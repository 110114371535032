<template>
  <div>
    <div>
      <v-combobox
        outlined
        dense
        :value="selectedNode.settings.to.value"
        item-text="name"
        item-value="name"
        @update:search-input="setIsCustomTo"
        @change="setIsCustomTo"
        :items="availableInputFields"
        :label="toLabel"
      ></v-combobox>
      <div class="mt-3">
        <mustache-template
          v-if="
            selectedNode.action_type === 'send_email' ||
              selectedNode.action_type === 'send_email_html'
          "
          label="Enter subject"
          :placeholder="
            'Example: Hi {{patient.firstname}}, this is your updated bill from Valley Women\'s Health'
          "
          :value="selectedNode.settings.subject"
          @input="(val) => onUpdate(val, 'settings.subject')"
          :suggestions="availableInputFields"
          :singleLine="true"
          class="mb-3"
        ></mustache-template>
        <v-combobox
          v-if="
            selectedNode.action_type === 'send_email' ||
              selectedNode.action_type === 'send_email_html'
          "
          outlined
          dense
          :value="selectedNode.settings.attachment"
          item-text="name"
          item-value="name"
          @update:search-input="setAttachment"
          @change="setAttachment"
          :items="availableInputFields"
          label="Attachment"
          class="mb-3"
        ></v-combobox>
        <mustache-template
          label="Template"
          :placeholder="
            'Example: Hi {{patient.firstname}}. Ms {{patient.lastname}}. Your email is {{email}}'
          "
          :value="selectedNode.settings.template"
          @input="(val) => onUpdate(val, 'settings.template')"
          :suggestions="availableInputFields"
        ></mustache-template>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { find } from 'lodash';
import { createNamespacedHelpers } from 'vuex';

import MustacheTemplate from '@/components/mustache/mustache-template.vue';
import { getNotifierSettings } from '@/util/defaultNodeSettings';

const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'notifier-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    toLabel() {
      const baseStr = 'Select TO ';
      switch (this.selectedNode.action_type) {
        case 'send_sms':
          return baseStr + 'phone number';
        case 'send_email':
        case 'send_email_html':
          return baseStr + 'email';
        default:
          return baseStr;
      }
    },
    availableLinks() {
      return [
        { value: null, text: 'No link' },
        { value: 'prePay', text: 'prePay' },
        { value: 'checkIn', text: 'checkIn' },
        { value: 'billing', text: 'billing' },
      ];
    },
  },
  created() {
    if (!this.selectedNode.settings.to) {
      this.selectedNode.settings.to = getNotifierSettings();
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    setIsCustomTo(v) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.to.value = v;
      const obj = find(this.availableInputFields, ['name', v]);
      settings.to.is_custom = !obj;

      this.onUpdate(settings, 'settings');
    },
    setAttachment(name) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.attachment = name;
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss"></style>

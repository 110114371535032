var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-col"},[_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('h3',[_vm._v("Insurance Fields")])])],1),_vm._l((_vm.settings.fields),function(section,sectionIndex){return _c('div',{key:sectionIndex,attrs:{"dense":""}},[_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('h4',[_vm._v(_vm._s(section.title))])])],1),_vm._l((section.fields),function(field,fieldIndex){return _c('v-row',{key:fieldIndex,attrs:{"dense":""}},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"outlined":"","dense":"","value":field.value,"item-text":"name","item-value":"name","items":_vm.availableInputFields,"label":field.name,"clearable":""},on:{"input":(val) =>
              _vm.onUpdate(
                val,
                `settings.fields[${sectionIndex}].fields[${fieldIndex}].value`
              )}})],1),_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"5"}},[_c('v-select',{attrs:{"outlined":"","dense":"","value":field.type,"item-text":"name","item-value":"value","items":_vm.fieldTypes,"label":"Field Type"},on:{"input":(val) =>
              _vm.onUpdate(
                val,
                `settings.fields[${sectionIndex}].fields[${fieldIndex}].type`
              )}})],1),_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-checkbox',{attrs:{"dense":"","input-value":field.required,"readonly":""}})],1)]}}],null,true)},[_c('span',[_vm._v("Is Field Required?")])])],1)],1)})],2)}),_c('div',[_c('h4',{staticClass:"mt-5 mb-3"},[_vm._v("Task Settings")]),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","label":"Task Name","value":_vm.settings.taskName},on:{"input":(val) => _vm.onUpdate(val, 'settings.taskName')}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","label":"Task Description","value":_vm.settings.taskDescription},on:{"input":(val) => _vm.onUpdate(val, 'settings.taskDescription')}}),_c('v-textarea',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","label":"Task Description","value":_vm.settings.taskDescription},on:{"input":(val) => _vm.onUpdate(val, 'settings.taskDescription')}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","label":"Logo URL","value":_vm.settings.logo},on:{"input":(val) => _vm.onUpdate(val, 'settings.logo')}}),_c('mustache-template',{attrs:{"label":"Assign To(patient id)","value":_vm.settings.patient_id,"suggestions":_vm.availableInputFields,"singleLine":true},on:{"input":(val) => _vm.onUpdate(val, 'settings.patient_id')}}),_c('v-select',{staticClass:"mt-5",attrs:{"outlined":"","dense":"","label":"Connected Workflow","item-text":"name","item-value":"id","value":_vm.settings.workflowId,"items":_vm.automationWorkflows,"loading":_vm.getAutomationWorkflowsLoading,"clearable":""},on:{"input":(val) => _vm.onUpdate(val, 'settings.workflowId')}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <div>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Set patient id"
        clearable
      />

      <v-text-field
        outlined
        dense
        label="Flag name"
        hide-details="auto"
        type="text"
        :value="selectedNode.settings.flag"
        @input="(val) => onUpdate(val, 'settings.flag')"
        class="mb-3"
      ></v-text-field>
      <v-select
        outlined
        dense
        :value="selectedNode.settings.command"
        @input="(val) => onUpdate(val, 'settings.command')"
        :items="['SET', 'REMOVE']"
        label="Set / remove"
        clearable
      />
    </div>
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "patient-flag-settings",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedNode.input);
      const selectedOption = this.availableInputFields.find(
        (f) => f.uid === uid
      );
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;
      this.onUpdate(input, "input");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

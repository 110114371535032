import { render, staticRenderFns } from "./csv-to-json.vue?vue&type=template&id=b37d6398&scoped=true"
import script from "./csv-to-json.vue?vue&type=script&lang=js"
export * from "./csv-to-json.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b37d6398",
  null
  
)

export default component.exports
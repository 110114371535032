<template>
  <div class="Activators">
    <div class="Activators__content">
      <h3 align="center" class="mb-3">External Workflow Credentials</h3>
      <div class="mx-5">
        <p>
          Send POST request on workflow activation endpoint:
          <span class="Activators__endpoint"
            >{{
              `https://${domain}/proxy/workflows/${workflowId}/external-activators/${type}/run?x-external-api-key=YOUR_KEY_HERE`
            }}
          </span>
        </p>
      </div>
      <v-data-table
        class="Activators__data-table"
        :headers="headers"
        outlined
        :items="externalActivatorCredentials"
        item-key="id"
        hide-default-footer
        show-expand
        single-expand
        :expanded.sync="expanded"
      >
        <template #item.actions="{ item }">
          <v-btn
            icon
            color="error"
            @click.stop="openDeleteExternalActivatorModal(item)"
          >
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-0">
            <v-card tile flat class="">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="auto">
                    <v-card flat class="pa-1">
                      <p>x-external-api-key: {{ item.credentials[0].value }}</p>
                      <v-btn @click="copyURL(item.credentials[0].value)">
                        Copy Link
                      </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <v-btn small color="primary" @click="openExternalActivatorModal()">
        + Add new credentials
      </v-btn>
      <action-prompt
        v-model="showDeleteExternalActivatorModal"
        v-if="showDeleteExternalActivatorModal"
        action-text="Delete"
        :action="deleteExternalActivatorMethod"
        header="Delete External Activator"
        body="Are you sure you want to delete this external activator?"
        action-type="error"
      />

      <add-external-activator-modal
        :type="this.type"
        v-model="showAddExternalActivatorModal"
        v-if="showAddExternalActivatorModal"
      />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import AddExternalActivatorModal from './modals/add-external-activator-modal/add-external-activator-modal';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import { getExternalWorkflowActivatorCreds } from '@/util/workflowDetails';

const {
  mapGetters: workflowDetailsGetters,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');

const { mapGetters: credentialGroupGetter } =
  createNamespacedHelpers('credentials');

const { mapActions: notificationsActions } =
  createNamespacedHelpers('notifications');

export default {
  name: 'ExternalWorkflowActivator',
  components: {
    ActionPrompt,
    AddExternalActivatorModal,
  },
  props: {
    type: { required: true },
  },
  data() {
    return {
      expanded: [],
      headers: [
        { text: 'Name', value: 'name', filterable: false },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          filterable: false,
        },
      ],
      selectedExternalActivatorId: '',
      showAddExternalActivatorModal: false,
      showDeleteExternalActivatorModal: false,
    };
  },

  methods: {
    ...notificationsActions(['pushNotification']),
    ...workflowDetailsMutations({
      deleteCredential: 'DELETE_CREDENTIAL',
      setWorkflowEdited: 'WORKFLOW_EDITED',
    }),
    openDeleteExternalActivatorModal({ id }) {
      this.selectedExternalActivatorId = id;
      this.showDeleteExternalActivatorModal = true;
    },
    async deleteExternalActivatorMethod() {
      this.deleteCredential(this.selectedExternalActivatorId);
      this.selectedExternalActivatorId = '';
      this.showDeleteExternalActivatorModal = false;
      this.setWorkflowEdited(true);
    },
    openExternalActivatorModal() {
      this.showAddExternalActivatorModal = true;
    },
    async copyURL(secretKey) {
      try {
        const url = `https://${this.domain}/proxy/workflows/${this.workflowId}/external-activators/${this.type}/run?x-external-api-key=${secretKey}`;
        await navigator.clipboard.writeText(url);
        this.pushNotification(
          'Url has been successfully copied to the clipboard'
        );
      } catch ($e) {
        this.pushNotification('Url cant be copied :(');
      }
    },
  },
  computed: {
    ...credentialGroupGetter({
      credentialGroupsAll: 'AUTHENTICATIONS',
    }),
    ...workflowDetailsGetters({
      workflowCredentials: 'CREDENTIALS',
    }),
    ...workflowDetailsMutations({
      setSelectedNodeOption: 'SET_SELECTED_NODE_OPTIONS',
    }),
    externalActivatorCredentials() {
      return getExternalWorkflowActivatorCreds(
        this.workflowCredentials,
        this.credentialGroupsAll
      );
    },
    domain() {
      return location.host;
    },
    workflowId() {
      return this.$route.params.automationId;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './external-activators';
</style>

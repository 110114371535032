<template>
  <div>
    <div>
      <mustache-template
        class="mb-3"
        label="filename"
        :value="selectedNode.settings.filename"
        @input="(val) => onUpdate(val, 'settings.filename')"
        :suggestions="availableInputFields"
        :singleLine="true"
      />
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.json"
        @input="(val) => onUpdate(val, 'settings.json')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="CSV Array"
        clearable
      ></v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "csv-settings",
  props: {
    availableInputFields: { required: true },
  },
  components: {
    MustacheTemplate,
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <div>
      <h4 class="mt-5">Invoice Settings</h4>
      <v-select
        class="mt-2"
        outlined
        dense
        :value="settings.invoice.invoice_type"
        @input="(val) => onUpdate(val, 'settings.invoice.invoice_type')"
        :items="invoiceTypes"
        label="Invoice type"></v-select>
      <mustache-template
        label="Assign To(patient id)"
        class="mt-2"
        :value="settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        :suggestions="availableInputFields"
        :singleLine="true"></mustache-template>
      <v-select
        class="mt-2"
        outlined
        dense
        :value="settings.invoice.balanceOps"
        @input="(val) => onUpdate(val, 'settings.invoice.balanceOps')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Invoice Data(Array of Objects)"
        clearable></v-select>
    </div>

    <div v-if="settings.invoice.balanceOps">
      <h4 class="mt-5">Field Mapping</h4>
      <v-select
        label="Invoice ID (Required)"
        class="mt-2"
        :value="settings.invoice.fields.visit_id"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.visit_id')"
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <div class="d-flex align-center justify-space-between">
        <v-select
          label="Charge ID (Required)"
          class="mt-2"
          :value="settings.invoice.fields.charge_id"
          @input="(val) => onUpdate(val, 'settings.invoice.fields.charge_id')"
          :items="nodeInputFields"
          :disabled="settings.invoice.auto_charge_id"
          item-text="name"
          item-value="name"
          outlined
          dense></v-select>
        <div class="d-flex align-center">
          <v-checkbox
            class="mb-0 pb-0"
            :input-value="settings.invoice.auto_charge_id"
            @change="(val) => autogenerateChargeId(val)"></v-checkbox>
          <v-label>Generate</v-label>
        </div>
      </div>
      <v-select
        label="Service Detail ID"
        class="mt-2"
        :value="settings.invoice.fields.service_detail_id"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.service_detail_id')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Procedure Code"
        class="mt-2"
        :value="settings.invoice.fields.procedure_code"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.procedure_code')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Procedure Description (Required)"
        class="mt-2"
        :value="settings.invoice.fields.procedure_description"
        @input="
          (val) =>
            onUpdate(val, 'settings.invoice.fields.procedure_description')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Total Charge (Required)"
        class="mt-2"
        :value="settings.invoice.fields.total_charge"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.total_charge')"
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        :label="
          settings.invoice.invoice_type === 'prePay'
            ? 'Expiration Date (Required)'
            : 'Date of Service (Required)'
        "
        class="mt-2"
        :value="settings.invoice.fields.date_of_service"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.date_of_service')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Patient Paid"
        class="mt-2"
        :value="settings.invoice.fields.patient_paid"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.patient_paid')"
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Patient Balance (Required)"
        class="mt-2"
        :value="settings.invoice.fields.patient_balance"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.patient_balance')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Paid"
        class="mt-2"
        :value="settings.invoice.fields.insurance_paid"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_paid')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Balance"
        class="mt-2"
        :value="settings.invoice.fields.insurance_balance"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_balance')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Adjusted Amount"
        class="mt-2"
        :value="settings.invoice.fields.adjusted_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.adjusted_amount')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Adjusted Amount"
        class="mt-2"
        :value="settings.invoice.fields.insurance_adj_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_adj_amount')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Write off Amount"
        class="mt-2"
        :value="settings.invoice.fields.write_off_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.write_off_amount')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Last Statement Date"
        class="mt-2"
        :value="settings.invoice.fields.last_statement_date"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.last_statement_date')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Last Statement Amount"
        class="mt-2"
        :value="settings.invoice.fields.last_statement_amount"
        @input="
          (val) =>
            onUpdate(val, 'settings.invoice.fields.last_statement_amount')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Posting Date"
        class="mt-2"
        :value="settings.invoice.fields.posting_date"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.posting_date')"
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Is Paid? (Required) "
        class="mt-2"
        :value="settings.invoice.fields.statusIsPaid"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.statusIsPaid')"
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Location ID"
        class="mt-2"
        :value="settings.invoice.fields.location_id_pk"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.location_id_pk')
        "
        :items="nodeInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';
import { shTypes } from '@/util/actionsModels';
import isObject from 'lodash/isObject';
import { findNode, getInputModel } from '@/util/action-types';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'invoice-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
    availableInputs: { required: true },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      executing: false,
      statuses: [
        { name: 'Not Paid', value: false },
        { name: 'Paid', value: true },
      ],
      invoiceTypes: ['prePay', 'postPay'],
      invoiceSources: ['greenway', 'nextgen'],
      dateMenu: false,
    };
  },
  created() {
    if (!this.settings.invoice) {
      this.settings.invoice = {
        fields: {},
      };
    }
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      nodes: 'NODES',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    input() {
      return this.selectedNode.input;
    },
    nodeInputFields() {
      let result = [];
      const inputModel = this.getInputModel();
      const balanceOps = this.settings.invoice?.balanceOps?.split('.');

      balanceOps.shift();
      const selectedModel = this.getSelectedModel(inputModel, balanceOps);
      if (!selectedModel) {
        this.onUpdate(null, 'settings.invoice.balanceOps');
        return;
      }
      if (selectedModel) {
        if (Array.isArray(selectedModel)) {
          const items = selectedModel
            .filter((item) => item.type === shTypes.ARRAY)
            .map((i) => ({ name: i.name, uid: i.uid, field: i }));
          result.push(...items);
        } else if (
          typeof selectedModel === 'object' &&
          selectedModel.type === shTypes.ARRAY
        ) {
          const items = [
            {
              name: selectedModel.name,
              uid: selectedModel.uid,
              field: selectedModel,
            },
          ];
          result.push(...items);
        }
        const flattenObjFields = (items, prefix) => {
          if (isObject(items)) {
            items.forEach((prop) => {
              const propWithPrefix = {
                name: !prefix ? prop.name : `${prefix}.${prop.name}`,
                uid: prop.uid,
                field: prop,
              };

              if (prop.type === shTypes.OBJECT) {
                result.push(propWithPrefix);
                flattenObjFields(prop.value, propWithPrefix.name);
              } else {
                result.push(propWithPrefix);
              }
            });
          }
        };

        if (selectedModel.type === shTypes.OBJECT) {
          flattenObjFields(selectedModel.value, selectedModel.name);
        } else {
          const items = [
            {
              name: selectedModel.name,
              uid: selectedModel.uid,
              field: selectedModel,
            },
          ];
          result.push(...items);
        }
      }

      return result;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    findNode(id) {
      return findNode(this.availableInputs, id);
    },
    getInputModel() {
      const inputNodeId = this.input.inputNodeId;
      const inputNode = this.findNode(inputNodeId);
      const options = {
        inputNodeId,
        inputNode,
        inputAction: null,
        nodes: this.nodes,
        nodeId: this.selectedNode.id,
      };
      const inputModel = getInputModel(options);
      return inputModel;
    },
    getSelectedModel(inputModel, balanceOps) {
      if (balanceOps.length > 0) {
        const valueName = balanceOps.shift();
        const model = inputModel.value.find((v) => v.name === valueName);
        return this.getSelectedModel(model, balanceOps);
      }
      return inputModel?.value;
    },
    autogenerateChargeId(val) {
      this.onUpdate(undefined, 'settings.invoice.fields.charge_id');
      this.onUpdate(val, 'settings.invoice.auto_charge_id');
    },
  },
};
</script>

<style scoped></style>

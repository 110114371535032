<template>
  <div class="RunWorkflow mt-5">
      <v-row
          dense
          align="center">
        <v-col
            cols="auto"
            class="mr-auto">
          <p class="text-subtitle-1 mb-0">Input Fields</p>
        </v-col>
        <v-col cols="auto">
          <v-btn
              v-if="showAddParamBtn"
              @click="addParam()"
              text
              color="primary"
              dense>
            + Add
          </v-btn>
        </v-col>
      </v-row>
      <div
          class="RunWorkflow__param-row mb-6"
          v-for="(param, i) in settings.params"
          :key="i">
        <v-text-field
            class="RunWorkflow__param-row--field mr-2"
            outlined
            dense
            label="Name"
            :value="param.name"
            @input="(val) =>{ onUpdate(val, `settings.params[${i}].name`); updateOutput() }" />
        <v-select
            class="RunWorkflow__param-row--field mr-2"
            outlined
            dense
            :value="param.value"
            @input="(val) => onUpdate(val, `settings.params[${i}].value`)"
            item-text="name"
            item-value="name"
            :items="availableInputFields"
            label="Value"
            clearable></v-select>
        <v-btn
            class="RunWorkflow__param-row--icon-btn"
            icon
            @click="deleteParam(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import {getModel} from "@/util/actionsModels";

const { mapGetters: workflowDetailsGetters } =
    createNamespacedHelpers("workflows/details");

export default {
  name: "workflow-output-settings",
  props: {
    availableInputFields: {
      type: Array,
      required: true,
    },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  watch: {},
  computed: {
    ...workflowDetailsGetters({
      actionsOutputs: "ACTION_OUTPUTS",
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      if (!this.selectedNode) return {};
      return this.selectedNode.settings;
    },
    actionType() {
      return this.selectedNode.action_type;
    },
    placeholderForDefaultFieldSelect() {
      return "Data"
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.params) {
        settings.params = [];
      }

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;

      this.onUpdate(settings, 'settings');
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
          !params ||
          !params[0] ||
          (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    updateOutput() {
      let outputModel = {}
      this.settings.params.map((param) => {
        outputModel[param.name] = null;
      })
      const output_type = getModel(outputModel);
      this.onUpdate(output_type, 'output_type');
    }
  },
};
</script>

<style lang="scss" scoped>
@import './run-workflow/run-workflow';
</style>

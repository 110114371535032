<template>
  <div class="settings-col">
    <v-row dense class="mt-2">
      <v-col cols="auto" class="mr-auto">
        <h3>Insurance Fields</h3>
      </v-col>
    </v-row>

    <div
      dense
      v-for="(section, sectionIndex) in settings.fields"
      :key="sectionIndex"
    >
      <v-row dense class="mt-2">
        <v-col cols="auto" class="mr-auto">
          <h4>{{ section.title }}</h4>
        </v-col>
      </v-row>
      <v-row
        dense
        v-for="(field, fieldIndex) in section.fields"
        :key="fieldIndex"
      >
        <v-col cols="6" class="mr-auto">
          <v-select
            outlined
            dense
            :value="field.value"
            @input="
              (val) =>
                onUpdate(
                  val,
                  `settings.fields[${sectionIndex}].fields[${fieldIndex}].value`
                )
            "
            item-text="name"
            item-value="name"
            :items="availableInputFields"
            :label="field.name"
            clearable
          ></v-select>
        </v-col>

        <v-col cols="5" class="mr-auto">
          <v-select
            outlined
            dense
            :value="field.type"
            @input="
              (val) =>
                onUpdate(
                  val,
                  `settings.fields[${sectionIndex}].fields[${fieldIndex}].type`
                )
            "
            item-text="name"
            item-value="value"
            :items="fieldTypes"
            label="Field Type"
          ></v-select>
        </v-col>

        <v-col cols="1" class="mr-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-checkbox dense :input-value="field.required" readonly>
                </v-checkbox>
              </span>
            </template>
            <span>Is Field Required?</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>

    <div>
      <h4 class="mt-5 mb-3">Task Settings</h4>
      <v-text-field
        class="mb-2"
        outlined
        dense
        label="Task Name"
        :value="settings.taskName"
        @input="(val) => onUpdate(val, 'settings.taskName')"
      />
      <v-text-field
        class="mb-2"
        outlined
        dense
        label="Task Description"
        :value="settings.taskDescription"
        @input="(val) => onUpdate(val, 'settings.taskDescription')"
      />
      <v-textarea
        class="mb-2"
        outlined
        dense
        label="Task Description"
        :value="settings.taskDescription"
        @input="(val) => onUpdate(val, 'settings.taskDescription')"
      ></v-textarea>

      <v-text-field
        class="mb-2"
        outlined
        dense
        label="Logo URL"
        :value="settings.logo"
        @input="(val) => onUpdate(val, 'settings.logo')"
      />
      <mustache-template
        label="Assign To(patient id)"
        :value="settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        :suggestions="availableInputFields"
        :singleLine="true"
      ></mustache-template>
      <v-select
        class="mt-5"
        outlined
        dense
        label="Connected Workflow"
        item-text="name"
        item-value="id"
        :value="settings.workflowId"
        @input="(val) => onUpdate(val, 'settings.workflowId')"
        :items="automationWorkflows"
        :loading="getAutomationWorkflowsLoading"
        clearable
      ></v-select>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workflowsGetters, mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'patient-insurance',
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      fieldTypes: [
        { name: 'Short Text', value: 'short_text' },
        { name: 'Long Text', value: 'long_text' },
        { name: 'Image', value: 'image' },
        { name: 'Date', value: 'date' },
      ],
    };
  },
  components: {
    MustacheTemplate,
  },
  mounted() {
    if (!this.automationWorkflows.length) {
      this.fetchWorkflows();
    }
  },
  created() {
    if (!this.settings.fields) {
      const settings = cloneDeep(this.settings);
      settings.fields = [
        {
          title: 'Primary Insurance',
          fields: [
            {
              name: 'Patient First Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Patient Last Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Patient Date of Birth',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Member ID',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Insurance Company',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder First Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder Last Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder Date of Birth',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Relationship to subscriber',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'ImageCardFront',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'ImageCardBack',
              value: '',
              required: true,
              type: 'short_text',
            },
          ],
        },
        {
          title: 'Secondary Insurance',
          fields: [
            {
              name: 'Patient First Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Patient Last Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Patient Date of Birth',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Member ID',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder First Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder Last Name',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'Policyholder Date of Birth',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'ImageCardFront',
              value: '',
              required: true,
              type: 'short_text',
            },
            {
              name: 'ImageCardBack',
              value: '',
              required: true,
              type: 'short_text',
            },
          ],
        },
      ];
      this.onUpdate(settings, 'settings');
    }
  },
  computed: {
    ...workflowsGetters({
      automationWorkflows: 'AUTOMATION_WORKFLOWS',
      getAutomationWorkflowsLoading: 'FETCH_AUTOMATION_WORKFLOWS_LOADING',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowsActions(['fetchWorkflows']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped lang="scss"></style>

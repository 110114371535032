<template>
  <div>
    <div>
      <h4 class="mt-5">Invoice Settings</h4>
      <v-select
        label="Assign To(patient id)"
        class="mt-2"
        :value="settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Status"
        class="mt-2"
        :value="settings.statusIsPaid"
        @input="(val) => onUpdate(val, 'settings.statusIsPaid')"
        :items="statuses"
        item-text="name"
        item-value="value"
        outlined
        dense></v-select>
      <v-select
        label="Type"
        class="mt-2"
        :value="settings.invoice_type"
        @input="(val) => onUpdate(val, 'settings.invoice_type')"
        :items="invoiceTypes"
        item-text="name"
        item-value="name"
        outlined
        dense>
      </v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { findNode } from '@/util/action-types';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'invoice-settings',
  props: {
    availableInputFields: { required: true },
    availableInputs: { required: true },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      executing: false,
      statuses: [
        { name: 'Not Paid', value: false },
        { name: 'Paid', value: true },
        { name: 'All', value: null },
      ],
      invoiceTypes: ['prePay', 'postPay'],
      dateMenu: false,
    };
  },
  created() {
    if (!this.settings.invoice) {
      this.settings.invoice = {
        fields: {},
      };
    }
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    input() {
      return this.selectedNode.input;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    findNode(id) {
      return findNode(this.availableInputs, id);
    },
    inputAction(inputNode) {
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    getSelectedModel(inputModel, balanceOps) {
      if (balanceOps.length > 0) {
        const valueName = balanceOps.shift();
        const model = inputModel.value.find((v) => v.name === valueName);
        return this.getSelectedModel(model, balanceOps);
      }
      return inputModel.value;
    },
    autogenerateChargeId(val) {
      this.onUpdate(undefined, 'settings.invoice.fields.charge_id');
      this.onUpdate(val, 'settings.invoice.auto_charge_id');
    },
  },
};
</script>

<style scoped></style>

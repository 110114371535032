<template>
  <div class="ChainOutput">
    <div v-for="(output, outputIndex) in outputs" :key="outputIndex" class="5">
      <p class="text-h6 mb-3">Connector #{{ outputIndex + 1 }}</p>

      <v-row class="mb-1" dense>
        <v-col cols="11">
          <v-select
            outlined
            dense
            :value="output.inputNodeId"
            @input="
              (val) => onUpdateOutput(val, `[${outputIndex}].inputNodeId`)
            "
            @change="changeInput(outputIndex)"
            item-text="name"
            item-value="id"
            :items="internalInputs.filter((i) => i.action_type)"
            label="Connector"
          />
        </v-col>

        <v-col cols="1">
          <v-btn icon @click="deleteInput(outputIndex)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="mb-3">
        <v-row dense align="center">
          <v-col cols="auto" class="mr-auto">
            <p class="text-subtitle-2 mb-0">Output Fields</p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="showAddParamBtn(outputIndex)"
              @click="addParam(outputIndex)"
              dense
              text
              color="primary"
            >
              + Add
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-for="(param, paramIndex) in output.params"
          :key="paramIndex"
          dense
        >
          <v-col cols="5">
            <v-text-field
              outlined
              dense
              label="Name"
              :value="param.name"
              @input="
                (val) =>
                  onUpdateOutput(
                    val,
                    `[${outputIndex}].params[${paramIndex}].name`
                  )
              "
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              outlined
              dense
              :value="param.value"
              @input="
                (val) =>
                  onUpdateOutput(
                    val,
                    `[${outputIndex}].params[${paramIndex}].value`
                  )
              "
              menu-props="auto"
              item-text="name"
              item-value="name"
              :items="availableInputFields(outputIndex)"
              label="Value"
              clearable
              append-icon=""
            />
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="deleteParam(outputIndex, paramIndex)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-divider inset v-if="outputIndex !== outputs.length - 1" class="my-2"></v-divider>
    </div>

    <v-btn class="mb-3 mt-5" @click="addInput()" text color="primary">
      + Add Output
    </v-btn>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import lodashSet from 'lodash/set';

export default {
  name: 'chain-output',
  props: {
    availableInputs: { required: true },
    outputs: { required: true },
    internalInputs: { required: true },
    availableInputFields: { required: true },
    changeInput: { required: true },
    addInput: { required: true },
  },
  methods: {
    onUpdateOutput(value, path) {
      const outputs = cloneDeep(this.outputs);
      const updatedOutputs = lodashSet(outputs, path, value);
      this.$emit('updateOutput', updatedOutputs);
    },
    showAddParamBtn(outputIndex) {
      const output = this.outputs[outputIndex];
      const params = output ? output.params : null;
      if (!output || !output.inputNodeId) return false;

      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    deleteInput(outputIndex) {
      const outputs = cloneDeep(this.outputs);
      outputs.splice(outputIndex, 1);
      this.$emit('updateOutput', outputs);
    },
    addParam(outputIndex) {
      const outputs = cloneDeep(this.outputs);
      outputs[outputIndex].params.push({
        name: '',
        value: '',
      });
      this.$emit('updateOutput', outputs);
    },
    deleteParam(outputIndex, paramIndex) {
      const outputs = cloneDeep(this.outputs);
      outputs[outputIndex].params.splice(paramIndex, 1);
      this.$emit('updateOutput', outputs);
    },
  },
};
</script>

<style scoped lang="scss"></style>

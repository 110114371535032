<template>
  <div>
    <div>
      <mustache-template
        class="mb-3"
        label="field path"
        :value="selectedNode.settings.incomingPath"
        @input="(val) => onUpdate(val, 'settings.incomingPath')"
        :suggestions="availableInputFields"
        :singleLine="true"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "incoming-general-settings",
  props: {
    availableInputFields: [],
  },
  components: {
    MustacheTemplate,
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <v-select
      v-for="(label, value) in fields"
      :key="value"
      outlined
      dense
      class="mb-3"
      :value="selectedNode.settings[value]"
      @input="(val) => onUpdate(val, `settings[${value}]`)"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      :label="label"
      clearable
    ></v-select>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

/**
 * Confirm the value and input gives same result as this
 * v-model="selectedNode.settings[value]"
 */
export default {
  name: "estimate-patient-cost-params",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    fields() {
      return {
        patient_id: "Patient Id",
        deductible: "Deductible",
        deductible_met: "Deductible met",
        co_insurance_percent: "Co insurance percent",
        allowable: "Allowable",
      };
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="HumanInTheLoopSettings">
    <v-text-field
      class="userInput"
      outlined
      dense
      label="Dialog Title"
      :value="settings.userInput.title"
      @input="(val) => onUpdate(val, 'settings.userInput.title')"
    />

    <p class="text-h6 mb-3">Inputs Fields</p>

    <v-row
      dense
      v-for="(field, i) in settings.userInput.fields"
      :key="field.name || field.id"
    >
      <v-col cols="11">
        <v-text-field
          outlined
          dense
          label="Field Name"
          :value="field.label"
          @input="
            (val) => onUpdate(val, `settings.userInput.fields[${i}].label`)
          "
          @change="buildOutputModel(i)"
        />
      </v-col>
      <v-col cols="1">
        <v-btn class="param-row__icon-btn" icon @click="deleteUserField(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-btn
      class="mb-5"
      v-if="showAddUserFieldBtn"
      @click="addUserField"
      text
      color="primary"
    >
      + Add
    </v-btn>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidv4 } from 'uuid';
import { getModel } from "@/util/actionsModels";
import { getHumanInTheLoopSettings } from "@/util/defaultNodeSettings";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "HumanInTheLoopSettings",
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddUserFieldBtn() {
      const { fields } = this.settings.userInput;
      return !fields.length || fields[fields.length - 1].label;
    },
  },
  created() {
    const settings = cloneDeep(this.settings);
    if (!settings.userInput) {
      settings.userInput = getHumanInTheLoopSettings();
      this.onUpdate(settings, "settings");
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    addUserField() {
      const settings = cloneDeep(this.settings);
      settings.userInput.fields.push({
        label: "",
        name: "",
        id: uuidv4(),
      });
      this.onUpdate(settings, "settings");
    },
    deleteUserField(index) {
      const settings = cloneDeep(this.settings);
      settings.userInput.fields.splice(index, 1);
      this.onUpdate(settings, "settings");
      this.buildOutputModel();
    },
    buildOutputModel(index) {
      const settings = cloneDeep(this.settings);
      const model = {};

      if (index) {
        const label = settings.userInput.fields[index].label;
        settings.userInput.fields[index].name = (label
          ? label.toLowerCase().replace(/ /gi, "_")
          : "");
      }

      const data = settings.userInput.fields.map((field) => {
        if (field.label) {
          model[field.name] = "String";
        }
        return field;
      });

      settings.userInput.fields = data;
      const output_type = getModel(model, "HumanInTheLoop");
      this.onUpdate(settings, "settings");
      this.onUpdate(output_type, "output_type");
    },
  },
};
</script>

<style scoped lang="scss">
</style>

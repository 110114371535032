<template>
  <div>
    <v-text-field
      class="mb-3"
      outlined
      dense
      label="Sheet Name"
      placeholder="Leave black to parse all sheets"
      :value="selectedNode.settings.sheets"
      @input="(val) => onUpdate(val, 'settings.sheets')"
    />

    <v-text-field
      class="mb-3"
      outlined
      dense
      label="Range"
      placeholder="e.g. A1:B2 (Leave black to parse whole sheet)"
      :value="selectedNode.settings.range"
      @input="(val) => onUpdate(val, 'settings.range')"
    />

    <v-checkbox
      label="Use first row as keys?"
      :input-value="selectedNode.settings.rowOneIsHeader"
      @change="(val) => onUpdate(val, 'settings.rowOneIsHeader')"
      class="mb-3"
    />

    <v-file-input
      accept=".xlsx"
      outlined
      dense
      :loading="loadingModel"
      v-model="sampleExcel"
      label="Import sample Excel file(For output Model)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getModel } from '@/util/actionsModels';

const { mapActions: workflowsActions, mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'ExcelToJSON',
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      sampleExcel: null,
      loadingModel: false,
    };
  },
  created() {
    if (!this.selectedNode.settings.cutKey) {
      this.selectedNode.settings.cutKey = '';
    }
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
  },
  watch: {
    sampleExcel() {
      if (!this.sampleExcel) return;
      const reader = new FileReader();
      reader.readAsDataURL(this.sampleExcel);
      reader.onload = this.parseFile;
      reader.onerror = (evt) => {
        console.log('error', evt);
      };
    },
  },
  methods: {
    ...workflowsActions(['executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async parseFile(evt) {
      this.loadingModel = true;
      const content = evt.target.result.toString().split(';base64,')[1];
      const input = {
        name: 'ExcelFile',
        file: content,
      };
      const options = {
        input,
        data: this.selectedNode.settings,
        action: 'xlsx_to_json',
      };
      const resp = await this.executeSingleAction(options);
      if (resp.success) {
        const output_type = getModel(resp.model);
        this.onUpdate(output_type, 'output_type');
      }
      this.loadingModel = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>

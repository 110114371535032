<template>
  <div>
    <v-switch
      label="Use Share URL"
      class="mt-2"
      :input-value="settings.useShareUrl"
      @change="toggleUseShareUrl"
    ></v-switch>
    <mustache-template
      v-if="settings.useShareUrl"
      label="Share URL"
      class="mb-3"
      :value="settings.shareUrl"
      @input="(val) => onUpdate(val, 'settings.shareUrl')"
      :suggestions="availableInputFields"
      :singleLine="true"
    />

    <div v-else>
      <v-autocomplete
        class="mb-3"
        outlined
        dense
        :value="settings.location"
        @input="(val) => setLocation(val)"
        @click:append-outer="listFolders"
        item-text="name"
        item-value="id"
        :items="driveFolders"
        label="File Location"
        :append-outer-icon="
          loadingFolders ? 'mdi-loading mdi-spin' : 'mdi-reload'
        "
        clearable
      ></v-autocomplete>
      <v-autocomplete
        class="mb-3"
        outlined
        dense
        :value="settings.documentId"
        @input="(val) => setDocument(val)"
        @click:append-outer="listDocuments"
        item-text="name"
        item-value="id"
        :items="documents"
        :label="docTitle"
        :append-outer-icon="
          loadingDocument ? 'mdi-loading mdi-spin' : 'mdi-reload'
        "
        clearable
        @change="documentChange"
        @click:clear="clearDocument()"
      ></v-autocomplete>
    </div>

    <v-autocomplete
      class="mb-3"
      outlined
      dense
      :value="settings.sheetId"
      @input="(val) => setSheet(val)"
      @click:append-outer="listSheetInDocument"
      item-text="name"
      item-value="id"
      :items="sheets"
      label="Select Sheet"
      :append-outer-icon="loadingSheet ? 'mdi-loading mdi-spin' : 'mdi-reload'"
      clearable
      :disabled="!settings.documentId && !settings.shareUrl"
    ></v-autocomplete>

    <div class="d-flex">
      <v-select
        class="mb-4 mr-1"
        outlined
        dense
        @input="(val) => onUpdate(val, 'settings.row')"
        :value="settings.row"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Row Number"
      ></v-select>
      <v-select
        outlined
        dense
        @input="(val) => onUpdate(val, 'settings.column')"
        :value="settings.column"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        class="mb-4 ml-1"
        label="Column Number"
      ></v-select>
    </div>
    <v-select
      outlined
      dense
      @input="(val) => onUpdate(val, 'settings.data')"
      :value="settings.data"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      class="mb-4 ml-1"
      label="Cell Value"
    ></v-select>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'update_cell',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      driveFolders: [],
      documents: [],
      sheets: [],
      dynamicFolders: [],
      loadingFolders: false,
      loadingDocument: false,
      loadingSheet: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    action() {
      return this.selectedNode.action_type;
    },
    settings() {
      return this.selectedNode.settings;
    },
    suite() {
      if (
        this.action === 'google_sheets_read_data' ||
        this.action === 'google_sheets_write_data'
      ) {
        return 'google';
      }
      return 'outlook';
    },
    docTitle() {
      if (this.suite === 'google') return 'Select Spreadsheet';
      if (this.suite === 'outlook') return 'Select Worksheet';
      return '';
    },
    getInputNode() {
      const inputId = this.selectedNode?.input?.inputFieldId;
      const inputField = this.availableInputFields?.find((field) => {
        return field.uid === inputId;
      });
      if (!inputField?.field?.value?.value) {
        return [];
      }

      return inputField.field?.value?.value;
    },
  },
  created() {
    if (this.settings.location && this.settings.locationName) {
      this.driveFolders = [
        { id: this.settings.location, name: this.settings.locationName },
      ];
    }
    if (this.settings.documentId && this.settings.documentName) {
      this.documents = [
        { id: this.settings.documentId, name: this.settings.documentName },
      ];
    }

    if (this.settings.sheetName) {
      this.sheets = [
        { id: this.settings.sheetId, name: this.settings.sheetName },
      ];
    }

    if (!this.settings.output) {
      this.onUpdate('values', 'settings.output');
    }

    if (!this.settings.header) {
      const settings = cloneDeep(this.settings);
      settings.header = {
        spaces: false,
        dots: false,
        lowercase: false,
      };
      this.onUpdate(settings, 'settings');
    }
    this.dynamicFolders = this.availableInputFields.map((inputField) => {
      return { name: inputField.name, id: inputField.name };
    });
  },
  watch: {
    'settings.authenticationId'() {
      this.driveFolders = [];
      this.settings.location = null;
      this.listFolders();
    },
    'settings.location'(newValue) {
      if (!newValue) return;
      this.documents = [];
      this.settings.documentId = null;
      this.listDocuments();
    },
    'settings.documentId'() {
      this.sheets = [];
      this.settings.sheetId = null;
      this.listSheetInDocument();
    },
    'settings.useShareUrl'() {
      if (this.settings.useShareUrl) {
        this.sheets = [...this.dynamicFolders];
      } else {
        this.sheets = [];
        this.settings.sheetId = null;
        this.settings.sheetName = null;
      }
    },
  },
  methods: {
    ...workflowsActions(['outlookAction', 'googleAction']),
    setLocation(location) {
      const folder = this.driveFolders.find((item) => item.id === location);
      this.onUpdate(location, 'settings.location');
      this.onUpdate(folder.name, 'settings.locationName');
    },
    setDocument(documentId) {
      const document = this.documents.find((item) => item.id === documentId);
      this.onUpdate(documentId, 'settings.documentId');
      this.onUpdate(document.name, 'settings.documentName');
      this.onUpdate(document.driveId, 'settings.driveId');
    },
    setSheet(sheet) {
      const data = this.sheets.find((item) => {
        return item.id === sheet || item.name === sheet;
      });

      if (this.suite === 'outlook') {
        this.onUpdate(sheet, 'settings.sheetId');
      }
      this.onUpdate(data.name, 'settings.sheetName');
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    clearDocument() {
      const settings = cloneDeep(this.settings);
      this.sheets = [];
      settings.sheetId = null;
      this.onUpdate(settings, 'settings');
    },
    suiteAction(data) {
      if (this.suite === 'google') return this.googleAction(data);
      if (this.suite === 'outlook') return this.outlookAction(data);
    },
    documentChange() {
      this.clearDocument();
      this.listSheetInDocument();
    },
    async listFolders() {
      this.loadingFolders = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
      } finally {
        this.loadingFolders = false;
      }
    },
    async listDocuments() {
      this.loadingDocument = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_excelsheet',
          location: this.settings.location || undefined,
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.documents = resp.result;
        }
      } finally {
        this.loadingDocument = false;
      }
    },
    async listSheetInDocument() {
      if (!this.settings.documentId && !this.settings.shareUrl) return;
      this.loadingSheet = true;
      try {
        const data = {
          authenticationId: this.selectedNode.settings.authenticationId,
          action: 'list_sheets',
          driveId: this.selectedNode.settings.driveId,
          documentId: this.selectedNode.settings.documentId,
          useShareUrl: this.selectedNode.settings.useShareUrl,
          shareUrl: this.selectedNode.settings.shareUrl,
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.sheets = resp.result;
          if (this.settings.useShareUrl) {
            this.sheets = [...this.dynamicFolders, ...this.sheets];
          }
        }
      } finally {
        this.loadingSheet = false;
      }
    },
    toggleUseShareUrl() {
      const settings = cloneDeep(this.settings);
      settings.useShareUrl = !settings.useShareUrl;
      if (!settings.useShareUrl) {
        settings.location = null;
      }
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss"></style>

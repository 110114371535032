<template>
  <div>
    <div>
      <div>
        <v-text-field
            outlined
            dense
            label="Queue Name"
            hide-details="auto"
            type="text"
            class="mb-3"
            :value="selectedNode.settings.sqsQueue"
            @input="(val) => onUpdate(val, 'settings.sqsQueue')"
        ></v-text-field>
      </div>
    </div>
    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Message Body Fields</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
            v-if="showAddBodyParamBtn"
            @click="addBodyParam()"
            text
            color="primary"
            dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
        class="AwsClaudeSettings__param-row mb-6"
        v-for="(param, i) in settings.messageBodyParams"
        :key="i">
      <v-text-field
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          label="Name"
          :value="param.name"
          @input="(val) => onUpdate(val, `settings.messageBodyParams[${i}].name`)" />
      <v-combobox
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          :value="param.value"
          @input="(val) => onUpdate(val?.name || val, `settings.messageBodyParams[${i}].value`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Value"
          clearable></v-combobox>
      <v-btn
          class="AwsClaudeSettings__param-row--icon-btn"
          icon
          @click="deleteBodyParam(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Message Attributes Fields</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
            v-if="showAddAttrsParamBtn"
            @click="addAttrsParam()"
            text
            color="primary"
            dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
        class="AwsClaudeSettings__param-row mb-6"
        v-for="(param, i) in settings.messageAttributesParams"
        :key="i+`_attrs`">
      <v-text-field
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          label="Name"
          :value="param.name"
          @input="(val) => onUpdate(val, `settings.messageAttributesParams[${i}].name`)" />
      <v-combobox
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          :value="param.value"
          @input="(val) => onUpdate(val?.name || val, `settings.messageAttributesParams[${i}].value`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Value"
          clearable></v-combobox>
      <v-btn
          class="AwsClaudeSettings__param-row--icon-btn"
          icon
          @click="deleteAttrsParam(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from "lodash/cloneDeep";

const { mapGetters: workflowDetailsGetters } =
    createNamespacedHelpers('workflows/details');

export default {
  name: 'aws-send-sqs',
  props: {
    availableInputFields: { required: true },
  },
  components: {},
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddBodyParamBtn() {
      const params = this.settings.messageBodyParams;
      return (
          !params ||
          !params[0] ||
          (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    showAddAttrsParamBtn() {
      const params = this.settings.messageAttributesParams;
      return (
          !params ||
          !params[0] ||
          (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addBodyParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.messageBodyParams) {
        settings.messageBodyParams = [];
      }

      settings.messageBodyParams.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteBodyParam(index) {
      const settings = cloneDeep(this.settings);
      settings.messageBodyParams.splice(index, 1);
      if (!settings.messageBodyParams.length) delete settings.messageBodyParams;

      this.onUpdate(settings, 'settings');
    },
    addAttrsParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.messageAttributesParams) {
        settings.messageAttributesParams = [];
      }

      settings.messageAttributesParams.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteAttrsParam(index) {
      const settings = cloneDeep(this.settings);
      settings.messageAttributesParams.splice(index, 1);
      if (!settings.messageAttributesParams.length) delete settings.messageAttributesParams;

      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss">
@import './aws-claude';
</style>

<template>
  <div class="DatetimeSettings">
    <div v-if="action === 'format_date_time'">
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          label="Desired Format"
          placeholder="e.g. YYYY-MM-DD"
          hide-details="auto"
          :value="settings.format"
          @input="(val) => onUpdate(val, 'settings.format')"
        ></v-text-field>
        <a
          href="https://devhints.io/moment#formatting-1"
          target="_blank"
          class="DatetimeSettings__link"
        >
          format options
        </a>
      </div>

      <p class="text-h6 mb-3">Select Date Fields</p>
      <v-row v-for="(field, i) in settings.fields" :key="i" dense>
        <v-col cols="11">
          <v-select
            outlined
            dense
            item-text="name"
            item-value="name"
            :value="field.name"
            @input="(val) => onUpdate(val, `settings.fields[${i}].name`)"
            :items="availableInputFields"
            @change="buildOutputModel"
            clearable
          />
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="deleteField(i)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-btn v-if="showAddFieldBtn" @click="addField()" text color="primary">
        + Add
      </v-btn>
    </div>

    <div v-else-if="action === 'current_date_time'">
      <v-select
        outlined
        dense
        item-text="name"
        item-value="value"
        :value="settings.timeZone"
        @input="(val) => onUpdate(val, 'settings.timeZone')"
        :items="timezones"
        @change="buildOutputModel"
        clearable
      />
    </div>

    <div v-else-if="action === 'get_days_between'">
      <p class="text-h6 mb-3">Days Between</p>

      <v-row dense>
        <v-col>
          <v-select
            outlined
            dense
            item-text="name"
            item-value="name"
            :value="settings.days_between.begin_date"
            @input="(val) => onUpdate(val, 'settings.days_between.begin_date')"
            :items="availableInputFields"
            @change="buildOutputModel"
            label="Begin Date"
            clearable
          />
        </v-col>
        <v-col>
          <v-select
            outlined
            dense
            item-text="name"
            item-value="name"
            :value="settings.days_between.end_date"
            @input="(val) => onUpdate(val, 'settings.days_between.end_date')"
            :items="availableInputFields"
            @change="buildOutputModel"
            label="End Date"
            clearable
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { createNamespacedHelpers } from 'vuex';

import { getModel } from '@/util/actionsModels';
import { findNode } from '@/util/action-types';
import {
  getCurrentTimeSettings,
  getDaysBetweenSettings,
} from '@/util/defaultNodeSettings';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'DatetimeSettings',
  props: {
    nodes: { required: true },
    availableInputFields: { required: true },
  },
  data() {
    return {
      timezones: [],
    };
  },
  mounted() {
    this.timezones = moment.tz
      .names()
      .map((item) => ({ name: item, value: item }));
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    action() {
      return this.selectedNode.action_type;
    },
    showAddFieldBtn() {
      const fields = this.settings.fields;
      return !fields || !fields[0] || fields[fields.length - 1].name;
    },
  },
  created() {
    const settings = cloneDeep(this.settings);
    if (!settings.fields) {
      settings.fields = [];
    }
    if (!settings.timeZone) {
      settings.timeZone = getCurrentTimeSettings();
    }
    if (!this.settings.days_between) {
      settings.days_between = getDaysBetweenSettings();
    }
    this.onUpdate(settings, 'settings');
  },

  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addField() {
      const settings = cloneDeep(this.settings);
      settings.fields.push({ name: '' });
      this.onUpdate(settings, 'settings');
    },
    deleteField(index) {
      const settings = cloneDeep(this.settings);
      settings.fields.splice(index, 1);
      this.onUpdate(settings, 'settings');
      this.buildOutputModel();
    },
    buildOutputModel() {
      const model = {};
      let outputModel;

      let parentOutput = null;
      const id = this.selectedNode.input.inputNodeId;
      const parentNode = findNode(this.nodes, id);
      if (parentNode) parentOutput = cloneDeep(parentNode.output_type);

      if (this.action === 'format_date_time') {
        this.settings.fields.forEach((field) => {
          if (field.name && field.name.includes('.')) {
            model[field.name.split('.')[1]] = 'String';
          } else {
            model[field.name] = 'String';
          }
        });
        outputModel = getModel(model, 'formatted_dates');

        parentOutput?.value.push(outputModel);
        this.onUpdate(parentOutput, 'output_type');
      }

      if (this.action === 'current_date_time') {
        model.current_date_time = 'String';
        model.time_zone = 'String';
        outputModel = getModel(model, 'current_date_time');
        if (!parentOutput) {
          const wrapper = getModel({});
          wrapper?.value.push(outputModel);
          this.onUpdate(wrapper, 'output_type');
        } else {
          parentOutput?.value.push(outputModel);
          this.onUpdate(parentOutput, 'output_type');
        }
      }

      if (this.action === 'get_days_between') {
        model.begin_date = 'String';
        model.end_date = 'String';
        model.days_between = 'Number';
        outputModel = getModel(model, 'days_between');

        parentOutput?.value.push(outputModel);
        this.onUpdate(parentOutput, 'output_type');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.DatetimeSettings {
  &__link {
    text-align: right;
    font-size: 12px;
    display: block;
  }
}
</style>

<template>
  <div>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.json"
      @input="(val) => onUpdate(val, 'settings.json')"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      label="JSON Input"
      clearable
    ></v-select>
    <div>
      <v-switch
        v-if="isMicrosoft"
        label="Use Share URL"
        class="mt-2"
        :input-value="settings.useShareUrl"
        @change="toggleUseShareUrl"
      ></v-switch>
      <mustache-template
        v-if="settings.useShareUrl"
        label="Share URL"
        class="mb-3"
        :value="settings.shareUrl"
        @input="(val) => onUpdate(val, `settings.shareUrl`)"
        :suggestions="availableInputFields"
        :singleLine="true"
      />

      <div v-else>
        <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="settings.location"
          @input="(val) => setLocation(val)"
          @click:append-outer="listFolders"
          item-text="name"
          item-value="id"
          :items="driveFolders"
          label="File Location"
          :append-outer-icon="
            loadingFolders ? 'mdi-loading mdi-spin' : 'mdi-reload'
          "
          clearable
        ></v-autocomplete>

        <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="settings.documentId"
          @input="(val) => setDocument(val)"
          @click:append-outer="listDocuments"
          item-text="name"
          item-value="id"
          :items="documents"
          :label="docTitle"
          :append-outer-icon="
            loadingDocument ? 'mdi-loading mdi-spin' : 'mdi-reload'
          "
          clearable
          @change="documentChange"
          @click:clear="clearDocument()"
        ></v-autocomplete>
      </div>

      <!-- Create New Sheet -->
      <v-checkbox
        outlined
        dense
        label="Create new sheet"
        hide-details="auto"
        class="mb-3"
        v-model="settings.createNew"
      ></v-checkbox>
      <v-text-field
        v-if="settings.createNew"
        outlined
        dense
        label="Sheet Name"
        hide-details="auto"
        type="text"
        class="mb-3"
        :value="settings.newSheetName"
        @input="(val) => onUpdate(val, 'settings.newSheetName')"
      ></v-text-field>
      <v-autocomplete
        v-if="suite === 'google' && !settings.createNew"
        class="mb-3"
        outlined
        dense
        :value="settings.sheetName"
        @input="(val) => setSheet(val)"
        @click:append-outer="listSheetInDocument"
        item-text="name"
        item-value="name"
        :items="sheets"
        label="Select Sheet"
        :append-outer-icon="
          loadingSheet ? 'mdi-loading mdi-spin' : 'mdi-reload'
        "
        clearable
        :disabled="!settings.documentId"
      ></v-autocomplete>
      <v-autocomplete
        v-else-if="suite === 'outlook' && !settings.createNew"
        class="mb-3"
        outlined
        dense
        :value="settings.sheetId"
        @input="(val) => setSheet(val)"
        @click:append-outer="listSheetInDocument"
        item-text="name"
        item-value="id"
        :items="sheets"
        label="Select Sheet"
        :append-outer-icon="
          loadingSheet ? 'mdi-loading mdi-spin' : 'mdi-reload'
        "
        clearable
        :disabled="!settings.documentId && !settings.shareUrl"
      ></v-autocomplete>

      <v-autocomplete
        v-if="suite === 'outlook' && action === 'msgraph_workbook_read_data'"
        class="mb-3"
        outlined
        dense
        @input="(val) => onUpdate(val, 'settings.output')"
        :value="settings.output"
        :items="parseOutput"
        label="Select Output"
      ></v-autocomplete>

      <div
        v-if="
          action === 'google_sheets_read_data' ||
          action === 'msgraph_workbook_read_data'
        "
      >
        <h3 class="mb-3">Header Settings</h3>
        <v-checkbox
          class="mb-3"
          outlined
          dense
          :input-value="settings.header.spaces"
          @change="(val) => onUpdate(val, 'settings.header.spaces')"
          label="Replace Spaces with Underscores?"
        ></v-checkbox>
        <v-checkbox
          class="mb-3"
          outlined
          dense
          :input-value="settings.header.dots"
          @change="(val) => onUpdate(val, 'settings.header.dots')"
          label="Remove dots?"
        ></v-checkbox>
        <v-checkbox
          class="mb-3"
          outlined
          dense
          :input-value="settings.header.lowercase"
          @change="(val) => onUpdate(val, 'settings.header.lowercase')"
          label="Convert to lowercase?"
        ></v-checkbox>
      </div>

      <v-btn
        v-if="
          action === 'google_sheets_read_data' ||
          action === 'msgraph_workbook_read_data'
        "
        class="mb-8"
        color="primary"
        :loading="runTest"
        @click="testScript()"
        :disabled="
          !settings.authenticationId ||
          (!settings.documentId && !settings.shareUrl) ||
          (settings.sheetId == undefined && settings.sheetName == undefined)
        "
      >
        Test Run
      </v-btn>
    </div>

    <div v-if="suite === 'outlook' && action === 'msgraph_workbook_write_data'">
      <div class="mb-2 d-flex">
        <h5>Column Order</h5>
        <span style="font-size: smaller">&nbsp;(select all or none)</span>
      </div>
      <v-select
        v-for="(_, index) in getInputNode"
        :key="settings.headerOrder?.[index]"
        class="mb-3"
        outlined
        dense
        @input="(val) => onUpdate(val, `settings.headerOrder[${index}]`)"
        :value="settings.headerOrder?.[index]"
        item-text="name"
        item-value="name"
        :items="getInputNode"
        :label="`Column ${index + 1}`"
        clearable
      ></v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getModel } from '@/util/actionsModels';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'sheet-readwrite',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      driveFolders: [],
      documents: [],
      sheets: [],
      dynamicFolders: [],
      parseOutput: [
        'values',
        'text',
        'formulas',
        'formulasLocal',
        'formulasR1C1',
      ],
      loadingFolders: false,
      loadingDocument: false,
      loadingSheet: false,
      runTest: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    action() {
      return this.selectedNode.action_type;
    },
    settings() {
      return this.selectedNode.settings;
    },
    suite() {
      if (
        this.action === 'google_sheets_read_data' ||
        this.action === 'google_sheets_write_data'
      ) {
        return 'google';
      }
      return 'outlook';
    },
    isMicrosoft() {
      return this.suite !== 'google';
    },
    docTitle() {
      if (this.suite === 'google') return 'Select Spreadsheet';
      if (this.suite === 'outlook') return 'Select Worksheet';
      return '';
    },
    getInputNode() {
      const inputName = this.selectedNode?.settings.json;
      const inputField = this.availableInputFields?.find((field) => {
        return field.name === inputName;
      });
      if (!inputField?.field?.value?.value) {
        return [];
      }

      return inputField.field?.value?.value;
    },
  },
  created() {
    if (this.settings.location && this.settings.locationName) {
      this.driveFolders = [
        { id: this.settings.location, name: this.settings.locationName },
      ];
    }
    if (this.settings.documentId && this.settings.documentName) {
      this.documents = [
        { id: this.settings.documentId, name: this.settings.documentName },
      ];
    }
    if (this.settings.sheetName) {
      this.sheets = [
        { id: this.settings.sheetId, name: this.settings.sheetName },
      ];
    }

    if (!this.settings.output) {
      this.onUpdate('values', 'settings.output');
    }

    if (!this.settings.header) {
      const settings = cloneDeep(this.settings);
      settings.header = {
        spaces: false,
        dots: false,
        lowercase: false,
      };
      this.onUpdate(settings, 'settings');
    }
    this.dynamicFolders = this.availableInputFields.map((inputField) => {
      return { name: inputField.name, id: inputField.name };
    });
  },
  watch: {
    'settings.authenticationId'() {
      this.driveFolders = [];
      this.settings.location = null;
      this.listFolders();
    },
    'settings.location'(newValue) {
      if (!newValue) return;
      this.documents = [];
      this.settings.documentId = null;
      this.listDocuments();
    },
    'settings.documentId'() {
      this.sheets = [];
      this.settings.sheetId = null;
      this.listSheetInDocument();
    },
    'settings.useShareUrl'() {
      if (this.isMicrosoft) {
        if (this.settings.useShareUrl) {
          this.sheets = [...this.dynamicFolders];
        } else {
          this.sheets = [];
          this.settings.sheetId = null;
          this.settings.sheetName = null;
        }
      }
    },
  },
  methods: {
    ...workflowsActions(['outlookAction', 'googleAction']),
    setLocation(location) {
      const folder = this.driveFolders.find((item) => item.id === location);
      this.onUpdate(location, 'settings.location');
      this.onUpdate(folder.name, 'settings.locationName');
    },
    setDocument(documentId) {
      const document = this.documents.find((item) => item.id === documentId);
      this.onUpdate(documentId, 'settings.documentId');
      this.onUpdate(document.name, 'settings.documentName');
      this.onUpdate(document.driveId, 'settings.driveId');
    },
    setSheet(sheet) {
      const data = this.sheets.find((item) => {
        return item.id === sheet || item.name === sheet;
      });

      if (this.suite === 'outlook') {
        this.onUpdate(sheet, 'settings.sheetId');
      }
      this.onUpdate(data.name, 'settings.sheetName');
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    clearDocument() {
      const settings = cloneDeep(this.settings);
      this.sheets = [];
      settings.sheetId = null;
      this.onUpdate(settings, 'settings');
    },
    suiteAction(data) {
      if (this.suite === 'google') return this.googleAction(data);
      if (this.suite === 'outlook') return this.outlookAction(data);
    },
    documentChange() {
      this.clearDocument();
      this.listSheetInDocument();
    },
    async listFolders() {
      this.loadingFolders = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
      } finally {
        this.loadingFolders = false;
      }
    },
    async listDocuments() {
      this.loadingDocument = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_excelsheet',
          location: this.settings.location || undefined,
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.documents = resp.result;
        }
      } finally {
        this.loadingDocument = false;
      }
    },
    async listSheetInDocument() {
      if (!this.settings.documentId && !this.settings.shareUrl) return;
      this.loadingSheet = true;
      try {
        const data = {
          authenticationId: this.selectedNode.settings.authenticationId,
          action: 'list_sheets',
          driveId: this.selectedNode.settings.driveId,
          documentId: this.selectedNode.settings.documentId,
          useShareUrl: this.selectedNode.settings.useShareUrl,
          shareUrl: this.selectedNode.settings.shareUrl,
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.sheets = resp.result;
          if (this.settings.useShareUrl) {
            this.sheets = [...this.dynamicFolders, ...this.sheets];
          }
        }
      } finally {
        this.loadingSheet = false;
      }
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.runTest = true;

        const params = {};
        if (this.settings.params)
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'get_data',
          documentId: this.settings.documentId,
          sheetId: this.settings.sheetId,
          header: this.settings.header,
          driveId: this.settings.driveId,
          output: this.settings.output,
          useShareUrl: this.selectedNode.settings.useShareUrl,
          shareUrl: this.selectedNode.settings.shareUrl,
        };
        if (this.suite === 'google') {
          data.sheetName = this.settings.sheetName;
          delete data.sheetId;
        }
        const resp = await this.suiteAction(data);
        if (resp.success) {
          this.output = resp.result || 'no output';
          const output_type = getModel(resp.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(resp.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.runTest = false;
      }
    },
    toggleUseShareUrl() {
      const settings = cloneDeep(this.settings);
      settings.useShareUrl = !settings.useShareUrl;
      if (!settings.useShareUrl) {
        settings.location = null;
      }
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss"></style>

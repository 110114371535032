<template>
    <div>
        <div>
            <v-select
                    class="mb-3"
                    outlined
                    dense
                    :value="selectedNode.settings.file"
                    @input="(val) => onUpdate(val, 'settings.file')"
                    item-text="name"
                    item-value="name"
                    :items="availableInputFields"
                    label="File Input"
                    clearable></v-select>
            <v-select
                    class="mb-3"
                    outlined
                    dense
                    :value="selectedNode.settings.rawText"
                    @input="(val) => onUpdate(val, 'settings.rawText')"
                    item-text="name"
                    item-value="name"
                    :items="availableInputFields"
                    label="Raw Text"
                    clearable></v-select>
            <v-switch
                    v-model="selectedNode.settings.isRawText"
                    color="primary"
                    hide-details
                    true-value="yes"
                    false-value="no"
                    :label="`Get note from raw text`"
            ></v-switch>
            <mustache-template
                    label="Diagnosis Prompt"
                    class="mr-2 mb-3 mt-3"
                    :value="selectedNode.settings.promptDiagnosis"
                    @input="(val) => onUpdate(val, `settings.promptDiagnosis`)"
                    :suggestions="availableInputFields"
                    :singleLine="false" />
            <mustache-template
                    label="ICD Prompt"
                    class="mr-2 mb-3"
                    :value="selectedNode.settings.promptICD"
                    @input="(val) => onUpdate(val, `settings.promptICD`)"
                    :suggestions="availableInputFields"
                    :singleLine="false" />
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const { mapGetters: workflowDetailsGetters } =
    createNamespacedHelpers('workflows/details');

export default {
    name: 'file-upload',
    components: {MustacheTemplate},
    props: {
        availableInputFields: { required: true },
    },
    data() {
        return {
            driveFolders: [],
            loading: false,
            isRawText: true,
        };
    },
    computed: {
        ...workflowDetailsGetters({
            selectedNode: 'SELECTED_NODE',
            isServerless: 'IS_SERVERLESS',
        }),
        settings() {
            return this.selectedNode.settings;
        },
        action() {
            return this.selectedNode.action_type;
        },
    },
    methods: {
        onUpdate(value, path) {
            this.$emit('update', value, path);
        },
        setInputOutput(uid) {
            const input = cloneDeep(this.selectedNode.input);
            const selectedOption = this.availableInputFields.find(
                (f) => f.uid === uid
            );
            const field = selectedOption.field;
            input.inputFieldPath = selectedOption.name;
            input.inputFieldName = field.name;
            input.inputFieldId = field.uid;

            this.onUpdate(input, 'input');
        },
    },
};
</script>

<style lang="scss" scoped></style>

<template>
  <node-input v-if="selectedNode"></node-input>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';
import { findNode } from '@/util/action-types';
import { shTypes } from '@/util/actionsModels';

import NodeInput from '../node-input/node-input.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: workflowDetailsGetters,
  mapMutations: workflowDetailsMutations,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: credentialsGetters } = createNamespacedHelpers(
  'credentials'
);

export default {
  name: 'SettingsBlock',
  components: {
    NodeInput,
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      actionsOutputs: 'ACTION_OUTPUTS',
    }),
    ...credentialsGetters({
      authentications: 'AUTHENTICATIONS',
    }),
    availableActions() {
      let excluded_actions = [];
      if (
        this.customer &&
        this.customer.CustomersFeatures &&
        this.customer.CustomersFeatures.excluded_actions
      ) {
        excluded_actions = this.customer.CustomersFeatures.excluded_actions;
      }
      if (!this.selectedNode) return [];
      return this.selectedNode.actions.filter((action) => {
        return !excluded_actions.includes(action.action_type);
      });
    },
    inputNode() {
      let sn = this.selectedNode;
      const id = sn && sn.input ? sn.input.inputNodeId : null;
      return findNode(this.nodes, id);
    },
    availableLinks() {
      return [
        { value: null, text: 'No link' },
        { value: 'prePay', text: 'prePay' },
        { value: 'checkIn', text: 'checkIn' },
        { value: 'billing', text: 'billing' },
      ];
    },

    actionType() {
      return this.selectedNode ? this.selectedNode.action_type : '';
    },
    settings() {
      return this.selectedNode ? this.selectedNode.settings : {};
    },
  },
  watch: {
    actionType() {
      if (this.actionType === 'get_patient_insurance') {
        this.updatePatientInsuranceOutput();
      }
    },
  },
  methods: {
    ...workflowDetailsMutations({
      setSelectedNode: 'SET_SELECTED_NODE',
    }),
    availableInputFields() {
      if (!this.inputModel) return [];
      if (this.selectedNode.action_type === 'loop') {
        if (Array.isArray(this.inputModel)) {
          const items = this.inputModel.filter(
            (item) => item.type === shTypes.ARRAY
          );
          return items.map((i) => {
            return { name: i.name, uid: i.uid, field: i };
          });
        } else if (
          typeof this.inputModel === 'object' &&
          this.inputModel.type === shTypes.ARRAY
        ) {
          return [
            {
              name: this.inputModel.name,
              uid: this.inputModel.uid,
              field: this.inputModel,
            },
          ];
        } else {
          return [];
        }
      } else {
        const result = [];
        const flattenObjFields = (items, prefix) => {
          items.forEach((prop) => {
            const propWithPrefix = {
              name: !prefix ? prop.name : `${prefix}.${prop.name}`,
              uid: prop.uid,
              field: prop,
            };

            if (prop.type === shTypes.OBJECT) {
              result.push(propWithPrefix);
              flattenObjFields(prop.value, propWithPrefix.name);
            } else {
              result.push(propWithPrefix);
            }
          });
        };

        if (this.inputModel.type === shTypes.OBJECT) {
          flattenObjFields(this.inputModel.value, this.inputModel.name);
        } else if (this.inputModel.type === shTypes.ARRAY) {
          console.error("conditionals don't expect arrays", this.inputModel);
          return;
        } else {
          return [
            {
              name: this.inputModel.name,
              uid: this.inputModel.uid,
              field: this.inputModel,
            },
          ];
        }
        return result;
      }
    },
    updatePatientInsuranceOutput() {
      const models = this.actionsOutputs[this.actionType];
      const outputIndex = Number(!!this.settings.full_response);

      if (models && Array.isArray(models)) {
        const selectedNode = cloneDeep(this.selectedNode);
        selectedNode.output_type = { ...models[outputIndex] };
        this.setSelectedNode(selectedNode);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './settings-block';
</style>

<template>
  <div>
    <div>
      <v-select
        class="mb-3 mt-2"
        outlined
        dense
        :value="selectedNode.settings.fileBase64"
        @input="(val) => onUpdate(val, 'settings.fileBase64')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="File Base64"
        clearable></v-select>
      <v-switch
        label="Multiple pages document"
        class="mt-2"
        :input-value="settings.isMultiPage"
        @change="toggleMultiPageOption"></v-switch>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'aws-textract',
  props: {
    availableInputFields: { required: true },
  },
  components: {},
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },

    toggleMultiPageOption() {
      const settings = cloneDeep(this.settings);
      settings.isMultiPage = !settings.isMultiPage;
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss"></style>

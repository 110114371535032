<template>
  <div class="LoopNode">
    <div class="LoopNode__arrows">
      <div class="arrow-down">
        <div class="arrow-down__line"></div>
      </div>
    </div>

    <general-node
      :node="node"
      :index="index"
      :parent-node-list="parentNodeList"
      :parent-node="parentNode"
    />

    <div class="LoopNode__arrows">
      <div class="arrow-down">
        <div class="arrow-down__line"></div>
      </div>
    </div>

    <div
      class="plus-block"
      @dragover.stop.prevent="dragOver($event, 0, null, null, true)"
      :id="loopId"
    >
      <action-list-menu
        :insertIndex="0"
        :parent-node="node"
        :parent-node-list="computedParentNodeList"
      />
    </div>

    <div
      class="block__container d-flex flex-column align-center"
      v-for="(subNode, subNodeindex) in node.settings.cycleBody"
      :key="subNode.id"
    >
      <node-resolver
        :node="subNode"
        :index="subNodeindex"
        :parent-node="node"
        :parent-node-list="computedParentNodeList"
      />
    </div>

    <div class="LoopNode__arrows">
      <div class="arrow-down">
        <div class="arrow-down__line"></div>
      </div>
    </div>

    <general-node
      :node="{ ...node, action_type: 'loop_output' }"
      :index="index"
      :parent-node-list="parentNodeList"
      :parent-node="parentNode"
    />

    <div class="LoopNode__arrows">
      <div class="arrow-down">
        <div class="arrow-down__line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoopNode',
  components: {
    GeneralNode: () =>
      import('@/components/node-types/general-node/general-node.vue'),
    NodeResolver: () => import('@/components/node-resolver/node-resolver.vue'),
    ActionListMenu: () => import('@/components/action-list/action-list.vue'),
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentNodeList: {
      type: Array,
      default: () => [],
    },
    parentNode: {
      required: false,
      default: null,
    },
  },
  computed: {
    loopId() {
      return this.node.id + '|in|DEST';
    },
    computedParentNodeList() {
      return [
        ...this.parentNodeList,
        { nodeId: this.node.id, index: this.index },
      ];
    },
  },
  methods: {
    dragOver() {},
  },
};
</script>

<style scoped lang="scss">
@import './loop-node.scss';
</style>

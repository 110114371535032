var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('h3',{staticClass:"pb-4"},[_vm._v("Input")]),(
        !_vm.selectedNode.settings.types ||
        _vm.selectedNode.settings.types.length !== _vm.appOptions.length
      )?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.selectAll()}}},[_vm._v(" Select all ")]):_vm._e(),(
        _vm.selectedNode.settings.types && _vm.selectedNode.settings.types.length > 0
      )?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.selectedNode.settings.types = []}}},[_vm._v(" Clear all ")]):_vm._e(),(_vm.appOptions)?_c('multiselect',{attrs:{"value":_vm.selectedNode.settings.types,"tag-placeholder":"Add this as new tag","placeholder":"Choose appointment types to include","label":"appointment_type_name","track-by":"id","options":_vm.appOptions,"multiple":true,"taggable":true},on:{"input":(val) => _vm.onUpdate(val, 'settings.types')}}):_vm._e(),_c('br'),_c('p',{staticClass:"text--secondary subtitle-2"},[_vm._v(" Choose when to look for the appointments ")]),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Period","hide-details":"auto","type":"number","value":_vm.selectedNode.settings.period},on:{"input":(val) => _vm.onUpdate(val, 'settings.period')}}),_c('p',{staticClass:"text--secondary subtitle-2 mt-2 mx-4"},[_vm._v("Days")]),_c('v-select',{attrs:{"outlined":"","dense":"","value":_vm.selectedNode.settings.sign,"item-text":"name","item-value":"value","items":[
          { name: 'In past', value: '-' },
          { name: 'In future', value: '+' },
        ],"label":"Before/After","clearable":""},on:{"input":(val) => _vm.onUpdate(val, 'settings.sign')}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <div>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.device_id"
        @input="(val) => onUpdate(val, 'settings.device_id')"
        item-text="name"
        item-value="uid"
        :items="devices"
        label="Set target device"
        clearable
      ></v-select>
      <div v-if="selectedNode.action_type === 'dental_get_scheduled'">
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.date"
          @input="(val) => onUpdate(val, 'settings.date')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Desired date"
          clearable
        ></v-select>
      </div>

      <div v-if="selectedNode.action_type === 'waco_update_benefits'">
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.patient_id"
          @input="(val) => onUpdate(val, 'settings.patient_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient nbr"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.member_id"
          @input="(val) => onUpdate(val, 'settings.member_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Policy no enc"
          clearable
        ></v-select>
        <br />
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.is_active"
          @input="(val) => onUpdate(val, 'settings.is_active')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Insurance is active"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.effective_date"
          @input="(val) => onUpdate(val, 'settings.effective_date')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Effective date"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.ded_max"
          @input="(val) => onUpdate(val, 'settings.ded_max')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Deductible"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.copay"
          @input="(val) => onUpdate(val, 'settings.copay')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Copayment"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.notes"
          @input="(val) => onUpdate(val, 'settings.notes')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Notes"
          clearable
        ></v-select>
      </div>

      <div v-if="selectedNode.action_type === 'waco_set_alerts'">
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.patient_id"
          @input="(val) => onUpdate(val, 'settings.patient_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient nbr"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.app_date"
          @input="(val) => onUpdate(val, 'settings.app_date')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Appointment date"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.event"
          @input="(val) => onUpdate(val, 'settings.event')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Appointment event"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.primary"
          @input="(val) => onUpdate(val, 'settings.primary')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Primary partner note"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.secondary"
          @input="(val) => onUpdate(val, 'settings.secondary')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Secondary partner note"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.estimate"
          @input="(val) => onUpdate(val, 'settings.estimate')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Estimate note"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.quick_note"
          @input="(val) => onUpdate(val, 'settings.quick_note')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Quick note"
          clearable
        ></v-select>
      </div>

      <div v-else-if="selectedNode.action_type === 'dental_update_benefits'">
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.patient_id"
          @input="(val) => onUpdate(val, 'settings.patient_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient ID/number"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.firstname"
          @input="(val) => onUpdate(val, 'settings.firstname')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient's firstname"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.lastname"
          @input="(val) => onUpdate(val, 'settings.lastname')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient's lastname"
          clearable
        ></v-select>
        <v-divider inset></v-divider>
        <v-select
          class="mb-4"
          outlined
          dense
          :value="selectedNode.settings.benefits"
          @input="(val) => onUpdate(val, 'settings.benefits')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Updated benefits"
          clearable
        ></v-select>
      </div>
      <div v-else-if="selectedNode.action_type === 'post_rob'">
        <v-select
          class="mb-n4"
          outlined
          dense
          :value="selectedNode.settings.visitIds"
          @input="(val) => onUpdate(val, 'settings.visitIds')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Array of Visit IDs"
          clearable
        ></v-select>
      </div>
      <v-select
        v-else
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Target patient ID"
        clearable
      ></v-select>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: devicesGetters } = createNamespacedHelpers("devices");
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "remote-vm-settings",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    ...devicesGetters({
      devices: "DEVICES",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
    <div class="settings-col">
        <v-select
                class="param-row__field mr-2"
                outlined
                dense
                item-text="name"
                item-value="id"
                :value="settings.modelId"
                @input="(val) => onUpdate(val, 'settings.modelId')"
                :items="models"
                @change="updateModel"
                :loading="fetchModelLoading"
                :label="'ML Model'"
                clearable
        ></v-select>

        <h4 class="pt-4 pb-2">Model Inputs</h4>
        <div v-if="settings.endpoint && settings.fields">
            <div v-for="(input, index) in settings.fields" :key="index">
                <v-select
                    class="mb-3 mt-2"
                    outlined
                    dense
                    :value="input.value"
                    @input="(val) => onUpdate(val, `settings.fields[${index}].value`)"
                    item-text="name"
                    item-value="value"
                    :items="availableInputFields"
                    :label="input.name"
                    clearable></v-select>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

const { mapGetters: modelsGetter, mapActions: modelsActions } =
    createNamespacedHelpers('MLModels');

const { mapGetters: workflowDetailsGetter } =
    createNamespacedHelpers('workflows/details');

export default {
    name: 'model-settings',
    components: {},
    props: {
        availableInputFields: { required: true },
    },
    computed: {
        ...modelsGetter({
            models: 'MODELS',
            model: 'MODEL',
            fetchModelLoading: 'FETCH_MODEL_LOADING',
        }),
        ...workflowDetailsGetter({
            selectedNode: 'SELECTED_NODE',
        }),
        settings() {
            return this.selectedNode.settings;
        },
    },
    created() {
        if (this.selectedNode.settings.endpoint) {
            this.updateWorker(false);
        }
    },
    methods: {
        ...modelsActions(['fetchModel', 'cleaModel','fetchModels']),
        onUpdate(value, path) {
            this.$emit('update', value, path);
        },
        async updateModel(overwrite = true) {
            const settings = cloneDeep(this.settings);

            if (settings.modelId) {
                await this.fetchModel(settings.modelId);
            }

            if (overwrite) {
                const output_type = cloneDeep(this.model.output);
                settings.fields = cloneDeep(this.model.input.fields);
                settings.inputType = cloneDeep(this.model.inputType);
                settings.endpoint = cloneDeep(this.model.endpoint);
                this.onUpdate(output_type, 'output_type');
                this.onUpdate(settings, 'settings');
            }
        },
    },
    mounted() {
        this.fetchModels()
    },
    beforeDestroy() {
        this.clearModel();
    },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <h4 class="mt-5">
      Caution: This connector resets the balances of all patients for this
      customer to zero. To reset some patient use the input below or leave it
      blank to reset all patients.
    </h4>
    <v-select
      class="mt-2"
      outlined
      dense
      :value="settings.patients"
      @input="(val) => onUpdate(val, 'settings.patients')"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      label="Array of Patient Ids"
      clearable
    ></v-select>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'reset-balances',
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped></style>

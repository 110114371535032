export const actionDocs = {
  'try_catch': 'https://docs.google.com/document/d/1JkrzzVhqSCHa3uGcR6otGRuD4MyliEhTlNyXYBfZvR0/edit?usp=sharing',
  'loop': 'https://docs.google.com/document/d/1zN5w1xMzRxbn9WkF3qMZNZaASYBk2x_ZzzlHgrm_BQs/edit?usp=sharing',
  'conditional': 'https://docs.google.com/document/d/19OQ1bwvE654I9sf3Yu_8wkwXawaFw6Veh1X4ZguuTsk/edit',
  'execute_javascript': 'https://docs.google.com/document/d/1oPTTKzj5nYeO4Ushy34uy63lqLjsvs-aBWZQJtnFGaY/edit',
  'short_browser_auto_playwright': 'https://docs.google.com/document/d/107nJ6WADmDmcTdZPRnqaPhHC_7VD50mvPiJ4zvgTSw8/edit',
  'aws_claude_prompt': 'https://docs.google.com/document/d/1ilcL4yBwdLZzgRuGQGXleIWa0DJhC0pbZG5fzeirKAo/edit',
  'aws_textract': 'https://docs.google.com/document/d/1giMDA97bSZX-g5eRUaCG5TOiaCa4tOAe3RC_0HALp9U/edit',
  'gen_ai_prompt': 'https://docs.google.com/document/d/1ilcL4yBwdLZzgRuGQGXleIWa0DJhC0pbZG5fzeirKAo/edit',
  'ai_text_extract': 'https://docs.google.com/document/d/1giMDA97bSZX-g5eRUaCG5TOiaCa4tOAe3RC_0HALp9U/edit',
  'pdf_split_file': 'https://docs.google.com/document/d/1SpGcOqcG7LToj7HshIfF7wwnoIKDc5KnSeg7OahOZ6Y/edit',

  // ms graph
  'msgraph_mail_read_email': 'https://docs.google.com/document/d/1inJQxHP3eXF6rlLkB7KU29v-D033c82nfm7uJ26LoPI/edit#heading=h.4sip2w251sko',
  'msgraph_mail_send_email': 'https://docs.google.com/document/d/1dL7o4BfEnsr_xkh-3ldA8gHe2x2u13-xecl5e_HDk3c/edit#heading=h.4sip2w251sko',
  'msgraph_onedrive_create_folder': 'https://docs.google.com/document/d/1Qg5mqmX5q_168CCGrssQq_K1WHY4q0SgX0OZifZRnl0/edit',
  'msgraph_onedrive_download_file': 'https://docs.google.com/document/d/1S4qwk4D70hwzOktpmeBkaDsINz31TeyQ0MBh_Szvmgw/edit',
  'msgraph_onedrive_get_folder': 'https://docs.google.com/document/d/1cI6QK0gBSUX03fuBiY-aHIyXD5KGhkmxxYQ6dKo4ueI/edit',
  'msgraph_onedrive_move_file': 'https://docs.google.com/document/d/18svDWLKs6Rcxx9K141x8tx6lHuPUIjRJcDXbsuWRSBI/edit',
  'msgraph_onedrive_upload_file': 'https://docs.google.com/document/d/1rf5DgczS2u7CNrEyXul_SHN3wsX0b6DiC4iv6eXPTGc/edit',
  'msgraph_workbook_read_data': 'https://docs.google.com/document/d/1dIuyLx3wg9VoRCufHt0jJ9wetF-Vv2a8nqd1z_jDFGY/edit',
  'msgraph_workbook_update_cell': 'https://docs.google.com/document/d/1oW4bbRMveai996i9Brjdmrz19TN0dwh9sSu9vE67bpg/edit',
  'msgraph_workbook_write_data': 'https://docs.google.com/document/d/1vzS9DQ76FHq-PaOAhORj8LNT9k9H6yAcRO7zYjIXJXA/edit',
};

<template>
  <div>
    <div>
      <h4 class="mt-5">Invoice Settings</h4>
      <div class="d-flex align-center justify-space-between">
        <v-select
          label="Charge ID"
          class="mt-2"
          :value="settings.invoice.fields.charge_id"
          @input="(val) => onUpdate(val, 'settings.invoice.fields.charge_id')"
          :items="availableInputFields"
          item-text="name"
          item-value="name"
          outlined
          dense></v-select>
      </div>
      <v-select
        label="Assign To(patient id)"
        class="mt-2"
        :value="settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
    </div>

    <div>
      <h4 class="mt-5">Field Mapping</h4>

      <v-select
        label="Service Detail ID"
        class="mt-2"
        :value="settings.invoice.fields.service_detail_id"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.service_detail_id')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Procedure Code"
        class="mt-2"
        :value="settings.invoice.fields.procedure_code"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.procedure_code')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Procedure Description"
        class="mt-2"
        :value="settings.invoice.fields.procedure_description"
        @input="
          (val) =>
            onUpdate(val, 'settings.invoice.fields.procedure_description')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Total Charge"
        class="mt-2"
        :value="settings.invoice.fields.total_charge"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.total_charge')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Date of Service"
        class="mt-2"
        :value="settings.invoice.fields.date_of_service"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.date_of_service')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Patient Paid"
        class="mt-2"
        :value="settings.invoice.fields.patient_paid"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.patient_paid')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Patient Balance"
        class="mt-2"
        :value="settings.invoice.fields.patient_balance"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.patient_balance')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Paid"
        class="mt-2"
        :value="settings.invoice.fields.insurance_paid"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_paid')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Balance"
        class="mt-2"
        :value="settings.invoice.fields.insurance_balance"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_balance')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Adjusted Amount"
        class="mt-2"
        :value="settings.invoice.fields.adjusted_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.adjusted_amount')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Insurance Adjusted Amount"
        class="mt-2"
        :value="settings.invoice.fields.insurance_adj_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.insurance_adj_amount')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Write off Amount"
        class="mt-2"
        :value="settings.invoice.fields.write_off_amount"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.write_off_amount')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Last Statement Date"
        class="mt-2"
        :value="settings.invoice.fields.last_statement_date"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.last_statement_date')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Last Statement Amount"
        class="mt-2"
        :value="settings.invoice.fields.last_statement_amount"
        @input="
          (val) =>
            onUpdate(val, 'settings.invoice.fields.last_statement_amount')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Posting Date"
        class="mt-2"
        :value="settings.invoice.fields.posting_date"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.posting_date')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Paid?"
        class="mt-2"
        :value="settings.invoice.fields.statusIsPaid"
        @input="(val) => onUpdate(val, 'settings.invoice.fields.statusIsPaid')"
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
      <v-select
        label="Location ID"
        class="mt-2"
        :value="settings.invoice.fields.location_id_pk"
        @input="
          (val) => onUpdate(val, 'settings.invoice.fields.location_id_pk')
        "
        :items="availableInputFields"
        item-text="name"
        item-value="name"
        outlined
        dense></v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { findNode } from '@/util/action-types';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'invoice-settings',
  props: {
    availableInputFields: { required: true },
    availableInputs: { required: true },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      executing: false,
      statuses: [
        { name: 'Not Paid', value: false },
        { name: 'Paid', value: true },
      ],
      invoiceTypes: ['prePay', 'postPay'],
      invoiceSources: ['greenway', 'nextgen'],
      dateMenu: false,
    };
  },
  created() {
    if (!this.settings.invoice) {
      this.settings.invoice = {
        fields: {},
      };
    }
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    input() {
      return this.selectedNode.input;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    findNode(id) {
      return findNode(this.availableInputs, id);
    },
    inputAction(inputNode) {
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    autogenerateChargeId(val) {
      this.onUpdate(undefined, 'settings.invoice.fields.charge_id');
      this.onUpdate(val, 'settings.invoice.auto_charge_id');
    },
  },
};
</script>

<style scoped></style>

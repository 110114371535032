<template>
  <div>
    <div>
      <h4 class="mt-5">Settings</h4>

      <div justify="space-between" class="my-4" dense>
        <v-label>
          <b>Posted X days ago</b>
        </v-label>
        <v-text-field
          outlined
          dense
          label="From (Required)"
          hide-details="auto"
          class="mb-1 mt-1"
          type="number"
          :value="selectedNode.settings.from"
          @input="(val) => onUpdate(val, 'settings.from')"
        />
        <v-text-field
          outlined
          dense
          label="To (Required)"
          hide-details="auto"
          class="mb-1"
          type="number"
          :value="selectedNode.settings.to"
          @input="(val) => onUpdate(val, 'settings.to')"
        />
      </div>

      <v-select
        label="Status"
        class="mt-3"
        :value="settings.statusIsPaid"
        @input="(val) => onUpdate(val, 'settings.statusIsPaid')"
        :items="chargeStatuses"
        item-text="name"
        item-value="value"
        outlined
        dense
        clearable
      ></v-select>

      <v-select
        label="Invoice Type"
        class="mt-2"
        :value="settings.invoice_type"
        @input="(val) => onUpdate(val, 'settings.invoice_type')"
        :items="invoiceTypes"
        item-text="name"
        item-value="name"
        outlined
        dense
        clearable
      >
      </v-select>

      <v-select
        label="Payment Plan Status"
        class="mt-2"
        :value="settings.planStatus"
        @input="(val) => onUpdate(val, 'settings.planStatus')"
        :items="planStatuses"
        item-text="name"
        item-value="value"
        outlined
        dense
        clearable
      >
      </v-select>

      <v-text-field
        outlined
        class="mt-2"
        dense
        label="Last Notification days ago (Required)"
        hide-details="auto"
        type="number"
        :value="settings.notifyGap"
        @input="(val) => onUpdate(val, 'settings.notifyGap')"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'get-patients-charges',
  props: {
    availableInputFields: { required: true },
    availableInputs: { required: true },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      executing: false,
      chargeStatuses: [
        { name: 'Not Paid', value: false },
        { name: 'Paid', value: true },
      ],
      planStatuses: [
        { name: 'Autopay', value: 'autopay' },
        { name: 'Manual', value: 'manual' },
      ],
      invoiceTypes: ['prePay', 'postPay'],
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <div>
      <div v-if="selectedNode.action_type === 'update_patient_insurance'">
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.patient_id"
          @input="(val) => onUpdate(val, 'settings.patient_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient ID"
          clearable
        ></v-select>
      </div>
      <div v-if="selectedNode.action_type === 'get_insurance_benefits'">
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.firstname"
          @input="(val) => onUpdate(val, 'settings.firstname')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="firstname"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.lastname"
          @input="(val) => onUpdate(val, 'settings.lastname')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="lastname"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.date_of_birth"
          @input="(val) => onUpdate(val, 'settings.date_of_birth')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="date of birth"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.member_id"
          @input="(val) => onUpdate(val, 'settings.member_id')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="member id"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.insurance_company"
          @input="(val) => onUpdate(val, 'settings.insurance_company')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="insurance company"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.provider_type"
          @input="(val) => onUpdate(val, 'settings.provider_type')"
          item-text="name"
          item-value="name"
          :items="['primary', 'secondary']"
          label="Provider type"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.coverage_levels"
          @input="(val) => onUpdate(val, 'settings.coverage_levels')"
          multiple
          item-text="name"
          item-value="name"
          :items="['individual', 'family']"
          label="Coverage level"
          clearable
        ></v-select>
        <v-select
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.pid"
          @input="(val) => onUpdate(val, 'settings.pid')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Patient ID (optional)"
          clearable
        ></v-select>
        <v-checkbox
          class="mb-3"
          outlined
          dense
          :input-value="selectedNode.settings.in_plan"
          @change="(val) => onUpdate(val, 'settings.in_plan')"
          label="In plan network only"
        ></v-checkbox>
        <v-checkbox
          class="mb-3"
          outlined
          dense
          :input-value="selectedNode.settings.update_primary"
          @change="(val) => onUpdate(val, 'settings.update_primary')"
          label="Update primary insurance"
        ></v-checkbox>
      </div>

      <p class="text-h6 my-5">Service type codes</p>
      <div
        my-5
        class="param-row mb-3"
        v-for="(serviceType, i) in selectedNode.settings.codes"
        :key="serviceType.id"
      >
        <v-select
          outlined
          dense
          :value="serviceType"
          :item-text="formatCodeDescription"
          :items="availableCodes"
          label="Service type code"
          @change="(value) => changeCode(value, i)"
          return-object
          clearable
        ></v-select>
        <v-btn class="param-row__icon-btn" icon @click="deleteCode(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn class="mb-8" @click="addServiceCode"> + Add </v-btn>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: sharedGetters } = createNamespacedHelpers('shared');

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "benefits-settings",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    ...sharedGetters({
      availableCodes: "SERVICE_TYPES",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    formatCodeDescription(item) {
      return item.code + " - " + item.description;
    },
    deleteCode(index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes.splice(index, 1);
      this.onUpdate(settings, "settings");
    },
    changeCode(value, index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes[index] = value;
      this.onUpdate(settings, "settings");
    },
    addServiceCode() {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes.push({
        id: null,
        description: null,
        code: null,
      });
      this.onUpdate(settings, "settings");
    },
  },
};
</script>

<style scoped lang="scss">
.param-row {
  display: flex;
  width: 100%;

  &__field {
    width: 33%;
  }
  &__icon-btn {
    width: 10%;
  }
}
</style>

<template>
  <div class="ObjectHelperSettings">
    <p class="text-h5 mt-5 mb-2">Select Connectors and Fields</p>
    <ChainOutput
      :availableInputs="availableInputs"
      :outputs="settings.outputs"
      :internalInputs="internalInputs"
      :availableInputFields="availableInputFields"
      :changeInput="changeInput"
      :addInput="addInput"
      @updateOutput="updateOutput"
    />
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { isObject } from 'lodash';
import { createNamespacedHelpers } from 'vuex';

import { shTypes, cloneModel, genUid } from '@/util/actionsModels';
import { findNode, getInputModel } from '@/util/action-types';
import ChainOutput from '@/components/connector-settings/chain-outputs/chain-output.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'loop-output-settings',
  props: {
    availableInputs: { required: true },
  },
  components: {
    ChainOutput,
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      nodes: 'NODES',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    internalInputs() {
      return this.selectedNode.settings.cycleBody;
    },
  },
  mounted() {
    if (!this.settings.outputs) {
      this.addInput();
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    updateOutput(outputs) {
      const settings = cloneDeep(this.settings);
      settings.outputs = outputs;
      this.onUpdate(settings, 'settings');
      this.updateModel();
    },
    findNode(id) {
      return findNode(this.internalInputs, id);
    },
    addInput() {
      const settings = cloneDeep(this.settings);

      if (!settings.outputs) {
        settings.outputs = [];
      }

      settings.outputs.push({
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
        params: [],
      });

      this.onUpdate(settings, 'settings');
    },
    updateModel() {
      // set default output model
      if (!this.selectedNode.output_external?.uid) {
        const output_external = {
          name: 'LoopOutput',
          type: 'array',
          uid: genUid(),
          value: [
            {
              name: 'Object',
              type: 'object',
              uid: genUid(),
              value: [],
            },
          ],
        };
        this.onUpdate(output_external, 'output_external');
      }
      let keysList = [];

      this.settings.outputs.forEach((output, index) => {
        //  clone output models from source models with new names and uids
        for (let param in output.params) {
          const output_external = cloneDeep(this.selectedNode.output_external);
          const newName = output.params[param]['name'];
          const sourcePath = output.params[param]['value'];
          if (!sourcePath) continue;

          const nodeOutputs = this.availableInputFields(index);
          const sourceModel = nodeOutputs.find(
            (field) => field.name === sourcePath
          );

          const existedParamIdx = output_external.value[0].value.findIndex(
            (v) => v.name == newName
          );

          if (existedParamIdx !== -1) {
            output_external.value[0].value[existedParamIdx] = cloneModel(
              newName,
              sourceModel.field
            );
          } else {
            output_external.value[0].value.push(
              cloneModel(newName, sourceModel.field)
            );
          }
          this.onUpdate(output_external, 'output_external');
        }

        keysList = [...keysList, ...output.params.map((p) => p.name)];
      });

      // remove deleted fields from output model
      const filteredModelValues =
        this.selectedNode.output_external.value[0].value.filter((outputParam) =>
          keysList.find((key) => key == outputParam.name)
        );

      const output_external = cloneDeep(this.selectedNode.output_external);
      output_external.value[0].value = filteredModelValues;
      this.onUpdate(output_external, 'output_external');
    },
    inputAction(inputNode) {
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    getInputModel(index) {
      const inputNodeId = this.settings.outputs[index].inputNodeId;
      const inputNode = this.findNode(inputNodeId);
      const inputAction = this.inputAction(inputNode);

      const options = {
        inputNodeId,
        inputNode,
        inputAction,
        nodes: this.nodes,
        nodeId: this.selectedNode.id,
      };
      return getInputModel(options);
    },
    availableInputFields(index) {
      let result = [];

      const inputModel = this.getInputModel(index);
      if (inputModel) {
        if (Array.isArray(inputModel)) {
          const items = inputModel
            .filter((item) => item.type === shTypes.ARRAY)
            .map((i) => ({ name: i.name, uid: i.uid, field: i }));
          result.push(...items);
        } else if (
          typeof inputModel === 'object' &&
          inputModel.type === shTypes.ARRAY
        ) {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
        const flattenObjFields = (items, prefix) => {
          if (isObject(items)) {
            items.forEach((prop) => {
              const propWithPrefix = {
                name: !prefix ? prop.name : `${prefix}.${prop.name}`,
                uid: prop.uid,
                field: prop,
              };

              if (prop.type === shTypes.OBJECT) {
                result.push(propWithPrefix);
                flattenObjFields(prop.value, propWithPrefix.name);
              } else {
                result.push(propWithPrefix);
              }
            });
          }
        };

        if (inputModel.type === shTypes.OBJECT) {
          flattenObjFields(inputModel.value, inputModel.name);
        } else {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
      }

      return result;
    },
    changeInput(index) {
      const settings = cloneDeep(this.settings);
      const inputModel = this.getInputModel(index);
      const inputFields = this.availableInputFields(index);

      if (inputFields && inputFields.length) {
        settings.outputs[index].inputFieldPath = inputModel.name;
        settings.outputs[index].inputFieldName = inputModel.name;
        settings.outputs[index].inputFieldId = inputModel.uid;
      }
      this.onUpdate(settings, 'settings');
      this.updateModel();
    },
  },
};
</script>

<style scoped lang="scss"></style>

import { render, staticRenderFns } from "./remote-vm-settings.vue?vue&type=template&id=8586b540&scoped=true"
import script from "./remote-vm-settings.vue?vue&type=script&lang=js"
export * from "./remote-vm-settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8586b540",
  null
  
)

export default component.exports
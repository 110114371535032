<template>
  <div class="EmailSettings">
    <mustache-template
      label="Add To Emails"
      placeholder="Array of Email addresses(Comma separated email addresses)"
      :suggestions="availableInputFields"
      :singleLine="true"
      :value="settings.toEmails"
      @input="(val) => onUpdate(val, 'settings.toEmails')"
    />

    <div class="mt-3">
      <mustache-template
        label="Enter subject"
        :value="settings.subject"
        @input="(val) => onUpdate(val, 'settings.subject')"
        :suggestions="availableInputFields"
        :singleLine="true"
        class="mb-3"
      />

      <v-autocomplete
        outlined
        dense
        :value="settings.attachment"
        item-text="name"
        item-value="name"
        @update:search-input="setAttachment"
        @change="setAttachment"
        :items="availableInputFields"
        label="Attachment"
        class="mb-3"
      />

      <mustache-template
        label="Body"
        :value="settings.template"
        @input="(val) => onUpdate(val, 'settings.template')"
        :suggestions="availableInputFields"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { createNamespacedHelpers } from "vuex";
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "email-settings",
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    setAttachment(name) {
      const settings = cloneDeep(this.settings);
      settings.attachments = name;
      this.onUpdate(settings, "settings");
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div>
    <v-select
      outlined
      dense
      :value="settings.base64File"
      @input="(val) => onUpdate(val, `settings.base64File`)"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      label="Base64 File"
      clearable
      class="mt-3"
    ></v-select>

    <mustache-template
      class="mt-3"
      label="Starting page numbers"
      placeholder="Array of numbers or comma separated numbers"
      :value="settings.startPageNumbers"
      @input="(val) => onUpdate(val, 'settings.startPageNumbers')"
      :suggestions="availableInputFields"
      :singleLine="true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'pdf-split-file',
  props: {
    availableInputFields: { required: true },
  },
  components: {
    MustacheTemplate,
  },
  data() {
    return {};
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
    <div>
        <div>
            <v-select
                    class="mb-3 mt-2"
                    outlined
                    dense
                    :value="selectedNode.settings.fileBase64"
                    @input="(val) => onUpdate(val, 'settings.fileBase64')"
                    item-text="name"
                    item-value="name"
                    :items="availableInputFields"
                    label="File Base64"
                    clearable></v-select>
            <div>
                <v-text-field
                    outlined
                    dense
                    label="Bucket"
                    hide-details="auto"
                    type="text"
                    class="mb-3"
                    :value="selectedNode.settings.bucket"
                    @input="(val) => onUpdate(val, 'settings.bucket')"
                ></v-text-field>
            </div>
            <div>
                <v-text-field
                    outlined
                    dense
                    label="Folder"
                    hide-details="auto"
                    type="text"
                    class="mb-3"
                    :value="selectedNode.settings.folder"
                    @input="(val) => onUpdate(val, 'settings.folder')"
                ></v-text-field>
            </div>
            <div>
                <v-text-field
                    outlined
                    dense
                    label="Content Type"
                    hide-details="auto"
                    type="text"
                    class="mb-3"
                    :value="selectedNode.settings.contentType"
                    @input="(val) => onUpdate(val, 'settings.contentType')"
                ></v-text-field>
            </div>
            <div class="AwsClaudeSettings mt-1">
                <v-row
                        dense
                        align="center">
                    <v-col
                            cols="auto"
                            class="mr-auto">
                        <p class="text-subtitle-1 mb-0">Metadata Parameters</p>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn
                                v-if="showAddMetadataBtn"
                                @click="addMetadata()"
                                text
                                color="primary"
                                dense>
                            + Add
                        </v-btn>
                    </v-col>
                </v-row>
                <div
                        class="AwsClaudeSettings__param-row mb-6"
                        v-for="(metadata, i) in settings.metadata"
                        :key="i">
                    <v-text-field
                            class="AwsClaudeSettings__param-row--field mr-2"
                            outlined
                            dense
                            label="Name"
                            :value="metadata.name"
                            @input="(val) => onUpdate(val, `settings.metadata[${i}].name`)" />
                    <v-select
                            class="AwsClaudeSettings__param-row--field mr-2"
                            outlined
                            dense
                            :value="metadata.value"
                            @input="(val) => onUpdate(val, `settings.metadata[${i}].value`)"
                            item-text="name"
                            item-value="name"
                            :items="availableInputFields"
                            label="Value"
                            clearable></v-select>
                    <v-btn
                            class="AwsClaudeSettings__param-row--icon-btn"
                            icon
                            @click="deleteMetadata(i)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from "lodash/cloneDeep";

const { mapGetters: workflowDetailsGetters } =
    createNamespacedHelpers('workflows/details');

export default {
    name: 'aws-s3-upload',
    props: {
        availableInputFields: { required: true },
    },
    components: {},
    computed: {
        ...workflowDetailsGetters({
            selectedNode: 'SELECTED_NODE',
        }),
        settings() {
            return this.selectedNode.settings;
        },
        showAddMetadataBtn() {
            const metadata = this.settings.metadata;
            return (
                !metadata ||
                !metadata[0] ||
                (metadata[metadata.length - 1].name && metadata[metadata.length - 1].value)
            );
        },
    },
    methods: {
        onUpdate(value, path) {
            this.$emit('update', value, path);
        },
        addMetadata() {
            let settings = cloneDeep(this.settings);
            if (!settings.metadata) {
                settings.metadata = [];
            }

            settings.metadata.push({
                name: '',
                value: '',
            });
            this.onUpdate(settings, 'settings');
        },
        deleteMetadata(index) {
            const settings = cloneDeep(this.settings);
            settings.metadata.splice(index, 1);
            if (!settings.metadata.length) delete settings.metadata;

            this.onUpdate(settings, 'settings');
        },
    },
};
</script>

<style scoped lang="scss">
@import './aws-claude';
</style>

<template>
  <div>
    <div>
      <v-select
        outlined
        dense
        :value="selectedNode.settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Choose patient id"
        clearable
      ></v-select>

      <v-text-field
        class="mt-3"
        outlined
        dense
        :value="selectedNode.settings.field_id"
        @input="(val) => onUpdate(val, 'settings.field_id')"
        label="Enter customer field id"
      ></v-text-field>

      <div class="mt-3">
        <mustache-template
          label="Enter customer field value"
          :placeholder="'Example: Error verifying insurance: {{patient.ribbonResponse.status.error}}'"
          :value="selectedNode.settings.field_value"
          @input="(val) => onUpdate(val, 'settings.field_value')"
          :suggestions="availableInputFields"
        ></mustache-template>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import MustacheTemplate from "@/components/mustache/mustache-template.vue";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "update-custom-field-settings",
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    fieldIds() {
      return ["1", "2", "3"];
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
  <div class="EmailSettings">
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.labels"
      @input="(val) => setLabel(val)"
      @click:append-outer="listLabels"
      item-text="name"
      item-value="id"
      multiple
      :items="labels"
      label="Labels"
      placeholder="Inbox"
      :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
      clearable
    ></v-select>

    <mustache-template
      label="Search Term"
      :value="settings.q"
      @input="(val) => onUpdate(val, 'settings.q')"
      :suggestions="availableInputFields"
      :singleLine="true"
      class="mb-4"
    />

    <v-text-field
      outlined
      dense
      placeholder="Maximun of 500. Defaults to 10"
      label="Max Results"
      hide-details="auto"
      type="number"
      :value="settings.maxResults"
      @input="(val) => onUpdate(val, 'settings.maxResults')"
      class="mb-4"
    ></v-text-field>

    <v-checkbox
      class="mb-3"
      outlined
      dense
      :input-value="selectedNode.settings.includeSpamTrash"
      @change="(val) => onUpdate(val, 'settings.includeSpamTrash')"
      label="Check Spam or Trash"
    ></v-checkbox>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: worklfowActions } = createNamespacedHelpers(
  'workflows/list'
);
const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'read-email-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      labels: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (this.settings.labels && this.settings.labelNames) {
      this.labels = this.settings.labels.map((item, index) => {
        return {
          id: this.settings.labels[index],
          name: this.settings.labelNames[index],
        };
      });
    }
  },
  methods: {
    ...worklfowActions(['googleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    setLabel(labels) {
      const labelNames = [];
      labels.forEach((item) => {
        const labelName = this.labels.find((l) => l.name === item).name;
        labelNames.push(labelName);
      });
      this.onUpdate(labels, 'settings.labels');
      this.onUpdate(labelNames, 'settings.labelNames');
    },
    async listLabels() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_email_labels',
        };
        const resp = await this.googleAction(data);
        if (resp && resp.result) {
          this.labels = resp.result;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="AwsClaudeSettings mt-1">
      <v-select
              outlined
              dense
              v-model="this.settings.model"
              :items="models"
              @change="(val) => { this.settings.model = val }"
              item-text="label"
              item-value="value"
              label="AI Model"
      ></v-select>
        <v-select
          outlined
          dense
          v-model="settings.usePrompt"
          :items="promts.map(promt => ({ label: promt.name, value: promt.id }))"
          @change="onSelectedProm"
          item-text="label"
          label="Prompt"
          clearable
          @option:clear="onPromptClear"
        ></v-select>

    <v-select
        outlined
        dense
        v-model="settings.systemPromptId"
        :items="systemPrompts.map(promt => ({ label: promt.name, value: promt.id }))"
        @change="onSystemPromptSelect"
        item-text="label"
        label="System Prompt"
        clearable
        @option:clear="onSystemPromptClear"
    ></v-select>
    <v-row
      dense
      align="center">
      <v-col
        cols="auto"
        class="mr-auto">
        <p class="text-subtitle-1 mb-0">Function Parameters</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="!settings.usePrompt"
          @click="addParam()"
          text
          color="primary"
          dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="AwsClaudeSettings__param-row mb-6"
      v-for="(param, i) in settings.promptInputs"
      :key="i">
      <v-text-field
        class="AwsClaudeSettings__param-row--field mr-2"
        outlined
        dense
        label="Name"
        :value="param.name"
        @input="(val) => onUpdate(val, `settings.promptInputs[${i}].name`)" />
      <v-select
        class="AwsClaudeSettings__param-row--field mr-2"
        outlined
        dense
        :value="param.value"
        @input="(val) => onUpdate(val, `settings.promptInputs[${i}].value`)"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Value"
        clearable></v-select>
      <v-btn
        class="AwsClaudeSettings__param-row--icon-btn"
        icon
        v-if="!settings.usePrompt"
        @click="deleteParam(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>
    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Attachments</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
            v-if="!settings.usePrompt"
            @click="addAttachment()"
            text
            color="primary"
            dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
        class="AwsClaudeSettings__param-row mb-6"
        v-for="(param, i) in settings.promptAttachments"
        :key="i+'attached'">
      <v-text-field
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          label="Name"
          :value="param.name"
          @input="(val) => onUpdate(val, `settings.promptAttachments[${i}].name`)" />
      <v-select
          class="AwsClaudeSettings__param-row--field mr-2"
          outlined
          dense
          :value="param.value"
          @input="(val) => onUpdate(val, `settings.promptAttachments[${i}].value`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Value"
          clearable></v-select>
      <v-btn
          class="AwsClaudeSettings__param-row--icon-btn"
          icon
          v-if="!settings.usePrompt"
          @click="deleteAttachment(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-row
        dense
        align="center">
      <v-col
          cols="auto"
          class="mr-auto">
        <p class="text-subtitle-1 mb-0">Tools</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
            v-if="!settings.usePrompt"
            @click="addTool()"
            text
            color="primary"
            dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
        class="AwsClaudeSettings__param-row mb-6"
        v-for="(param, i) in settings.tools"
        :key="i+'tool'">
      <v-autocomplete
          :disabled="!!settings.usePrompt"
          ref="toolSelect"
          outlined
          dense
          :value="param"
          @input="(val) => setNewTool(val, i)"
          item-text="name"
          item-value="id"
          :items="[...tools, param]"
          label="Select Tool"
          :append-outer-icon="fetchToolsLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
          @click:append-outer="listTools"
          clearable></v-autocomplete>
      <v-btn
          class="AwsClaudeSettings__param-row--icon-btn"
          icon
          v-if="!settings.usePrompt"
          @click="deleteTool(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <mustache-template
      label="Prompt Template"
      class="mr-2"
      :value="settings.promptTemplate"
      @input="(val) => onUpdate(val, `settings.promptTemplate`)"
      :suggestions="availableInputFields"
      :singleLine="false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { getModel } from '@/util/actionsModels';
import 'vue-json-viewer/style.css';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: promtsGetters, mapActions: promtsActions } =
  createNamespacedHelpers('promts');

const {  mapGetters: AIChatGetters, mapActions: AIChatActions } =
    createNamespacedHelpers('AIChat');

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

const {  mapGetters: toolsGetters, mapActions: toolsActions, mapMutations: toolsMutation } =
    createNamespacedHelpers('promptsTools');

export default {
  name: 'aws-claude-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      showDialog: false,
      showPromt: '',
      error: '',
      output: '',
      models: [
            {
                label: 'Claude V3 Sonnet',
                value: 'anthropic.claude-3-sonnet-20240229-v1:0'
            },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
            {
                label: 'Claude V3 Haiku',
                value: 'anthropic.claude-3-haiku-20240307-v1:0'
            },
            {
                label: 'Claude V2',
                value: 'anthropic.claude-v2:1'
            }
      ],
      selectedModel: {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
      },
      placeholder: `
      Example:
      function myFunction(data) {
        return 'Hello ' + data.patientName
      }
      myFunction(data)`,
    };
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchPromts();
    },
  },
  computed: {
    ...promtsGetters({
      promts: 'PROMTS',
      fetchWPromtsLoading: 'FETCH_PROMTS_LOADING',
      deletePromtLoading: 'DELETE_PROMT_LOADING',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    ...toolsActions({
      fetchTools: 'fetchTools'
    }),
    ...toolsGetters({
      tools: 'TOOLS',
      fetchToolsLoading: 'FETCH_TOOLS_LOADING',
    }),
    ...toolsMutation({
      setTools: 'SET_TOOLS'
    }),
    ...AIChatGetters({
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  mounted() {
    this.selectedModel
    this.listSystemPrompts();
    this.fetchPromts();
  },
    methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    ...promtsActions(['fetchPromts']),
    ...AIChatActions(['getSystemPrompts']),
    onUpdatePromptTemplate(val) {
      this.settings.promptTemplate = val;
    },
      async listTools() {
        await this.fetchTools()
      },
      async listSystemPrompts() {
        await this.getSystemPrompts()
      },
      setNewTool(val, i) {
        const exists = this.settings.tools.find((tool) => tool?.id === val)
        if(exists || !val) {
          return;
        }
        const tool = this.tools.find((tool) => tool?.id === val);
        let settings = cloneDeep(this.settings);
        settings.tools[i] = tool;
        this.onUpdate(settings, 'settings');
      },
      deleteTool(i) {
        const settings = cloneDeep(this.settings);
        settings.tools.splice(i, 1);
        if (!settings.tools.length) delete settings.tools;

        this.onUpdate(settings, 'settings');
      },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.promptInputs) {
        settings.promptInputs = [];
      }

      settings.promptInputs.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
      addAttachment() {
        let settings = cloneDeep(this.settings);
        if (!settings.params) {
          settings.params = [];
        }

        if(!this.settings.promptAttachments) {
          this.settings.promptAttachments = []
        }
        settings.promptAttachments.push({
          name: '',
          value: '',
        });
        this.onUpdate(settings, 'settings');
      },
      addTool() {
        let settings = cloneDeep(this.settings);
        if (!settings.tools) {
          settings.tools = [];
        }

        if(!this.settings.tools) {
          this.settings.tools = []
        }
        settings.tools.push({
          name: '',
          id: '',
        });
        this.onUpdate(settings, 'settings');
      },
    onSelectedProm(id){
      this.onUpdate(id, `settings.usePrompt`);
      const prompt = this.promts.find((pr) => pr.id === id)
      this.onUpdate(prompt.text, `settings.promptTemplate`);
      this.onUpdate(prompt.inputs || [], `settings.promptInputs`);
      this.onUpdate(prompt.attachments || [], `settings.promptAttachments`);
      this.onUpdate(prompt.tools || [], `settings.tools`);
      this.onUpdate(prompt.model, `settings.model`);
      this.onUpdate(prompt.SystemPrompt.id, `settings.systemPromptId`);
      this.onUpdate(prompt.SystemPrompt.prompt, `settings.systemPrompt`);
      this.selectedModel = this.models.find((model) => model.value === prompt.model)
    },
    onPromptClear() {
      this.onUpdate(null, `settings.usePrompt`);
    },
    onSystemPromptSelect(id) {
      const systemPrompt = this.systemPrompts.find((pro) => pro.id === id);
      this.onUpdate(systemPrompt.prompt, `settings.systemPrompt`);
      this.onUpdate(systemPrompt.id, `settings.systemPromptId`);
    },
      onSystemPromptClear() {
        this.onUpdate(null, `settings.systemPrompt`);
        this.onUpdate(null, `settings.systemPromptId`);
      },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.promptInputs.splice(index, 1);
      if (!settings.promptInputs.length) delete settings.promptInputs;

      this.onUpdate(settings, 'settings');
    },
      deleteAttachment(index) {
        const settings = cloneDeep(this.settings);
        settings.promptAttachments.splice(index, 1);
        if (!settings.promptAttachments.length) delete settings.promptAttachments;

        this.onUpdate(settings, 'settings');
      },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = {};
        if (this.settings.params) {
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        }

        const options = {
          data: {
            script: this.settings.script,
            params: JSON.stringify(params),
            test_mode: true,
          },
          action: this.selectedNode.action_type || 'execute_javascript',
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.output = data.result || 'no output';
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './aws-claude';
</style>

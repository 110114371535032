<template>
  <div class="NodeResolver d-flex flex-column align-center">
    <div class="arrow-down">
      <div class="arrow-down__line"></div>
    </div>

    <div
      @dragstart.stop="dragStart"
      @dragend.stop="dragEnd"
      draggable="true">
      <conditional-node
        :node="node"
        :index="index"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        v-if="isConditionalNode" />

      <nested-nodes
        :node="node"
        :index="index"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        v-else-if="isNestedNode" />

      <loop-node
        :node="node"
        :index="index"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        v-else-if="isLoopNode" />

      <try-catch-node
        :node="node"
        :index="index"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        v-else-if="isTryCatchNode" />

      <general-node
        :node="node"
        :index="index"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        v-else />
    </div>
    <div class="arrow-down">
      <div class="arrow-down__line"></div>
    </div>
    <div class="plus-block">
      <action-list-menu
        :insertIndex="index + 1"
        :isNestedActions="isNestedActions"
        :parent-node="parentNode"
        :parent-node-list="parentNodeList"
        :branch="branch" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { findNode, allowedNestedActionTypes } from '@/util/action-types';
import GeneralNode from '@/components/node-types/general-node/general-node.vue';
import ConditionalNode from '@/components/node-types/conditional-node/conditional-node.vue';
import LoopNode from '@/components/node-types/loop-node/loop-node.vue';
import TryCatchNode from '@/components/node-types/try-catch-node/try-catch.vue';
import NestedNodes from '@/components/node-types/nested-nodes/nested-nodes.vue';
import ActionListMenu from '@/components/action-list/action-list.vue';

const { mapMutations: workflowDetailsMutations } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'NodeResolver',
  props: {
    node: {
      type: Object,
    },
    index: {
      type: Number,
    },
    parentNode: {
      type: Object,
    },
    parentNodeList: {
      type: Array,
      default: () => [],
    },
    branch: {
      required: false,
      default: null,
    },
    isNestedActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GeneralNode,
    ConditionalNode,
    LoopNode,
    ActionListMenu,
    NestedNodes,
    TryCatchNode,
  },
  computed: {
    isConditionalNode() {
      return this.node.action_type === 'conditional';
    },
    isLoopNode() {
      return this.node.action_type === 'loop';
    },
    isTryCatchNode() {
      return this.node.action_type === 'try_catch';
    },
    isNestedNode() {
      return allowedNestedActionTypes.includes(this.node.action_type);
    },
    movingNode() {
      return findNode(this.nodes, this.$store.getters.MOVING_NODE_ID);
    },
  },
  methods: {
    ...workflowDetailsMutations({
      setSelectedNodeOption: 'SET_SELECTED_NODE_OPTIONS',
    }),
    dragStart() {
      const options = {
        index: this.index,
        parentNode: this.parentNode,
        parentNodeList: this.parentNodeList,
      };

      this.setSelectedNodeOption(options);

      // TODO: change this later
      this.$store.commit('workflows/details/SET_MOVING_NODE_ID', this.node.id);
    },
    dragEnd() {
      // TODO: clear selected node if not dropped o drop zone
    },
  },
};
</script>

<style scoped lang="scss">
@import './node-resolver.scss';
</style>

<template>
  <div>
    <div>
      <v-switch
        label="Use Share URL"
        class="mt-2"
        :input-value="settings.useShareUrl"
        @change="toggleUseShareUrl"
      ></v-switch>
      <div v-if="settings.useShareUrl">
        <mustache-template
          label="Source File Share URL"
          class="mb-3"
          :value="settings.srcShareUrl"
          @input="(val) => onUpdate(val, `settings.srcShareUrl`)"
          :suggestions="availableInputFields"
          :singleLine="true"
        />
        <mustache-template
          label="Destination Folder Share URL"
          class="mb-3"
          :value="settings.destShareUrl"
          @input="(val) => onUpdate(val, `settings.destShareUrl`)"
          :suggestions="availableInputFields"
          :singleLine="true"
        />
      </div>
      <div v-else>
        <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.sourceFileLocation"
          @input="(val) => onUpdate(val, 'settings.sourceFileLocation')"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Source File Location"
          clearable
        ></v-autocomplete>
        <v-autocomplete
          class="mb-3"
          outlined
          dense
          :value="selectedNode.settings.destFolderLocation"
          @input="(val) => setDestFolderLocation(val)"
          item-text="name"
          item-value="id"
          @click:append-outer="listFolders"
          :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
          :items="driveFolders"
          label="Destination Folder Location"
          clearable
        ></v-autocomplete>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: worklfowActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'file-move',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      driveFolders: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    action() {
      return this.selectedNode.action_type;
    },
    suite() {
      return 'outlook';
    },
  },
  created() {
    const dynamicFolders = this.availableInputFields.map((inputField) => {
      return {
        name: inputField.name,
      };
    });
    this.driveFolders = [...dynamicFolders];
    if (
      !this.settings.destFolderLocation ||
      !this.settings.destFolderLocationName
    ) {
      return;
    }
    this.driveFolders.push({
      id: this.settings.destFolderLocation,
      name: this.settings.destFolderLocationName,
    });
  },

  methods: {
    ...worklfowActions(['outlookAction']),
    setDestFolderLocation(location) {
      this.onUpdate(location, 'settings.destFolderLocation');
      const folder = this.driveFolders.find((item) => item.id === location);
      if (folder?.name) {
        this.onUpdate(folder.name, 'settings.destFolderLocationName');
      } else {
        this.onUpdate(null, 'settings.destFolderLocationName');
      }

      if (folder?.driveId) {
        this.onUpdate(folder.driveId, 'settings.driveId');
      } else {
        this.onUpdate(null, 'settings.driveId');
      }
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    suiteAction(data) {
      if (this.suite === 'outlook') return this.outlookAction(data);
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedNode.input);
      const selectedOption = this.availableInputFields.find(
        (f) => f.uid === uid
      );
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;

      this.onUpdate(input, 'input');
    },
    async listFolders() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
        const dynamicFolders = this.availableInputFields.map((inputField) => {
          return {
            name: inputField.name,
          };
        });
        this.driveFolders.push(...dynamicFolders);
      } finally {
        this.loading = false;
      }
    },
    toggleUseShareUrl() {
      const settings = cloneDeep(this.settings);
      settings.useShareUrl = !settings.useShareUrl;
      if (!settings.useShareUrl) {
        settings.location = null;
      }
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style lang="scss" scoped></style>

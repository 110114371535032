<template>
  <div class="PlaywrightSettings">
    <v-switch
      label="Use Playwright Worker"
      class="mt-2"
      :input-value="settings.useWorker"
      @change="toggleUseWorker"
    ></v-switch>

    <!-- Worker Settings - Start -->
    <div class="param-row mt-2 mb-5 flex-column" v-if="settings.useWorker">
      <v-select
        class="d-flex"
        outlined
        dense
        label="Select Worker"
        item-text="name"
        item-value="id"
        :value="settings.workerId"
        @input="(val) => onUpdate(val, 'settings.workerId')"
        :items="workers"
        @change="updateWorker"
        :loading="fetchWorkerLoading"
        clearable
      ></v-select>

      <div v-if="settings.workerId && settings.inputs">
        <h4 class="pt-4 pb-2">Worker Inputs</h4>
        <div v-for="(input, index) in settings.inputs" :key="index">
          <v-select
            class="d-flex"
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="input.name"
            @input="(val) => onUpdate(val, `settings.inputs[${index}].value`)"
            :items="availableInputFields"
            clearable
          ></v-select>
        </div>
      </div>
    </div>
    <!-- Worker Settings - End -->
    <div v-else class="mb-3">
      <v-dialog v-model="showDialog" width="700">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="my-2" v-on="on">
            Open JavaScript code editor
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="PlaywrightSettings__modal-title">
            JavaScript code editor
          </v-card-title>
          <v-card-text class="PlaywrightSettings__modal-content">
            <CodeEditor
              v-if="showDialog"
              :value="settings.automation"
              @input="(val) => onUpdate(val, 'settings.automation')"
              language="javascript"
              class="mb-3"
              :runScript="testScript"
              :placeholder="placeholder"
              :suggestions="availableInputFields"
            />
            <p v-if="error" class="error--text">
              {{ error }}
            </p>
            <div v-if="output">
              <div v-if="typeof output === 'object' || Array.isArray(output)">
                <div class="output-title">Output:</div>
                <div class="output-tree">
                  <v-treeview dense open-on-click :items="output">
                    <template v-slot:label="{ item }">
                      <div v-if="item.image">
                        <button @click="openImageModal(item.image)">
                          {{ item.name }}
                        </button>
                      </div>
                      <div v-else>
                        <div>{{ item.name }}</div>
                      </div>
                    </template>
                  </v-treeview>
                </div>
              </div>
              <div v-else>Output: {{ output }}</div>
            </div>
          </v-card-text>

          <v-divider />

          <v-card-actions class="justify-end py-3 px-4">
            <v-btn color="primary" text @click="showDialog = false">Done</v-btn>
            <v-btn
              color="primary"
              :loading="testingScript"
              @click="testScript()"
            >
              Test Script
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div>
      <v-switch
        label="Input is nested simple type (string, number), e.g. Object.x.y"
        class="mb-3"
        v-model="isObjectFieldSimpleType"
      ></v-switch>

      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.inputDataPath"
        @input="(val) => onUpdate(val, 'settings.inputDataPath')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Input"
        clearable
      ></v-select>

      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.authStatePath"
        @input="(val) => onUpdate(val, 'settings.authStatePath')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Auth json"
        clearable
      ></v-select>

      <v-switch
        label="Recording"
        class="mb-3"
        v-model="isRecordingOn"
      ></v-switch>

      <v-text-field
        outlined
        dense
        label="Width"
        class="mb-3"
        hide-details="auto"
        v-model="widthSize"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Height"
        class="mb-3"
        hide-details="auto"
        v-model="heightSize"
      ></v-text-field>

      <images-popup
        :dialog="imageDialog"
        :toggleDialog="openImageModal"
        :images="images"
      ></images-popup>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import CodeEditor from '@/components/code-editor/code-editor.vue';
import { getModel } from '@/util/actionsModels';
import imagesPopup from '@/components/modals/image-modal/image-modal.vue';

const { mapGetters: workersGetters, mapActions: workersActions } =
  createNamespacedHelpers('workers');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'playwright-settings',
  components: {
    imagesPopup,
    CodeEditor,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      showDialog: false,
      testingScript: false,
      error: '',
      output: '',
      images: [],
      imageDialog: false,
      placeholder: `
      Example:
      module.exports = async function(page) {
         await page.goto('https://internet.yandex.ru');
         const screenshot = await page.screenshot();
         return Buffer.from(screenshot).toString('base64');
        }
      `,
    };
  },
  created() {
    if (this.selectedNode.settings.workerId) {
      this.updateWorker(false);
    }
  },
  mounted() {
    this.fetchWorkers({ type: 'playwright' });

    const settings = cloneDeep(this.settings);
    if (settings.useWorker === null || settings.useWorker === undefined) {
      settings.useWorker = false;
      this.onUpdate(settings, 'settings');
    }
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    ...workersGetters({
      workers: 'WORKERS',
      worker: 'WORKER',
      fetchWorkerLoading: 'FETCH_WORKER_LOADING',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    actionType() {
      return this.selectedNode.action_type;
    },
    isRecordingOn: {
      get() {
        return Boolean(this.selectedNode.settings.isRecordingOn);
      },
      set(value) {
        this.onUpdate(value, 'settings.isRecordingOn');
      },
    },
    isObjectFieldSimpleType: {
      get() {
        return Boolean(this.selectedNode.settings.isObjectFieldSimpleType);
      },
      set(value) {
        this.onUpdate(value, 'settings.isObjectFieldSimpleType');
      },
    },
    widthSize: {
      get() {
        return this.selectedNode.settings.widthSize || 1280;
      },
      set(value) {
        this.onUpdate(value, 'settings.widthSize');
      },
    },
    heightSize: {
      get() {
        return this.selectedNode.settings.heightSize || 720;
      },
      set(value) {
        this.onUpdate(value, 'settings.heightSize');
      },
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    ...workersActions(['fetchWorker', 'clearWorker', 'fetchWorkers']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addParam() {
      const settings = cloneDeep(this.selectedNode.settings);
      if (!settings.params) settings.params = [];

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;
      this.onUpdate(settings, 'settings');
    },
    toggleUseWorker() {
      const settings = cloneDeep(this.settings);
      settings.useWorker = !settings.useWorker;
      if (!settings.useWorker) {
        settings.workerId = null;
      }
      this.onUpdate(settings, 'settings');
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = {};
        if (this.settings.params) {
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        }
        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: this.actionType,
        };
        const data = await this.executeSingleAction(options);
        if (data && data.success) {
          const output = data.data || 'no output';
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
          if (output.File && output.File.file) {
            let file = output.File.file;
            if (!file.startsWith('data:')) {
              file = `data:${output.File.mime_type};base64,${file}`;
            }
            output.File.file = file;
          }
          const getOutputTree = (output) => {
            if (output != null) {
              if (typeof output === 'object') {
                return Object.keys(output).map((key) => {
                  if (
                    // eslint-disable-next-line
                    output.hasOwnProperty(key) &&
                    output[key] != null &&
                    (typeof output[key] === 'object' ||
                      Array.isArray(output[key]))
                  ) {
                    return {
                      name: key,
                      children: getOutputTree(output[key]),
                    };
                  } else {
                    if (
                      typeof output[key] === 'string' &&
                      output[key].includes(';base64')
                    ) {
                      return {
                        image: output[key],
                        name: key + ': ' + output[key].substring(0, 30) + '...',
                      };
                    }
                    return {
                      name: key + ': ' + output[key],
                    };
                  }
                });
              } else if (Array.isArray(output)) {
                return output.map((el) => getOutputTree(el));
              }
            } else {
              return 'no output';
            }
          };
          if (typeof output === 'object' || Array.isArray(output)) {
            this.output = getOutputTree(output);
          } else {
            this.output = output;
          }
        } else {
          throw Error(data && data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    openImageModal(image) {
      this.images = image ? [image] : [];
      this.imageDialog = !this.imageDialog;
    },
    async updateWorker(overwrite = true) {
      const settings = cloneDeep(this.settings);

      if (settings.workerId) {
        await this.fetchWorker(settings.workerId);
      }

      if (overwrite) {
        const output_type = cloneDeep(this.worker.model);
        settings.authenticationId = this.worker.settings.authenticationId;
        settings.inputs = cloneDeep(this.worker.inputs);
        this.onUpdate(output_type, 'output_type');
        this.onUpdate(settings, 'settings');
      }
    },
  },
  beforeDestroy() {
    this.clearWorker();
  },
};
</script>

<style scoped lang="scss">
@import './default';
</style>

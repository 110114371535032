<template>
  <div class="TryCatchSettings">
    <h3 class="mt-3">Settings</h3>

    <v-text-field
      outlined
      dense
      label="Retry attempts"
      hide-details="auto"
      type="number"
      class="mb-3"
      :value="selectedNode.settings.retryAttempts || 3"
      @input="(val) => onUpdate(val, 'settings.retryAttempts')"
    ></v-text-field>

    <v-text-field
      outlined
      dense
      label="Retry Delay (in seconds)"
      hide-details="auto"
      type="number"
      class="mb-3"
      :value="selectedNode.settings.retryDelaySeconds || 3"
      @input="(val) => onUpdate(val, 'settings.retryDelaySeconds')"
    ></v-text-field>

    <v-checkbox
            outlined
            dense
            label="Fail workflow at maximum number of attempts"
            hide-details="auto"
            class="mb-3"
            v-model="selectedNode.settings.failWorkflow"
    ></v-checkbox>

    <div v-for="(filter, index) in filterMessages" :key="index">
      <v-row justify="space-between" align="center" no-gutters>
        <div class="filter-container">
          <v-text-field
            outlined
            dense
            label="Regex Filter message"
            hide-details="auto"
            type="string"
            class="mb-3"
            :value="filter.message"
            @input="(val) => onUpdateFilterMessage(val, index)"
          ></v-text-field>

          <v-select
            outlined
            dense
            label="Regex Flags"
            hide-details="auto"
            :items="[
              { text: 'Global search', value: 'g' },
              { text: 'Case insensitive', value: 'i' },
              { text: 'Multiline', value: 'm' },
              { text: 'Unicode', value: 'u' },
              { text: 'Sticky', value: 'y' },
              { text: 'Dotall', value: 's' },
            ]"
            multiple
            @change="onUpdateFilterFlags(filter.flags, index)"
            v-model="filter.flags"
          ></v-select>

          <v-btn text class="mt-2" color="red" @click="onDeleteFilter(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>

    <!-- New filter control -->
    <div v-if="showNewFilterControl" class="filter-container">
      <v-text-field
        outlined
        dense
        label="Regex Filter message"
        hide-details="auto"
        type="string"
        class="mb-3"
        v-model="newFilterMessage"
      ></v-text-field>
      <v-select
        outlined
        dense
        label="Regex Flags"
        hide-details="auto"
        :items="[
          { text: 'Global search', value: 'g' },
          { text: 'Case insensitive', value: 'i' },
          { text: 'Multiline', value: 'm' },
          { text: 'Unicode', value: 'u' },
          { text: 'Sticky', value: 'y' },
          { text: 'Dotall', value: 's' },
        ]"
        multiple
        v-model="newFilterFlags"
      ></v-select>
      <v-btn color="primary" dense class="mt-2" @click="onAddNewFilter"
        >Create
      </v-btn>
    </div>

    <v-btn v-else depressed @click="onAddFilter">Add filter </v-btn>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'TryCatchSetting',
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
  },
  data() {
    return {
      showNewFilterControl: false,
      newFilterMessage: '',
      newFilterFlags: [],
      filterMessages: [],
      failWorkflow: true,
    };
  },
  mounted() {
    // Initialize filterMessages on mount
    this.initFilterMessages();
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    onUpdateFilterMessage(value, index) {
      const updatedFilterMessages = this.filterMessages.map((filter, i) => {
        if (i === index) {
          return { message: value, flags: filter.flags };
        }
        return filter;
      });

      this.filterMessages = updatedFilterMessages;
      this.onFilterMessagesChange();
    },

    onUpdateFilterFlags(flags, index) {
      const updatedFilterMessages = this.filterMessages.map((filter, i) => {
        if (i === index) {
          return { message: filter.message, flags: flags };
        }
        return filter;
      });

      this.filterMessages = updatedFilterMessages;
      this.onFilterMessagesChange();
    },
    onDeleteFilter(index) {
      this.filterMessages = this.filterMessages.filter(
        (filter, i) => i !== index
      );
      this.onFilterMessagesChange();
    },
    onAddFilter() {
      this.showNewFilterControl = true;
    },
    onAddNewFilter() {
      this.filterMessages = [
        ...this.filterMessages,
        { message: this.newFilterMessage, flags: this.newFilterFlags },
      ];
      this.onFilterMessagesChange();
      this.showNewFilterControl = false;
      this.newFilterMessage = '';
      this.newFilterFlags = [];
    },

    initFilterMessages() {
      const filterMessages = this.selectedNode.settings.filterMessages;
      if (filterMessages && filterMessages.length) {
        this.filterMessages = filterMessages;
      } else {
        this.filterMessages = [];
      }
    },

    onFilterMessagesChange() {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.filterMessages = [];
      this.filterMessages.forEach((filter) => {
        settings.filterMessages.push(filter);
      });
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss">
.filter-container {
  border: 1px solid #ccc; /* Add your desired border style and color */
  padding: 10px; /* Add padding as needed */
  width: 100%;
  margin-bottom: 5px;
}
</style>

<template>
  <div class="EmailSettings">
    <mustache-template
      class="mb-3"
      label="Email of Sender"
      :suggestions="availableInputFields"
      :singleLine="true"
      :value="settings.fromEmail"
      @input="(val) => onUpdate(val, 'settings.fromEmail')"
    />

    <mustache-template
      class="mb-3"
      label="Name of Sender"
      :suggestions="availableInputFields"
      :singleLine="true"
      :value="settings.fromName"
      @input="(val) => onUpdate(val, 'settings.fromName')"
    />

    <mustache-template
      class="mb-3"
      label="Add To Emails"
      placeholder="Array of Email addresses(Comma separated email addresses)"
      :suggestions="availableInputFields"
      :singleLine="true"
      :value="settings.toEmails"
      @input="(val) => onUpdate(val, 'settings.toEmails')"
    />

    <div class="mt-3">
      <mustache-template
        label="Enter subject"
        :value="settings.subject"
        @input="(val) => onUpdate(val, 'settings.subject')"
        :suggestions="availableInputFields"
        :singleLine="true"
        class="mb-3"
      />

      <v-select
        outlined
        dense
        :value="settings.attachments"
        @input="(val) => onUpdate(val, 'settings.attachments')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Attachment"
        class="mb-3"
      />

      <mustache-template
        label="Body"
        :value="settings.template"
        @input="(val) => onUpdate(val, 'settings.template')"
        :suggestions="availableInputFields"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'TwilioEmailSetting',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped lang="scss"></style>

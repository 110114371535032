<template>
  <div>
    <h4 class="mt-5">Patient Settings</h4>
    <mustache-template
      label="Patient Id"
      class="mt-2"
      :value="settings.patient.patient_id"
      @input="(val) => onUpdate(val, 'settings.patient.patient_id')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      label="First Name"
      class="mt-2"
      :value="settings.patient.firstname"
      @input="(val) => onUpdate(val, 'settings.patient.firstname')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      label="Last Name"
      class="mt-2"
      :value="settings.patient.lastname"
      @input="(val) => onUpdate(val, 'settings.patient.lastname')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      label="Date Of Birth"
      class="mt-2"
      :value="settings.patient.date_of_birth"
      @input="(val) => onUpdate(val, 'settings.patient.date_of_birth')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      label="Email"
      class="mt-2"
      :value="settings.patient.email"
      @input="(val) => onUpdate(val, 'settings.patient.email')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      label="Primary Phone"
      class="mt-2"
      :value="settings.patient.primary_phone"
      @input="(val) => onUpdate(val, 'settings.patient.primary_phone')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'patient-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (!this.settings.patient) {
      this.settings.patient = {};
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped></style>

<template>
  <section>
    <div>
      <h3 class="pb-4">Input</h3>
      <v-btn
        v-if="
          !selectedNode.settings.types ||
          selectedNode.settings.types.length !== appOptions.length
        "
        text
        @click="selectAll()"
      >
        Select all
      </v-btn>
      <v-btn
        v-if="
          selectedNode.settings.types && selectedNode.settings.types.length > 0
        "
        text
        @click="selectedNode.settings.types = []"
      >
        Clear all
      </v-btn>
      <multiselect
        v-if="appOptions"
        :value="selectedNode.settings.types"
        @input="(val) => onUpdate(val, 'settings.types')"
        tag-placeholder="Add this as new tag"
        placeholder="Choose appointment types to include"
        label="appointment_type_name"
        track-by="id"
        :options="appOptions"
        :multiple="true"
        :taggable="true"
      ></multiselect>
      <br />
      <p class="text--secondary subtitle-2">
        Choose when to look for the appointments
      </p>
      <v-row no-gutters class="flex-nowrap">
        <v-text-field
          outlined
          dense
          label="Period"
          hide-details="auto"
          type="number"
          :value="selectedNode.settings.period"
          @input="(val) => onUpdate(val, 'settings.period')"
        ></v-text-field>
        <p class="text--secondary subtitle-2 mt-2 mx-4">Days</p>
        <v-select
          outlined
          dense
          :value="selectedNode.settings.sign"
          @input="(val) => onUpdate(val, 'settings.sign')"
          item-text="name"
          item-value="value"
          :items="[
            { name: 'In past', value: '-' },
            { name: 'In future', value: '+' },
          ]"
          label="Before/After"
          clearable
        ></v-select>
      </v-row>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "scheduled-settings",
  components: {
    Multiselect,
  },
  props: {
    appOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    selectAll() {
      this.onUpdate(this.appOptions, "settings.types");
    }
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="ObjectHelperSettings">
    <div
      v-for="(input, inputIndex) in settings.inputs"
      :key="inputIndex"
      class="5">
      <p class="text-h6 mb-3">Input #{{ inputIndex + 1 }}</p>

      <v-row
        class="mb-1"
        dense>
        <v-col cols="11">
          <v-select
            outlined
            dense
            :value="input.inputNodeId"
            @input="
              (val) =>
                onUpdate(val, `settings.inputs[${inputIndex}].inputNodeId`)
            "
            @change="changeInput(inputIndex)"
            item-text="name"
            item-value="id"
            :items="availableInputs.filter((i) => i.action_type)"
            label="Connector" />
        </v-col>

        <v-col cols="1">
          <v-btn
            icon
            @click="deleteInput(inputIndex)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <div class="mb-3">
        <v-row
          dense
          align="center">
          <v-col
            cols="auto"
            class="mr-auto">
            <p class="text-subtitle-1 mb-0">Function Parameters</p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="showAddParamBtn(inputIndex)"
              @click="addParam(inputIndex)"
              dense
              text
              color="primary">
              + Add
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-for="(param, paramIndex) in input.params"
          :key="paramIndex"
          dense>
          <v-col cols="5">
            <v-text-field
              @change="updateModel"
              outlined
              dense
              label="Name"
              :value="param.name"
              @input="
                (val) =>
                  onUpdate(
                    val,
                    `settings.inputs[${inputIndex}].params[${paramIndex}].name`
                  )
              " />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              outlined
              dense
              :value="param.value"
              @input="
                (val) =>
                  onUpdate(
                    val,
                    `settings.inputs[${inputIndex}].params[${paramIndex}].value`
                  )
              "
              menu-props="auto"
              item-text="name"
              item-value="name"
              :items="availableInputFields(inputIndex)"
              @change="updateModel"
              label="Value"
              clearable
              append-icon="" />
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              @click="deleteParam(inputIndex, paramIndex)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-btn
      class="mb-3"
      v-if="showAddInputBtn"
      @click="addInput()"
      text
      color="primary">
      + Add Input
    </v-btn>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { isObject } from 'lodash';
import { createNamespacedHelpers } from 'vuex';

import { shTypes, cloneModel, genUid } from '@/util/actionsModels';
import { findNode, getInputModel } from '@/util/action-types';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'object-helper-settings',
  props: {
    availableInputs: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      nodes: 'NODES',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddInputBtn() {
      const inputs = this.settings.inputs;
      return !inputs || !inputs[0] || inputs[inputs.length - 1].inputNodeId;
    },
  },
  mounted() {
    if (!this.settings.inputs) {
      this.addInput();
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    findNode(id) {
      return findNode(this.availableInputs, id);
    },
    showAddParamBtn(inputIndex) {
      const input = this.settings.inputs[inputIndex];
      const params = input ? input.params : null;
      if (!input || !input.inputNodeId) return false;

      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
    addInput() {
      const settings = cloneDeep(this.settings);

      if (!settings.inputs) {
        settings.inputs = [];
      }

      settings.inputs.push({
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
        params: [],
      });

      this.onUpdate(settings, 'settings');
    },
    deleteInput(inputIndex) {
      const settings = cloneDeep(this.settings);
      settings.inputs.splice(inputIndex, 1);
      this.onUpdate(settings, 'settings');
      this.updateModel();
    },
    addParam(inputIndex) {
      const settings = cloneDeep(this.settings);
      settings.inputs[inputIndex].params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(inputIndex, paramIndex) {
      const settings = cloneDeep(this.settings);
      settings.inputs[inputIndex].params.splice(paramIndex, 1);
      this.onUpdate(settings, 'settings');
      this.updateModel();
    },
    updateModel() {
      // set default output model
      if (!this.selectedNode.output_type) {
        const output_type = {
          name: 'Object',
          type: 'object',
          uid: genUid(),
          value: [],
        };
        this.onUpdate(output_type, 'output_type');
      }
      let keysList = [];

      this.settings.inputs.forEach((input, index) => {
        //  clone output models from source models with new names and uids
        for (let param in input.params) {
          const output_type = cloneDeep(this.selectedNode.output_type);
          const newName = input.params[param]['name'];
          const sourcePath = input.params[param]['value'];
          if (!sourcePath) continue;

          const nodeOutputs = this.availableInputFields(index);
          const sourceModel = nodeOutputs.find(
            (field) => field.name === sourcePath
          );

          const existedParamIdx = this.selectedNode.output_type.value.findIndex(
            (v) => v.name == newName
          );
          if (existedParamIdx !== -1) {
            output_type.value[existedParamIdx] = cloneModel(
              newName,
              sourceModel.field
            );
          } else {
            output_type.value.push(cloneModel(newName, sourceModel.field));
          }

          this.onUpdate(output_type, 'output_type');
        }

        keysList = [...keysList, ...input.params.map((p) => p.name)];
      });

      // remove deleted fields from output model
      const filteredModelValues = this.selectedNode.output_type.value.filter(
        (outputParam) => keysList.find((key) => key == outputParam.name)
      );

      const output_type = cloneDeep(this.selectedNode.output_type);
      output_type.value = filteredModelValues;
      this.onUpdate(output_type, 'output_type');
    },
    inputAction(inputNode) {
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    getInputModel(index) {
      const inputNodeId = this.settings.inputs[index].inputNodeId;
      const inputNode = this.findNode(inputNodeId);

      const inputAction = this.inputAction(inputNode);
      const options = {
        inputNodeId,
        inputNode,
        inputAction,
        nodes: this.nodes,
        nodeId: this.selectedNode.id,
      };
      

      return getInputModel(options);
    },
    availableInputFields(index) {
      let result = [];

      const inputModel = this.getInputModel(index);
      if (inputModel) {
        if (Array.isArray(inputModel)) {
          const items = inputModel
            .filter((item) => item.type === shTypes.ARRAY)
            .map((i) => ({ name: i.name, uid: i.uid, field: i }));
          result.push(...items);
        } else if (
          typeof inputModel === 'object' &&
          inputModel.type === shTypes.ARRAY
        ) {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
        const flattenObjFields = (items, prefix) => {
          if (isObject(items)) {
            items.forEach((prop) => {
              const propWithPrefix = {
                name: !prefix ? prop.name : `${prefix}.${prop.name}`,
                uid: prop.uid,
                field: prop,
              };

              if (prop.type === shTypes.OBJECT) {
                result.push(propWithPrefix);
                flattenObjFields(prop.value, propWithPrefix.name);
              } else {
                result.push(propWithPrefix);
              }
            });
          }
        };

        if (inputModel.type === shTypes.OBJECT) {
          flattenObjFields(inputModel.value, inputModel.name);
        } else {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
      }

      return result;
    },
    changeInput(index) {
      const settings = cloneDeep(this.settings);
      const inputModel = this.getInputModel(index);
      const inputFields = this.availableInputFields(index);

      if (inputFields && inputFields.length) {
        settings.inputs[index].inputFieldPath = inputModel.name;
        settings.inputs[index].inputFieldName = inputModel.name;
        settings.inputs[index].inputFieldId = inputModel.uid;
      }
      this.onUpdate(settings, 'settings');
      this.updateModel();
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <v-select
      class="my-3"
      outlined
      dense
      :value="selectedNode.settings.device_id"
      @input="(val) => onUpdate(val, 'settings.device_id')"
      item-text="name"
      item-value="uid"
      :items="devices"
      label="Set target device"
      clearable
    ></v-select>

    <v-switch
      label="Use Python Worker"
      class="mt-2"
      :input-value="settings.useWorker"
      @change="toggleUseWorker"
    ></v-switch>

    <div class="param-row mt-2 mb-5 flex-column" v-if="settings.useWorker">
      <v-select
        class="d-flex"
        outlined
        dense
        label="Select Worker"
        item-text="name"
        item-value="id"
        :value="settings.workerId"
        @input="(val) => onUpdate(val, 'settings.workerId')"
        :items="workers"
        @change="updateWorker"
        :loading="fetchWorkerLoading"
        clearable
      ></v-select>
    </div>
    <v-text-field
      v-else
      label="Python Module(e.g. module_name.class_name)"
      outlined
      dense
      :hide-details="true"
      :value="selectedNode.settings.module"
      @change="(val) => onUpdate(val, 'settings.module')"
    />

    <div class="PythonSettings mt-1">
      <v-row dense align="center">
        <v-col cols="auto" class="mr-auto">
          <p class="text-subtitle-1 mb-0">Function Parameters</p>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="showAddParamBtn"
            @click="addParam()"
            text
            color="primary"
            dense
          >
            + Add
          </v-btn>
        </v-col>
      </v-row>
      <div
        class="PythonSettings__param-row mb-6"
        v-for="(param, i) in settings.params"
        :key="i"
      >
        <v-text-field
          class="PythonSettings__param-row--field mr-2"
          outlined
          dense
          label="Name"
          :value="param.name"
          @input="(val) => onUpdate(val, `settings.params[${i}].name`)"
        />
        <v-select
          class="PythonSettings__param-row--field mr-2"
          outlined
          dense
          :value="param.value"
          @input="(val) => onUpdate(val, `settings.params[${i}].value`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          label="Value"
          clearable
        ></v-select>
        <v-btn
          class="PythonSettings__param-row--icon-btn"
          icon
          @click="deleteParam(i)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: devicesGetters } = createNamespacedHelpers('devices');
const { mapGetters: workersGetters, mapActions: workersActions } =
  createNamespacedHelpers('workers');

export default {
  name: 'python-settings',
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    ...devicesGetters({
      devices: 'DEVICES',
    }),
    ...workersGetters({
      workers: 'WORKERS',
      worker: 'WORKER',
      fetchWorkerLoading: 'FETCH_WORKER_LOADING',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  created() {
    if (this.selectedNode.settings.workerId) {
      this.updateWorker(false);
    }
  },
  mounted() {
    this.fetchWorkers({ type: 'python' });
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    ...workersActions(['fetchWorker', 'clearWorker', 'fetchWorkers']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.params) {
        settings.params = [];
      }

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;

      this.onUpdate(settings, 'settings');
    },
    toggleUseWorker() {
      const settings = cloneDeep(this.settings);
      settings.useWorker = !settings.useWorker;
      if (!settings.useWorker) {
        settings.workerId = null;
      }
      this.onUpdate(settings, 'settings');
    },
    async updateWorker() {
      const settings = cloneDeep(this.settings);

      if (settings.workerId) {
        await this.fetchWorker(settings.workerId);
      }
    },
  },
  beforeDestroy() {
    this.clearWorker();
  },
};
</script>

<style scoped lang="scss">
@import './python_settings';
</style>

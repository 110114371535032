<template>
  <div class="settings-col">
    <div>
      <h4 class="mt-5 mb-3">Task Settings</h4>
      <v-text-field
        class="mb-2"
        outlined
        dense
        label="Task Name"
        :value="settings.taskName"
        @input="(val) => onUpdate(val, 'settings.taskName')"
      />
      <v-textarea
        class="mb-2"
        outlined
        dense
        label="Task Description"
        :value="settings.taskDescription"
        @input="(val) => onUpdate(val, 'settings.taskDescription')"
      ></v-textarea>

      <v-text-field
        class="mb-2"
        outlined
        dense
        label="Logo URL"
        :value="settings.logo"
        @input="(val) => onUpdate(val, 'settings.logo')"
      />
      <mustache-template
        label="Assign To(patient id)"
        :value="settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        :suggestions="availableInputFields"
        :singleLine="true"
      ></mustache-template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'patient-insurance',
  props: {
    availableInputFields: { required: true },
  },
  components: {
    MustacheTemplate,
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<style scoped lang="scss"></style>

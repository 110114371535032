<template>
  <div>
    <v-text-field
      outlined
      dense
      label="Delay (in seconds)"
      hide-details="auto"
      type="number"
      class="mb-3"
      :value="selectedNode.settings.delay"
      @input="(val) => onUpdate(val, 'settings.delay')"
    ></v-text-field>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "delay-settings",
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
</style>

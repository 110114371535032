import { render, staticRenderFns } from "./settings-block.vue?vue&type=template&id=6fa64693&scoped=true"
import script from "./settings-block.vue?vue&type=script&lang=js"
export * from "./settings-block.vue?vue&type=script&lang=js"
import style0 from "./settings-block.vue?vue&type=style&index=0&id=6fa64693&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa64693",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ObjectHelperSettings"},[_vm._l((_vm.settings.inputs),function(input,inputIndex){return _c('div',{key:inputIndex,staticClass:"5"},[_c('p',{staticClass:"text-h6 mb-3"},[_vm._v("Input #"+_vm._s(inputIndex + 1))]),_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{attrs:{"outlined":"","dense":"","value":input.inputNodeId,"item-text":"name","item-value":"id","items":_vm.availableInputs.filter((i) => i.action_type),"label":"Connector"},on:{"input":(val) =>
              _vm.onUpdate(val, `settings.inputs[${inputIndex}].inputNodeId`),"change":function($event){return _vm.changeInput(inputIndex)}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteInput(inputIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('div',{staticClass:"mb-3"},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('p',{staticClass:"text-subtitle-1 mb-0"},[_vm._v("Function Parameters")])]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showAddParamBtn(inputIndex))?_c('v-btn',{attrs:{"dense":"","text":"","color":"primary"},on:{"click":function($event){return _vm.addParam(inputIndex)}}},[_vm._v(" + Add ")]):_vm._e()],1)],1),_vm._l((input.params),function(param,paramIndex){return _c('v-row',{key:paramIndex,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Name","value":param.name},on:{"change":_vm.updateModel,"input":(val) =>
                _vm.onUpdate(
                  val,
                  `settings.inputs[${inputIndex}].params[${paramIndex}].name`
                )}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","value":param.value,"menu-props":"auto","item-text":"name","item-value":"name","items":_vm.availableInputFields(inputIndex),"label":"Value","clearable":"","append-icon":""},on:{"input":(val) =>
                _vm.onUpdate(
                  val,
                  `settings.inputs[${inputIndex}].params[${paramIndex}].value`
                ),"change":_vm.updateModel}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteParam(inputIndex, paramIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)})],2)],1)}),(_vm.showAddInputBtn)?_c('v-btn',{staticClass:"mb-3",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.addInput()}}},[_vm._v(" + Add Input ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div>
      <h4 class="mt-5 mb-2">Request Origin</h4>
      <v-text-field
        class=""
        outlined
        dense
        label="Website URL(comma separated)"
        :value="settings.origins"
        @input="(val) => onUpdate(val, `settings.origins`)"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'dropin-settings',
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
  },
};
</script>

<template>
  <div class="HttpSettings">
    <v-row dense>
      <v-col cols="7" class="mb-3">
        <mustache-template
          label="URL"
          :value="settings.url"
          @input="(val) => onUpdate(val, 'settings.url')"
          :suggestions="availableInputFields"
          :singleLine="true"
        />
      </v-col>
      <v-col cols="5">
        <v-select
          outlined
          dense
          :value="settings.method"
          @input="(val) => onUpdate(val, 'settings.method')"
          :items="['GET', 'POST']"
          label="Method"
          clearable
        />
      </v-col>
    </v-row>

    <v-tabs v-model="tab" grow class="mb-4">
      <v-tab v-for="tab in tabs" :key="tab">{{ tab }}</v-tab>

      <v-tab-item :value="tabs.indexOf('headers')" class="mt-4">
        <v-row
          class="mb-1"
          dense
          v-for="(header, i) in settings.add_headers"
          :key="i"
        >
          <v-col>
            <mustache-template
              label="Name"
              class="mr-2"
              :value="header.name"
              @input="(val) => onUpdate(val, `settings.add_headers[${i}].name`)"
              :suggestions="availableInputFields"
              :singleLine="true"
            />
          </v-col>
          <v-col>
            <mustache-template
              label="Value"
              :value="header.value"
              @input="
                (val) => onUpdate(val, `settings.add_headers[${i}].value`)
              "
              :suggestions="availableInputFields"
              :singleLine="true"
            />
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="deleteHeader(i)">
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn text class="mt-2" v-if="showAddHeadersBtn" @click="addHeader">
          + Add
        </v-btn>
      </v-tab-item>

      <v-tab-item :value="tabs.indexOf('body')" class="mt-4">
        <mustache-template
          label="Request body"
          :placeholder="bodyPlaceholder"
          :value="settings.body"
          @input="(val) => onUpdate(val, 'settings.body')"
          :suggestions="availableInputFields"
        ></mustache-template>
      </v-tab-item>
    </v-tabs>

    <div>
      <v-text-field
        outlined
        dense
        label="FileName (For File Downloads)"
        hide-details="auto"
        :value="settings.fileName"
        @input="(val) => onUpdate(val, 'settings.fileName')"
      />
    </div>

    <v-btn
      class="mb-8 mt-4"
      color="primary"
      :loading="testing"
      @click="testHttp()"
    >
      Test request
    </v-btn>

    <v-snackbar
      color="error"
      :timeout="99999999999999"
      right
      v-model="snackbar"
    >
      {{ error }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-dialog v-model="outputDialog" width="700" class="HttpSettings__modal">
      <v-card>
        <v-card-title class="HttpSettings__modal-title">
          HTTP Response
        </v-card-title>

        <v-card-text class="HttpSettings__modal-content">
          <code class="code">{{ httpResponse }}</code>
        </v-card-text>

        <v-divider />

        <v-card-actions class="HttpSettings__modal-content justify-end">
          <v-btn color="primary" text @click="outputDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import cloneDeep from "lodash/cloneDeep";

import MustacheTemplate from "@/components/mustache/mustache-template.vue";
import { getModel } from "@/util/actionsModels";

const { mapGetters: customerGetters } = createNamespacedHelpers("customer");
const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers("workflows/details");

export default {
  name: "http-settings",
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      outputDialog: false,
      snackbar: false,
      error: "",
      httpResponse: "",
      tabs: ["headers", "body"],
      tab: "headers",
      testing: false,
      bodyPlaceholder: `Example:
        {
          "patientID": {{patient.id}}
        }
      `,
    };
  },
  computed: {
    ...customerGetters({
      customer: "CUSTOMER",
      selected_customer: "SELECTED_CUSTOMER",
    }),
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddHeadersBtn() {
      const headers = this.settings.add_headers;
      return (
        !headers ||
        !headers[0] ||
        (headers[headers.length - 1].name && headers[headers.length - 1].value)
      );
    },
  },
  watch: {
    httpResponse() {
      this.outputDialog = true;
    },
    error() {
      this.snackbar = true;
    },
  },
  methods: {
    ...workflowDetailsActions(["executeSingleAction"]),
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    addHeader() {
      const settings = cloneDeep(this.settings);
      settings.add_headers.push({
        name: "",
        value: "",
      });
      this.onUpdate(settings, "settings");
    },
    deleteHeader(index) {
      const settings = cloneDeep(this.settings);
      settings.add_headers.splice(index, 1);
      this.onUpdate(settings, "settings");
    },
    async testHttp() {
      try {
        if (!this.settings.url) throw Error("URL is not provided");
        this.testing = true;

        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: "execute_http_request",
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.httpResponse = data.output.data.response;
          const output_type = getModel(data.output.model);
          this.onUpdate(output_type, "output_type");
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testing = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.HttpSettings {
  &__modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__modal-content {
    padding-top: 20px !important;
  }

  &__modal-actions {
    padding: 15px 25px !important;
  }
}

.code {
  display: block;
  margin-bottom: 8px;
  box-shadow: none;
  background-color: #fff;
  color: #000;
  max-height: 80vh;
  overflow-y: scroll;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',[_c('p',{staticClass:"text--secondary subtitle-2"},[_vm._v(" Choose when to look for the visits ")]),_c('h5',[_vm._v("From")]),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Period","hide-details":"auto","type":"number","value":_vm.selectedNode.settings.from},on:{"input":(val) => _vm.onUpdate(val, 'settings.from')}}),_c('p',{staticClass:"text--secondary subtitle-2 mt-2 mx-4"},[_vm._v("Days")]),_c('v-select',{attrs:{"outlined":"","dense":"","value":_vm.selectedNode.settings.from_sign,"item-text":"name","item-value":"value","items":[
          { name: 'In past', value: '-' },
          { name: 'In future', value: '+' },
        ],"label":"Future/past"},on:{"input":(val) => _vm.onUpdate(val, 'settings.from_sign')}})],1),_c('h5',[_vm._v("To")]),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Period","hide-details":"auto","type":"number","value":_vm.selectedNode.settings.to},on:{"input":(val) => _vm.onUpdate(val, 'settings.to')}}),_c('p',{staticClass:"text--secondary subtitle-2 mt-2 mx-4"},[_vm._v("Days")]),_c('v-select',{attrs:{"outlined":"","dense":"","value":_vm.selectedNode.settings.to_sign,"item-text":"name","item-value":"value","items":[
          { name: 'In future', value: '+' },
          { name: 'In past', value: '-' },
        ],"label":"Future/past"},on:{"input":(val) => _vm.onUpdate(val, 'settings.to_sign')}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
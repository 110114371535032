<template>
  <div>
    <div
      v-if="
        selectedNode.action_type === 'get_benefits_pverify' ||
        selectedNode.action_type === 'get_inquiry_pverify'
      "
    >
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.firstname"
        @input="(val) => onUpdate(val, 'settings.firstname')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="firstname"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.lastname"
        @input="(val) => onUpdate(val, 'settings.lastname')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="lastname"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.date_of_birth"
        @input="(val) => onUpdate(val, 'settings.date_of_birth')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="date of birth"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.member_id"
        @input="(val) => onUpdate(val, 'settings.member_id')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="member id"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.insurance_company"
        @input="(val) => onUpdate(val, 'settings.insurance_company')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="insurance company"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.pid"
        @input="(val) => onUpdate(val, 'settings.pid')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Patient ID (optional)"
        clearable
      ></v-select>

      <v-select
        v-if="selectedNode.action_type === 'get_benefits_pverify'"
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.practice_type_code"
        @input="(val) => onUpdate(val, 'settings.practice_type_code')"
        @change="setOutputModels()"
        @click:clear="setOutputModels"
        item-text="name"
        item-value="code"
        :items="practiceTypeCodes"
        label="Practice type"
        clearable
      ></v-select>
      <div v-if="selectedNode.action_type === 'get_inquiry_pverify'">
        <h5 my-5>Service type codes</h5>
        <div
          my-5
          class="param-row mb-n6"
          v-for="(serviceType, i) in selectedNode.settings.codes"
          :key="serviceType.id"
        >
          <v-select
            outlined
            dense
            :value="serviceType"
            :item-text="formatCodeDescription"
            :items="availableCodes"
            label="Service type code"
            @change="(value) => changeCode(value, i)"
            return-object
            clearable
          ></v-select>
          <v-btn class="param-row__icon-btn" icon @click="deleteCode(i)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <v-btn class="mb-8" @click="addServiceCode"> + Add </v-btn>
      </div>
    </div>

    <div v-else-if="selectedNode.action_type === 'parse_benefits_pverify'">
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.response"
        @input="(val) => onUpdate(val, 'settings.response')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="pVerify response"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.insurance_company"
        @input="(val) => onUpdate(val, 'settings.insurance_company')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Insurance company"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.provider_type"
        @input="(val) => onUpdate(val, 'settings.provider_type')"
        item-text="name"
        item-value="name"
        :items="['primary', 'secondary']"
        label="Provider type"
        clearable
      ></v-select>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.coverage_levels"
        @input="(val) => onUpdate(val, 'settings.coverage_levels')"
        multiple
        item-text="name"
        item-value="name"
        :items="['individual', 'family', 'employee_and_spouse']"
        label="Coverage level"
        clearable
      ></v-select>
      <v-checkbox
        class="mb-3"
        outlined
        dense
        :input-value="selectedNode.settings.in_plan"
        @change="(val) => onUpdate(val, 'settings.in_plan')"
        label="In plan network only"
      ></v-checkbox>
      <v-select
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.pid"
        @input="(val) => onUpdate(val, 'settings.pid')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Patient ID (optional)"
        clearable
      ></v-select>
      <h5 my-5>Service type codes</h5>
      <div
        my-5
        class="param-row mb-n6"
        v-for="(serviceType, i) in selectedNode.settings.codes"
        :key="serviceType.id"
      >
        <v-select
          outlined
          dense
          :value="serviceType"
          :item-text="formatCodeDescription"
          :items="availableCodes"
          label="Service type code"
          @change="(value) => changeCode(value, i)"
          return-object
          clearable
        ></v-select>
        <v-btn class="param-row__icon-btn" icon @click="deleteCode(i)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-btn class="mb-8" @click="addServiceCode"> + Add </v-btn>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';
import { getModel } from '../../util/actionsModels';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'pverify-benefits-settings',
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      defaultModel: null,
      pverifyModel: null,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      actionsOutputs: 'ACTION_OUTPUTS',
    }),
    availableCodes() {
      return this.$store.getters['shared/SERVICE_TYPES'];
    },
    practiceTypeCodes() {
      return [
        {
          name: 'DME (12)',
          code: 3,
        },
        {
          name: 'Physical Therapy (PT)',
          code: 12,
        },
        {
          name: 'General Office Benefits(30)',
          code: 70,
        },
        {
          name: 'Specialist - Office (98)',
          code: 18,
        },
        {
          name: 'Surgical -Office (2)',
          code: 22,
        },
        {
          name: 'Diagnostic Lab (5)',
          code: 24,
        },
        {
          name: 'Chiropractic (33)',
          code: 30,
        },
        {
          name: 'MRI/CAT Scan (62)',
          code: 31,
        },
        {
          name: 'X-Ray (4)',
          code: 32,
        },
        {
          name: 'Specialist(Office)_Chemotherapy',
          code: 36,
        },
        {
          name: 'DME_Specialist_Office (42)',
          code: 42,
        },
        {
          name: 'Specialist(Office)_ASC Facility',
          code: 44,
        },
        {
          name: 'SpecialistOffice_Physical Therapy',
          code: 88,
        },
        {
          name: 'Telemedicine-Primary Care(30)',
          code: 89,
        },
        {
          name: 'Telemedicine-Specialist Services(98)',
          code: 90,
        },
        {
          name: 'Telemedicine-Urgent Care(UC)',
          code: 91,
        },
        {
          name: 'Telemedicine-Physical Therapy(PT)',
          code: 92,
        },
        {
          name: 'Telemedicine-Mental Health(MH)',
          code: 93,
        },
      ];
    },
  },
  mounted() {
    const actionType = this.selectedNode.action_type;

    if (actionType === 'get_benefits_pverify') {
      this.pverifyModel = this.actionsOutputs[actionType];
      const modelObj = {
        pid: '',
        status: {},
        response: {},
      };

      const model = getModel(modelObj, 'Benefits');
      model.value[1].value = this.pverifyModel.status.value;
      model.value[2].value = this.pverifyModel.response;
      this.defaultModel = model;

      this.setOutputModels();
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    formatCodeDescription(item) {
      return item.code + ' - ' + item.description;
    },
    deleteCode(index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes.splice(index, 1);
      this.onUpdate(settings, 'settings');
    },
    changeCode(value, index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes[index] = value;
      this.onUpdate(settings, 'settings');
    },
    setOutputModels() {
      if (this.defaultModel) {
        const practiceTypeCode = this.selectedNode.settings.practice_type_code;
        const model = cloneDeep(this.defaultModel);
        const {
          DMESummary,
          PrimaryCareSummary,
          SpecialistOfficeSummary,
          SurgicalSummary,
          DiagnosticLabSummary,
          ChiropracticSummary,
          MRI_CT_ScanSummary,
          XRaySummary,
          SpecialistOfficeChemotherapy,
          ASC_FacilitySummary,
          PhysicalTherapySummary,
          TelemedicinePrimaryCareSummary,
          TelemedicineSpecialistSummary,
          TelemedicineUrgentCareSummary,
          TelemedicinePhysicalTherapySummary,
          TelemedicineMentalHealthSummary,
          OncologySummary,
        } = this.pverifyModel;

        switch (practiceTypeCode) {
          case 3:
            model.value[2].value.push(DMESummary);
            break;
          case 12:
            model.value[2].value.push(PhysicalTherapySummary);
            break;
          case 17:
            model.value[2].value.push(PrimaryCareSummary);
            model.value[2].value.push(SpecialistOfficeSummary);
            break;
          case 18:
            model.value[2].value.push(SpecialistOfficeSummary);
            break;
          case 22:
            model.value[2].value.push(SurgicalSummary);
            break;
          case 24:
            model.value[2].value.push(DiagnosticLabSummary);
            break;
          case 30:
            model.value[2].value.push(ChiropracticSummary);
            break;
          case 31:
            model.value[2].value.push(MRI_CT_ScanSummary);
            break;
          case 32:
            model.value[2].value.push(XRaySummary);
            break;
          case 36:
            model.value[2].value.push(...SpecialistOfficeChemotherapy.value);
            model.value[2].value.push(SpecialistOfficeSummary);
            model.value[2].value.push(OncologySummary);
            
            break;
          case 42:
            model.value[2].value.push(DMESummary);
            model.value[2].value.push(SpecialistOfficeSummary);
            break;
          case 44:
            model.value[2].value.push(SpecialistOfficeSummary);
            model.value[2].value.push(ASC_FacilitySummary);
            break;
          case 88:
            model.value[2].value.push(SpecialistOfficeSummary);
            model.value[2].value.push(PhysicalTherapySummary);
            break;
          case 89:
            model.value[2].value.push(TelemedicinePrimaryCareSummary);
            break;
          case 90:
            model.value[2].value.push(TelemedicineSpecialistSummary);
            break;
          case 91:
            model.value[2].value.push(TelemedicineUrgentCareSummary);
            break;
          case 92:
            model.value[2].value.push(TelemedicinePhysicalTherapySummary);
            break;
          case 93:
            model.value[2].value.push(TelemedicineMentalHealthSummary);
            break;
          default:
            break;
        }

        this.onUpdate(model, 'output_type');
      }
    },
    addServiceCode() {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.codes.push({
        id: null,
        description: null,
        code: null,
      });
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped>
.param-row {
  display: flex;
  width: 100%;

  &__field {
    width: 33%;
  }
  &__icon-btn {
    width: 10%;
  }
}
</style>

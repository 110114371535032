<template>
  <v-menu
    right
    center
    offset-x
    content-class="ActionList"
    rounded="lg"
    :close-on-content-click="false"
    v-model="showMenu"
    :attach="true"
  >
    <template v-slot:activator="{ attrs, on }">
      <div
        class="ActionList__plus-block"
        @dragenter.stop.prevent="handleNodeEnter"
        @dragleave.stop.prevent="handleNodeLeave"
        @dragover.stop.prevent="handleDragOver"
        @drop.stop.prevent="handleNodeDrop"
      >
        <v-icon
          color="#374151"
          v-bind="attrs"
          v-on="on"
          class="ActionList__action"
          :disabled="isDisabled"
        >
          mdi-plus-circle-outline
        </v-icon>
      </div>
    </template>

    <v-card
      class="ActionList__menu-list"
      max-width="280"
      max-height="365"
      rounded="lg"
    >
      <v-card-title>
        <v-text-field
          label="Search Connectors"
          outlined
          dense
          :hide-details="true"
          v-model="searchValue"
        />
      </v-card-title>

      <v-card-text class="ActionList__menu-list">
        <v-list>
          <v-list-item-group
            v-for="connector in actions"
            :key="connector.type_id"
          >
            <template v-if="connector.name">
              <v-subheader>{{ connector.name }}</v-subheader>
              <template v-if="connector.actions">
                <v-list-item
                  v-for="action in excludeAction(connector.actions)"
                  :key="action.type_id"
                  @click="addNode(connector, action)"
                  two-line
                >
                  <ActionIcon :action="action" :color="connector.color" />

                  <v-list-item-content class="ActionList__menu-text">
                    <v-list-item-title class="ActionList__menu-title">
                      {{ action.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ action.descr || '** provide description plz **' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item @click="addNode(connector)">
                  <ActionIcon :action="connector" />

                  <v-list-item-content two-line>
                    <v-list-item-title class="ActionList__menu-title">
                      {{ connector.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ connector.descr || '** provide description plz **' }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { actionsTypes, nestedActionTypes } from '@/util/action-types';
import ActionIcon from '@/components/action-icon/action-icon.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapActions: workflowDetailsActions,
  mapGetters: workflowDetailsGetters,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'ActionListMenu',
  components: {
    ActionIcon,
  },
  props: {
    insertIndex: {
      required: true,
      type: Number,
    },
    parentNode: {
      required: false,
      default: null,
    },
    branch: {
      required: false,
      default: null,
    },
    parentNodeList: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isNestedActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dragStartHover: false,
      showMenu: false,
      searchValue: '',
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
    }),
    ...workflowDetailsGetters({
      startSettings: 'START_SETTINGS',
      actionsOutputs: 'ACTION_OUTPUTS',
      movingNodeId: 'MOVING_NODE_ID',
      workflow: 'WORKFLOW',
    }),
    excludedActions() {
      let excluded_actions = [];
      if (
        this.customer &&
        this.customer.CustomersFeature &&
        this.customer.CustomersFeature.excluded_actions
      ) {
        excluded_actions = this.customer.CustomersFeature.excluded_actions;
      }
      //if not serverless, exclude next actions:
      if (!this.workflow.isServerless) {
        excluded_actions = [
          ...excluded_actions,
          'short_browser_auto_playwright',
          'long_browser_auto_playwright',
          'msgraph_onedrive_create_folder',
          'msgraph_onedrive_get_folder',
          'js_pdf_fill',
          'pdf_split_file',
          'msgraph_workbook_update_cell',
        ];
      }
      /**
       * we never want loop_output selected.
       * It's a dummy connector
       */
      return [...excluded_actions, 'loop_output'];
    },
    actionsTypes() {
      const name = this.customer.CustomersFeature.data_source_name;
      let at = actionsTypes(name);
      if (this.isNestedActions) {
        at = nestedActionTypes();
      }
      const trigger_type = this.startSettings.trigger_type;

      return cloneDeep(at).filter((action) => {
        return !(
          action.action_type === 'human_in_the_loop' &&
          trigger_type &&
          trigger_type !== 'manual'
        );
      });
    },
    actions() {
      const actionsTypes = this.excludeAction(this.actionsTypes);
      if (!this.searchValue) return actionsTypes;
      const searchValue = this.searchValue.toLowerCase();
      const result = [];

      for (let i = 0; i < actionsTypes.length; i++) {
        const group = cloneDeep(actionsTypes[i]);
        if (
          group.name?.toLowerCase().includes(searchValue) ||
          group.display_name?.toLowerCase().includes(searchValue) ||
          group.action_type?.toLowerCase().includes(searchValue) ||
          group.descr?.toLowerCase().includes(searchValue)
        ) {
          result.push(group);
          continue;
        }

        if (group.actions) {
          const actions = group.actions;
          group.actions = [];

          actions.forEach((action) => {
            if (
              action.name?.toLowerCase().includes(searchValue) ||
              action.display_name?.toLowerCase().includes(searchValue) ||
              action.action_type?.toLowerCase().includes(searchValue) ||
              action.descr?.toLowerCase().includes(searchValue)
            ) {
              group.actions.push(action);
            }
          });

          if (group.actions.length > 0) {
            result.push(group);
          }
        }
      }

      return result;
    },
  },
  methods: {
    ...workflowDetailsActions(['insertNode', 'nodeDrop']),
    excludeAction(actions) {
      return actions.filter((action) => {
        return (
          !action.default && !this.excludedActions.includes(action.action_type)
        );
      });
    },
    addNode(connector, action) {
      this.showMenu = false;
      const node = cloneDeep(connector);
      if (action) {
        node.name = action.name;
        node.display_name = action.display_name;
        node.action_type = action.action_type;
        node.descr = action.descr;
        node.icon = action.icon ?? connector.icon;
        node.color = action.color ?? connector.color;
        node.svgIcon = action.svgIcon;
        node.settings = {
          ...node.settings,
          ...action.settings,
        };
      }
      const options = {
        index: this.insertIndex,
        parentNode: this.parentNode,
        branch: this.branch,
        parentNodeList: this.parentNodeList,
      };
      this.insertNode({ node, options });
    },
    handleNodeEnter() {},
    handleNodeLeave() {},
    handleDragOver() {},
    handleNodeDrop() {
      if (this.isDisabled) return;
      this.dragStartHover = false;

      const options = {
        index: this.insertIndex,
        parentNode: this.parentNode,
        branch: this.branch,
        parentNodeList: this.parentNodeList,
      };

      this.nodeDrop({ options });
    },
  },
};
</script>

<style scoped lang="scss">
@import './action-list';
</style>

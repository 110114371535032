<template>
  <div class="MustacheTemplate">
    <v-menu offset-y :max-height="400" v-model="showDropdown">
      <template v-slot:activator="{}">
        <v-textarea
          outlined
          dense
          ref="templateTextArea"
          :label="label"
          :placeholder="placeholder"
          hide-details="auto"
          :hint="showHint && false ? 'Type {{ to get suggestions' : null"
          @change="onChange"
          @keydown="onChange"
          @keyup="onChange"
          @click="onChange"
          novalidate
          :rows="singleLine ? 1 : 5"
          v-model="template"
          :rules="rules"
          :disabled="disabled"
        >
          <template v-if="append" slot="append">
            <span>{{ appendText }}</span>
          </template>
        </v-textarea>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in suggestions"
          :key="index"
          @click="insertVariable(item.name)"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "MustacheTemplate",
  props: {
    suggestions: { required: true },
    value: { required: true },
    placeholder: { required: false },
    label: { required: true },
    showHint: { default: true },
    singleLine: { default: false },
    rules: {
      default: function () {
        return [];
      },
    },
    append: { required: false, default: false },
    appendText: { required: false, default: "" },
    disabled: { required: false, default: false },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    template: {
      get() {
        return this.value || "";
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onChange() {
      const val = this.template;
      const cursorPos = this.$refs.templateTextArea.$refs.input.selectionStart;
      const str = val.substring(0, cursorPos);
      this.showDropdown = str.endsWith("{{") && !str.endsWith("{{{");
    },
    insertVariable(item) {
      const cursorPos = this.$refs.templateTextArea.$refs.input.selectionStart;
      /* eslint-disable */
      const field = item.match(/[\w\.]+/)[0];
      /* eslint-enable */
      const start = this.template.substring(0, cursorPos);
      const end = this.template.substring(cursorPos);
      const value = end.startsWith("}}") ? field : `${field}}}`;
      this.template = start + value + end;
    },
  },
};
</script>

<style scoped>
</style>
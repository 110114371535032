var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-combobox',{attrs:{"outlined":"","dense":"","value":_vm.selectedNode.settings.to.value,"item-text":"name","item-value":"name","items":_vm.availableInputFields,"label":_vm.toLabel},on:{"update:search-input":_vm.setIsCustomTo,"change":_vm.setIsCustomTo}}),_c('div',{staticClass:"mt-3"},[(
          _vm.selectedNode.action_type === 'send_email' ||
            _vm.selectedNode.action_type === 'send_email_html'
        )?_c('mustache-template',{staticClass:"mb-3",attrs:{"label":"Enter subject","placeholder":'Example: Hi {{patient.firstname}}, this is your updated bill from Valley Women\'s Health',"value":_vm.selectedNode.settings.subject,"suggestions":_vm.availableInputFields,"singleLine":true},on:{"input":(val) => _vm.onUpdate(val, 'settings.subject')}}):_vm._e(),(
          _vm.selectedNode.action_type === 'send_email' ||
            _vm.selectedNode.action_type === 'send_email_html'
        )?_c('v-combobox',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","value":_vm.selectedNode.settings.attachment,"item-text":"name","item-value":"name","items":_vm.availableInputFields,"label":"Attachment"},on:{"update:search-input":_vm.setAttachment,"change":_vm.setAttachment}}):_vm._e(),_c('mustache-template',{attrs:{"label":"Template","placeholder":'Example: Hi {{patient.firstname}}. Ms {{patient.lastname}}. Your email is {{email}}',"value":_vm.selectedNode.settings.template,"suggestions":_vm.availableInputFields},on:{"input":(val) => _vm.onUpdate(val, 'settings.template')}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <section>
    <div>
      <p class="text--secondary subtitle-2">
        Choose when to look for the visits
      </p>
      <h5>From</h5>
      <v-row no-gutters class="flex-nowrap">
        <v-text-field
          outlined
          dense
          label="Period"
          hide-details="auto"
          type="number"
          :value="selectedNode.settings.from"
          @input="(val) => onUpdate(val, 'settings.from')"
        ></v-text-field>
        <p class="text--secondary subtitle-2 mt-2 mx-4">Days</p>
        <v-select
          outlined
          dense
          :value="selectedNode.settings.from_sign"
          @input="(val) => onUpdate(val, 'settings.from_sign')"
          item-text="name"
          item-value="value"
          :items="[
            { name: 'In past', value: '-' },
            { name: 'In future', value: '+' },
          ]"
          label="Future/past"
        ></v-select>
      </v-row>
      <h5>To</h5>
      <v-row no-gutters class="flex-nowrap">
        <v-text-field
          outlined
          dense
          label="Period"
          hide-details="auto"
          type="number"
          :value="selectedNode.settings.to"
          @input="(val) => onUpdate(val, 'settings.to')"
        ></v-text-field>
        <p class="text--secondary subtitle-2 mt-2 mx-4">Days</p>
        <v-select
          outlined
          dense
          :value="selectedNode.settings.to_sign"
          @input="(val) => onUpdate(val, 'settings.to_sign')"
          item-text="name"
          item-value="value"
          :items="[
            { name: 'In future', value: '+' },
            { name: 'In past', value: '-' },
          ]"
          label="Future/past"
        ></v-select>
      </v-row>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "walkin-visits-settings",
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TestBlock"},[_c('div',{staticClass:"TestBlock__btn-container pb-4"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.isWorkflowRunning,"loading":!_vm.running.runningInProductionMode && _vm.isWorkflowRunning},on:{"click":function($event){return _vm.run()}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":""}},[_vm._v("mdi-bug-check")]),_vm._v(" Test Run ")],1)],1),(
      _vm.running.validation?.validation_status === _vm.workflowStatus.SUCCESS &&
      (_vm.running.vm_status === _vm.workflowStatus.SUCCESS ||
        _vm.running.vm_status === _vm.workflowStatus.SKIPPED)
    )?_c('h3',{staticClass:"py-3 px-4"},[_c('workflow-status-icon',{staticClass:"mr-1",attrs:{"status":_vm.running.workflow_status}}),_vm._v(" Running workflow in "+_vm._s(_vm.running.runningInProductionMode ? 'production mode' : 'test mode')+" ")],1):_vm._e(),(_vm.headerText)?_c('v-card',{staticClass:"mx-4 mb-4 TestBlock__run-status",attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"TestBlock__run-status-title"},[_c('workflow-status-icon',{staticClass:"mr-1",attrs:{"status":_vm.running.workflow_status}}),_vm._v(" "+_vm._s(_vm.headerText)+" ")],1),_c('v-list',{attrs:{"flat":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('workflow-status-icon',{attrs:{"status":_vm.running.validation?.validation_status}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Validation")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('workflow-status-icon',{attrs:{"status":_vm.running.validation?.actions}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Actions")])],1)],1),(
          _vm.running.validation?.schedule !== _vm.workflowStatus.SKIPPED &&
          _vm.running.validation?.schedule !== _vm.workflowStatus.NOT_STARTED
        )?_c('v-list-item',[_c('v-list-item-icon',[_c('workflow-status-icon',{attrs:{"status":_vm.running.validation?.schedule}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Schedule")])],1)],1):_vm._e()],1)],1):_vm._e(),(
      _vm.running.vm_status &&
      _vm.running.vm_status !== _vm.workflowStatus.NOT_STARTED &&
      _vm.running.vm_status !== _vm.workflowStatus.SKIPPED
    )?_c('v-card',{staticClass:"mx-4 mb-4 TestBlock__response",attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-text',[_c('workflow-status-icon',{attrs:{"status":_vm.running.vm_status}}),_vm._v(" Testing connection with the remote bot ")],1)],1):_vm._e(),(
      _vm.running.validation?.validation_status === _vm.workflowStatus.SUCCESS &&
      (_vm.running.vm_status === _vm.workflowStatus.SUCCESS ||
        _vm.running.vm_status === _vm.workflowStatus.SKIPPED)
    )?_c('v-card',{staticClass:"mx-4 mb-4 TestBlock__response",attrs:{"outlined":"","rounded":"lg"}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":"","multiple":""}},_vm._l((_vm.actionsStatuses),function(action,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"px-4",scopedSlots:_vm._u([{key:"actions",fn:function(){return [(action.finish)?[_c('span',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(_vm.formatDuration(action.start, action.finish)))])]:(action.progress)?[_c('span',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(action.progress))])]:_vm._e()]},proxy:true}],null,true)},[_c('div',[_c('workflow-status-icon',{staticClass:"mr-1",attrs:{"status":action.status}}),_vm._v(" Step "+_vm._s(index + 1)+"."+_vm._s(action.action_name)+" ")],1)]),_c('v-expansion-panel-content',[_c('input-output-viewer',{attrs:{"action":action,"isServerless":_vm.workflow.isServerless}})],1)],1)}),1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
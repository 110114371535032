<template>
  <div>
    <div>
      <v-text-field
        outlined
        dense
        label="Path"
        hide-details="auto"
        :value="selectedNode.settings.path"
        @input="(val) => onUpdate(val, 'settings.path')"
        @click:append-outer="getCWD"
        :append-outer-icon="
          performingSFTPAction ? 'mdi-loading mdi-spin' : 'mdi-reload'
        "
        :prefix="settings.cwd"
      />

      <v-checkbox
        v-if="selectedNode.action_type === 'sftp_upload'"
        label="Overwrite file if it already exists?"
        :input-value="selectedNode.settings.overwrite"
        @change="(val) => onUpdate(val, 'settings.overwrite')"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');
const { mapActions: workflowsActions, mapGetters: workflowsGetters } =
  createNamespacedHelpers('workflows/list');

export default {
  name: 'sftp-settings',
  props: {
    availableInputFields: { required: false },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    ...workflowsGetters({
      performingSFTPAction: 'PERFORM_SFTP_ACTION',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowsActions(['sftpAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async getCWD() {
      this.gettingCWD = true;
      try {
        const data = {
          authenticationId: this.selectedNode.settings.authenticationId,
          action: 'get_cwd',
        };
        const resp = await this.sftpAction(data);
        if (resp && resp.result) {
          this.onUpdate(resp.result, 'settings.cwd');
        }
      } finally {
        this.gettingCWD = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

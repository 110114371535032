<template>
  <v-dialog
    v-model="modalOpen"
    max-width="630px"
    class="AddExternalActivatorModal"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="AddExternalActivatorModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto">
            New external workflow activator
          </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="AddExternalActivatorModal__content">
        <component :is="getComponent" @submit-form="save" @close="close" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddEternalWorkflow from './forms/add-external-activator-form';

const { mapGetters: notificationsGetters } =
  createNamespacedHelpers('notifications');

const { mapMutations: workflowDetailsMutations } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'AddExternalActivatorModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    getComponent() {
      return AddEternalWorkflow;
    },
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...workflowDetailsMutations({
      addExternalActivationCreds: 'ADD_CREDENTIAL',
      setWorkflowEdited: 'WORKFLOW_EDITED',
    }),
    close() {
      this.modalOpen = false;
    },
    save(id) {
      this.addExternalActivationCreds(id);
      this.setWorkflowEdited(true);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import './add-external-activator-modal';
</style>

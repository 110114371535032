<template>
  <div>
    <div>
      <v-switch
        v-if="isMicrosoft"
        label="Use Share URL"
        class="mt-2"
        :input-value="settings.useShareUrl"
        @change="toggleUseShareUrl"
      ></v-switch>
      <mustache-template
        v-if="settings.useShareUrl"
        label="Share URL"
        class="mb-3"
        :value="settings.shareUrl"
        @input="(val) => onUpdate(val, `settings.shareUrl`)"
        :suggestions="availableInputFields"
        :singleLine="true"
      />
      <v-autocomplete
        v-else
        class="mb-3"
        outlined
        dense
        :value="settings.location"
        @input="(val) => setLocation(val)"
        item-text="name"
        item-value="id"
        :items="driveFolders"
        label="File Location"
        @click:append-outer="listFolders"
        :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
        clearable
      ></v-autocomplete>

      <v-select
        v-if="(isMicrosoft && isDownloadWithoutShareUrl) || isGoogleFileDownload"
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.fileInput"
        @input="(val) => onUpdate(val, 'settings.fileInput')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="File Input"
        clearable
      ></v-select>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: worklfowActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'file-upload',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      driveFolders: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      isServerless: 'IS_SERVERLESS',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    action() {
      return this.selectedNode.action_type;
    },
    suite() {
      if (
        this.action === 'google_drive_upload_file' ||
        this.action === 'google_drive_download_file'
      ) {
        return 'google';
      }
      return 'outlook';
    },
    isMicrosoft() {
      return this.isServerless == true && this.suite !== 'google';
    },
    isGoogleFileDownload() {
      return (
        this.isServerless == true &&
        this.suite === 'google' &&
        this.action === 'google_drive_download_file'
      );
    },
    isDownloadWithoutShareUrl() {
      const isDownload = this.action === 'msgraph_onedrive_download_file';
      if (isDownload && this.settings.useShareUrl) {
        return false;
      }
      return true;
    },
  },
  created() {
    if (this.isServerless) {
      this.addDynamicFolders();
    }
    if (!this.settings.location || !this.settings.locationName) {
      return;
    }
    this.driveFolders.push({
      id: this.settings.location,
      name: this.settings.locationName,
    });
  },
  methods: {
    ...worklfowActions(['outlookAction', 'googleAction']),
    setLocation(location) {
      this.onUpdate(location, 'settings.location');
      const folder = this.driveFolders.find((item) => item.id === location);
      if (folder?.name) {
        this.onUpdate(folder.name, 'settings.locationName');
        this.onUpdate(folder.driveId, 'settings.driveId');
      } else {
        this.onUpdate(null, 'settings.locationName');
        this.onUpdate(null, 'settings.driveId');
      }
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    suiteAction(data) {
      if (this.suite === 'google') return this.googleAction(data);
      if (this.suite === 'outlook') return this.outlookAction(data);
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedNode.input);
      const selectedOption = this.availableInputFields.find(
        (f) => f.uid === uid
      );
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;

      this.onUpdate(input, 'input');
    },
    async listFolders() {
      if (!this.settings.authenticationId) {
        return;
      }
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);

        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
        if (this.isServerless) {
          this.addDynamicFolders();
        }
      } finally {
        this.loading = false;
      }
    },
    addDynamicFolders() {
      const dynamicFolders = this.availableInputFields.map((inputField) => {
        return { name: inputField.name };
      });
      this.driveFolders.push(...dynamicFolders);
    },
    toggleUseShareUrl() {
      const settings = cloneDeep(this.settings);
      settings.useShareUrl = !settings.useShareUrl;
      if (!settings.useShareUrl) {
        settings.location = null;
      }
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="BrowserSettings">
    <div>
      <router-link :to="{ name: 'BrowserAutomationTutorial' }" target="_blank">
        How to create .side file?
      </router-link>

      <v-row class="my-3" dense>
        <v-col cols="7">
          <v-file-input
            class="mr-1"
            accept=".side"
            outlined
            dense
            v-model="sideFile"
            label="Import .side file"
            truncate-length="15"
            :clearable="false"
          />
        </v-col>
        <v-col cols="5">
          <v-btn
            color="primary"
            @click="downloadSideFile"
            :disabled="!settings.automation"
            dense
          >
            <v-icon>mdi-download</v-icon>
            Side File
          </v-btn>
        </v-col>
      </v-row>

      <v-text-field
        outlined
        dense
        :value="settings.automation.url"
        @input="(val) => onUpdate(val, 'settings.automation.url')"
        label="Base URL"
      />

      <v-divider class="mx-n4 BrowserSettings__divider my-3" />

      <p class="text-h6 mb-3">Events</p>

      <div class="BrowserSettings__event-editor ml-n4 py-4 mb-3">
        <v-row justify="center">
          <v-btn class="plus-block mt-1" @click="openEventsModal(0)" icon>
            <v-icon color="#374151"> mdi-plus-circle-outline </v-icon>
          </v-btn>
        </v-row>

        <draggable
          :value="settings.automation.tests[0].commands"
          @input="
            (val) => onUpdate(val, 'settings.automation.tests[0].commands')
          "
        >
          <transition-group>
            <div
              class="selected-event d-flex flex-column align-center"
              v-for="(event, i) in events"
              :key="event.id"
            >
              <v-card class="BrowserSettings__event-card">
                <v-card-title class="BrowserSettings__event-card-title pb-1">
                  {{ event.command }}
                </v-card-title>
                <v-card-text>
                  <p v-if="event.target" class="mb-1">
                    {{ event.target }}
                  </p>
                  <p v-if="event.value" class="mb-1">
                    {{ event.value }}
                  </p>
                  <p v-if="event.filename" class="mb-1">
                    {{ event.filename }}.png
                  </p>
                  <p v-if="event.link" class="mb-1">
                    {{ event.link }}
                  </p>
                  <p v-if="event.waitTime" class="mb-1">
                    {{ event.waitTime }}
                  </p>
                  <p v-if="event.content" class="mb-1">
                    {{ event.content }}
                  </p>
                  <p v-if="event.extension" class="mb-1">
                    {{ event.extension }}
                  </p>
                  <p v-if="event.singleFile !== undefined" class="mb-1">
                    Single File? {{ event.singleFile }}
                  </p>
                  <div v-if="event.command === 'getOTPToken'">
                    <p class="mb-1">Secret: {{ event.secret }}</p>
                    <p class="mb-1">VarName: {{ event.varName ?? 'token' }}</p>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-actions class="justify-end">
                  <v-btn
                    color="grey darken-2"
                    text
                    @click="openEventsModal(i, true)"
                  >
                    Edit
                  </v-btn>
                  <v-btn color="error" text @click="deleteEvent(i)"
                    >Delete</v-btn
                  >
                </v-card-actions>
              </v-card>

              <v-row justify="center">
                <v-btn
                  class="plus-block mt-1"
                  @click="openEventsModal(i + 1)"
                  icon
                >
                  <v-icon color="#374151"> mdi-plus-circle-outline </v-icon>
                </v-btn>
              </v-row>
              <v-icon v-if="i != events.length - 1" color="#374151">
                mdi-arrow-down
              </v-icon>
            </div>
          </transition-group>
        </draggable>

        <v-dialog v-model="showEventsDialog" width="400">
          <v-card>
            <v-card-title class="BrowserSettings__event-modal-title"
              >Events editor</v-card-title
            >
            <eventSettings
              v-model="currentEvent"
              :availableInputFields="availableInputFields"
              :showEventsDialog="showEventsDialog"
            />

            <v-divider />

            <v-card-actions class="justify-end py-3 px-4">
              <v-btn color="grey" @click="closeEventsModal" text>
                Cancel
              </v-btn>
              <v-btn color="primary" @click.stop="addEvent"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="param-row mb-5">
        <v-btn
          class="param-row"
          color="primary"
          :loading="runningTest"
          @click="testRun()"
        >
          Test Run
        </v-btn>
      </div>

      <images-popup
        :dialog="imageDialog"
        :toggleDialog="openImageModal"
        :images="screenshots"
      ></images-popup>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { get } from 'lodash';
import imagesPopup from '@/components/modals/image-modal/image-modal.vue';
import draggable from 'vuedraggable';
import uuidv4 from '@/util/uuid_generator';
import { getAutomationInitialSettings } from '@/util/browserAutomationEvents';
import { getModel } from '@/util/actionsModels';
import eventSettings from './event-settings.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'browser-settings',
  components: {
    draggable,
    imagesPopup,
    eventSettings,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      sideFile: null,
      runningTest: false,
      showEventsDialog: false,
      currentEvent: {},
      currentIndex: null,
      editing: false,
      imageDialog: false,
      screenshots: [],
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    events() {
      if (
        this.settings.automation &&
        this.settings.automation.tests &&
        this.settings.automation.tests[0] &&
        this.settings.automation.tests[0].commands
      ) {
        return this.settings.automation.tests[0].commands;
      } else {
        return null;
      }
    },
  },
  watch: {
    sideFile() {
      if (!this.sideFile) return;

      const reader = new FileReader();
      reader.readAsText(this.sideFile, 'UTF-8');
      reader.onload = this.parseFile;
      reader.onerror = (evt) => {
        console.log('error', evt);
      };
    },
  },
  created() {
    if (!this.settings.automation) {
      const initialSettings = getAutomationInitialSettings();
      this.onUpdate(initialSettings, 'settings.automation');
    }
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    parseFile(evt) {
      const automation = JSON.parse(evt.target.result.toString());
      const tests = automation.tests.map((test) => {
        const command = test.commands[0];
        if (command.command === 'open' && command.target === '/') {
          test.commands[0].target = automation.url;
        }
        return test;
      });

      automation.tests = tests;
      this.onUpdate(automation, 'settings.automation');
    },
    addEvent() {
      const events = cloneDeep(this.events);

      if (this.editing) {
        this.editing = false;
        events.splice(this.currentIndex, 1, { ...this.currentEvent });
      } else {
        events.splice(this.currentIndex, 0, { ...this.currentEvent });
      }
      this.onUpdate(events, 'settings.automation.tests[0].commands');
      this.closeEventsModal();
    },
    openEventsModal(index, editing) {
      this.showEventsDialog = true;
      this.currentIndex = index;
      if (editing) {
        this.editing = true;
        this.currentEvent = { ...this.events[index] };
      } else {
        this.currentEvent = {
          id: uuidv4(),
          command: null,
          target: '',
          targets: [],
          value: '',
        };
      }
    },
    closeEventsModal() {
      this.showEventsDialog = false;
      this.currentEvent = {};
      this.currentIndex = null;
      if (this.editing) {
        this.editing = false;
      }
    },
    deleteEvent(index) {
      const events = cloneDeep(this.events);
      events.splice(index, 1);
      this.onUpdate(events, 'settings.automation.tests[0].commands');
    },
    openImageModal() {
      this.imageDialog = !this.imageDialog;
    },
    async testRun() {
      let data;
      try {
        this.runningTest = true;

        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: 'browser_auto',
        };
        data = await this.executeSingleAction(options);

        if (data.success !== false) {
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw data.message;
        }
      } catch (e) {
        console.error(e);
      } finally {
        const screenshots =
          get(data, 'data.screenshots') || get(data, 'screenshots');

        this.screenshots = screenshots || [];
        this.runningTest = false;
        if (this.screenshots.length) {
          this.openImageModal();
        }
      }
    },
    downloadSideFile() {
      const data = JSON.stringify(this.settings.automation, null, 2);
      const link = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
      const fileName = `${this.settings.automation.name}.side`;
      const hiddenElement = document.createElement('a');

      hiddenElement.setAttribute('href', link);
      hiddenElement.setAttribute('download', fileName);
      hiddenElement.style.display = 'none';
      document.body.appendChild(hiddenElement);
      hiddenElement.click();
      document.body.removeChild(hiddenElement);
    },
  },
};
</script>

<style scoped lang="scss">
.BrowserSettings {
  &__divider {
    max-width: unset;
  }

  &__event-card {
    width: 200px;
    min-height: 70px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%) !important;
    transition: border 350ms ease-in-out;
    position: relative;
  }

  &__event-editor {
    background-color: #f8fafc;
  }

  &__event-modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }
}

.events-col {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.selected-event {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .v-card__text {
    max-height: 70px;
    overflow: hidden;
  }
}
.plus-block {
  z-index: 5 !important;
}
.param-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
</style>

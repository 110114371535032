<template>
  <div>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="selectedNode.settings.inputFieldId"
      @input="setCutKey"
      item-text="name"
      item-value="uid"
      :items="availableInputFields"
      label="Data"
      clearable
    ></v-select>
    <v-row justify="space-between" no-gutters class="my-4">
      <h4>Rules</h4>
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-source-branch</v-icon>
        </template>
        <span>Executed condition: {{ strCondition }}</span>
      </v-tooltip>
    </v-row>
    <div class="mb-6" v-for="(cond, i) in conditions" :key="i">
      <v-select
        class="mb-4"
        outlined
        dense
        v-model="cond.leftValue"
        @change="onConditionChange"
        :items="inputFields"
        item-text="name"
        item-value="name"
        label="1st value"
        clearable
      ></v-select>
      <v-select
        class="mb-4"
        outlined
        dense
        v-model="cond.comparator"
        @change="onConditionChange"
        item-text="name"
        item-value="value"
        :items="availableComparators"
        label="compare"
        clearable
      >
      </v-select>
      <mustache-template
        label="2nd value"
        :value="cond.rightValue"
        @input="(val) => conditionUpdate(val, i)"
        :suggestions="availableInputFields"
        :singleLine="true"
        :disabled="
          cond.comparator == 'is_null' || cond.comparator == 'is_not_null'
        "
      ></mustache-template>
      <v-row justify="space-between" no-gutters>
        <div>
          <v-btn-toggle
            v-if="conditions[i + 1]"
            v-model="conditions[i].condOperator"
            group
            mandatory
            color="success"
            @change="onConditionChange"
          >
            <v-btn value="&&" class="mr-2" depressed>AND</v-btn>
            <v-btn value="||" depressed>OR</v-btn>
          </v-btn-toggle>
        </div>

        <v-btn v-if="conditions.length > 1" text @click="deleteCondition(i)">
          <v-icon>mdi-arrow-up</v-icon>
          Delete
        </v-btn>
      </v-row>
    </div>
    <v-btn depressed @click="addCondition('&&')">+ Add condition </v-btn>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import lodashGet from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';
import { findNode, findOutputNode } from '@/util/action-types';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';
import { shTypes } from '@/util/actionsModels';

const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'ListFilterSettings',
  props: {
    nodes: { required: true },
    availableInputFields: { required: true },
  },
  components: {
    MustacheTemplate,
  },
  data() {
    return {
      conditions: [
        {
          condOperator: null,
          leftValue: null,
          comparator: null,
          rightValue: null,
        },
      ],
      availableComparators: [
        { name: 'Equal to =', value: '==' },
        { name: 'Contains', value: 'contains' },
        { name: 'One Of', value: 'one_of' },
        { name: 'Not equal to !=', value: '!=' },
        { name: 'Greater than >', value: '>' },
        { name: 'Less than <', value: '<' },
        { name: 'Greater than or equal to >=', value: '>=' },
        { name: 'Less than or equal to  <=', value: '<=' },
        { name: 'Is null/undefined', value: 'is_null' },
        { name: 'Is not null/undefined', value: 'is_not_null' },
      ],
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    strCondition() {
      let brackets = 0;
      let condition = this.conditions
        .reduce((acc, cond) => {
          let str = acc;
          if (cond.condOperator) str += `${cond.condOperator} `;
          if (cond.leftValue) str += `${cond.leftValue} `;
          if (cond.comparator) str += `${cond.comparator} `;
          if (cond.rightValue) str += `${cond.rightValue}`;

          if (cond.condOperator) {
            str += ') ';
            brackets++;
          } else {
            str += ' ';
          }
          return str;
        }, '')
        .trim();
      for (let i = brackets; i > 0; i--) {
        condition = '(' + condition;
      }
      return condition;
    },
    inputFields() {
      let parentOutput = null;
      const id = this.selectedNode.input.inputNodeId;
      const parentNode = findNode(this.nodes, id);
      if (parentNode) {
        parentOutput = cloneDeep(parentNode.output_type);
      }
      const inputFieldId = this.selectedNode.settings.inputFieldId;

      if (!parentNode || !inputFieldId) return [];
      const output = findOutputNode(parentOutput, inputFieldId);

      this.onUpdate(output, 'output_type');
      if (!lodashGet(output, 'value.value')) return [];
      const result = [];
      const flattenObjFields = (items, prefix) => {
        items.forEach((prop) => {
          const propWithPrefix = {
            name: !prefix ? prop.name : `${prefix}.${prop.name}`,
            value: prop.name,
          };

          if (prop.type === shTypes.OBJECT && Array.isArray(prop.value)) {
            result.push(propWithPrefix);
            flattenObjFields(prop.value, propWithPrefix.name);
          } else {
            result.push(propWithPrefix);
          }
        });
      };

      if (Array.isArray(output.value.value)) {
        flattenObjFields(output.value.value);
      } else {
        flattenObjFields([output.value.value]);
      }
      return result;
    },
    showAddButton() {
      const lastIndex = this.conditions.length - 1;
      const lastItem = this.conditions[lastIndex];

      if (lastItem.leftValue && lastItem.condOperator) return true;
      return false;
    },
  },
  watch: {
    'selectedNode.id'() {
      this.initConditions();
    },
  },
  mounted() {
    this.initConditions();
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    initConditions() {
      const cond = this.selectedNode.settings.condition;
      if (cond && cond.length) {
        this.conditions = cloneDeep(cond);
      } else {
        this.conditions = [
          {
            condOperator: null,
            leftValue: null,
            comparator: null,
            rightValue: null,
          },
        ];
      }
    },
    addCondition(condOperator) {
      const lastItem = this.conditions.length - 1;
      this.conditions[lastItem].condOperator = condOperator;
      this.conditions.push({
        condOperator: null,
        leftValue: null,
        comparator: null,
        rightValue: null,
      });
      this.onConditionChange();
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1);
      this.onConditionChange();
    },
    onConditionChange() {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.condition = cloneDeep(this.conditions);
      this.onUpdate(settings, 'settings');
    },
    conditionUpdate(value, index) {
      this.conditions[index].rightValue = value;
      this.onConditionChange();
    },
    setCutKey(uid) {
      const settings = cloneDeep(this.selectedNode.settings);
      if (!uid) {
        settings.cutKey = '';
        settings.inputFieldId = '';
        return;
      }
      settings.inputFieldId = uid;
      settings.cutKey = this.availableInputFields.find(
        (input) => input.uid == uid
      ).name;
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style scoped lang="scss"></style>

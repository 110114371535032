<template>
  <div class="BrowserSettingsRaw">
    <div>
      <v-dialog v-model="showDialog" width="700">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" class="my-4" v-on="on">
            Open JavaScript code editor
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="BrowserSettingsRaw__modal-title">
            JavaScript code editor
          </v-card-title>

          <v-card-text class="BrowserSettingsRaw__modal-content">
            <CodeEditor
              v-if="showDialog"
              :value="settings.automation"
              @input="(val) => onUpdate(val, 'settings.automation')"
              language="javascript"
              class="mb-3"
              :runScript="testScript"
              :placeholder="placeholder"
              :suggestions="availableInputFields"
            />
            <p v-if="error" class="error--text">{{ error }}</p>
            <div v-if="output">
              <div v-if="typeof output === 'object' || Array.isArray(output)">
                <div class="output-title">Output:</div>
                <div class="output-tree">
                  <v-treeview dense open-on-click :items="output">
                    <template v-slot:label="{ item }">
                      <div v-if="item.image">
                        <button @click="openImageModal(item.image)">
                          {{ item.name }}
                        </button>
                      </div>
                      <div v-else>
                        <div>{{ item.name }}</div>
                      </div>
                    </template>
                  </v-treeview>
                </div>
              </div>
              <div v-else>Output: {{ output }}</div>
            </div>
          </v-card-text>

          <v-divider />

          <v-card-actions class="justify-end py-3 px-4">
            <v-btn color="primary" text @click="showDialog = false">Done</v-btn>
            <v-btn
              color="primary"
              :loading="testingScript"
              @click="testScript()"
            >
              Test Script
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <images-popup
        :dialog="imageDialog"
        :toggleDialog="openImageModal"
        :images="images"
      ></images-popup>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import { getModel } from '@/util/actionsModels';
import imagesPopup from '@/components/modals/image-modal/image-modal.vue';
import CodeEditor from '@/components/code-editor/code-editor.vue';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'raw-browser-settings',
  components: {
    imagesPopup,
    CodeEditor,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      showDialog: false,
      testingScript: false,
      error: '',
      output: '',
      images: [],
      imageDialog: false,
      placeholder: `
      Example:
      module.exports = async (driver) => {
        await driver.get('https://login.healthfusion.com');

        await driver.uploadFile(xpath, fieContentBase64, extension);
        await driver.downloadFile(link);
        await driver.awaitDownload(waitTime = 30000, fileName, singleFile);
      }
      `,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addParam() {
      const settings = cloneDeep(this.selectedNode.settings);
      if (!settings.params) settings.params = [];

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;
      this.onUpdate(settings, 'settings');
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;

        const params = {};
        if (this.settings.params) {
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        }

        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: 'raw_browser_auto',
        };
        const data = await this.executeSingleAction(options);
        if (data && data.success) {
          const output = data.data || 'no output';
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
          if (output.File && output.File.file) {
            let file = output.File.file;
            if (!file.startsWith('data:')) {
              file = `data:${output.File.mime_type};base64,${file}`;
            }
            output.File.file = file;
          }
          const getOutputTree = (output) => {
            if (output != null) {
              if (typeof output === 'object') {
                return Object.keys(output).map((key) => {
                  if (
                    // eslint-disable-next-line
                    output.hasOwnProperty(key) &&
                    output[key] != null &&
                    (typeof output[key] === 'object' ||
                      Array.isArray(output[key]))
                  ) {
                    return {
                      name: key,
                      children: getOutputTree(output[key]),
                    };
                  } else {
                    if (
                      typeof output[key] === 'string' &&
                      output[key].includes(';base64')
                    ) {
                      return {
                        image: output[key],
                        name: key + ': ' + output[key].substring(0, 30) + '...',
                      };
                    }
                    return {
                      name: key + ': ' + output[key],
                    };
                  }
                });
              } else if (Array.isArray(output)) {
                return output.map((el) => getOutputTree(el));
              }
            } else {
              return 'no output';
            }
          };
          if (typeof output === 'object' || Array.isArray(output)) {
            this.output = getOutputTree(output);
          } else {
            this.output = output;
          }
        } else {
          throw Error(data && data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
    openImageModal(image) {
      this.images = image ? [image] : [];
      this.imageDialog = !this.imageDialog;
    },
  },
};
</script>

<style scoped lang="scss">
.BrowserSettingsRaw {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__modal-content {
    padding-top: 20px !important;
  }
}
.param-row {
  display: flex;
  width: 100%;

  &__field {
    width: 33%;
  }
  &__icon-btn {
    width: 10%;
  }
}
.output-title {
  width: 100%;
}
.output-tree {
  display: flex;
  height: 250px;
  width: 100%;
  overflow: scroll;

  & > div {
    flex: 1;
  }
}
</style>

<template>
  <section>
    <div>
      <h3 class="pb-4">Input</h3>
      <v-row justify="space-between" align="center" class="my-4" dense>
        <v-col cols="6">
          <v-text-field
            outlined
            dense
            label="Post date from (days ago)"
            hide-details="auto"
            type="number"
            :value="selectedNode.settings.from"
            @input="(val) => onUpdate(val, 'settings.from')"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            dense
            label="Post date to (days ago)"
            hide-details="auto"
            type="number"
            :value="selectedNode.settings.to"
            @input="(val) => onUpdate(val, 'settings.to')"
          />
        </v-col>
      </v-row>
      <v-text-field
        outlined
        dense
        label="Last notification (days ago)"
        hide-details="auto"
        type="number"
        :value="selectedNode.settings.notifGap"
        @input="(val) => onUpdate(val, 'settings.notifGap')"
      ></v-text-field>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "patient-balances-settings",
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="SqlSettings">
    <v-dialog v-model="showDialog" width="700">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mt-4 mb-4" v-on="on">
          Open SQL query editor
        </v-btn>
      </template>

      <v-card>
        <v-card-title primary-title class="SqlSettings__modal-title">
          SQL query editor
        </v-card-title>

        <v-card-text class="SqlSettings__modal-content">
          <CodeEditor
            v-if="showDialog"
            :value="selectedNode.settings.query"
            @input="(val) => onUpdate(val, 'settings.query')"
            language="sql"
            :runScript="executeQuery"
            :placeholder="placeholder"
            :suggestions="availableInputFields"
          />
        </v-card-text>

        <v-divider />

        <v-card-actions class="SqlSettings__modal-actions justify-end">
          <v-btn color="primary" text @click="showDialog = false">
            Close
          </v-btn>
          <v-btn color="primary" @click="executeQuery()" :loading="executing">
            Execute
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { getModelFromSql } from '@/util/actionsModels';
import CodeEditor from '@/components/code-editor/code-editor.vue';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'sql-settings',
  components: {
    CodeEditor,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      showDialog: false,
      executing: false,
      placeholder: `
      Example:
      select *
      from patients_table
      where patient_id = '{{patient.id}}'
      `,
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),

    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async executeQuery() {
      this.executing = true;

      try {
        const options = {
          creds: this.settings.authenticationId,
          data: this.settings,
          action: 'execute_raw_query',
        };
        const data = await this.executeSingleAction(options);
        if (data && data.success) {
          this.showDialog = false;
          const output_type = this.getModel(this.settings.query, data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data && data.message ? data.message : 'Error');
        }
      } finally {
        this.executing = false;
      }
    },
    getModel(query, baseModel) {
      const tableName = query.match(/from\s"*(\w*)"*/i)[1];
      return getModelFromSql(tableName, baseModel);
    },
  },
};
</script>

<style scoped lang="scss">
@import './sql-settings';
</style>

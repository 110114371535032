<template>
  <div>
    <v-card-title primary-title>
      <v-combobox
        v-model="event.command"
        :items="seleniumEvents"
        label="Event"
        outlined
        dense
        @input="updateEvent"
      ></v-combobox>
    </v-card-title>
    <v-card-text v-if="event.command">
      <mustache-template
        v-if="event.command === 'screenshot'"
        label="File Name"
        v-model="event.fileName"
        :suggestions="availableInputFields"
        :singleLine="true"
        :append="true"
        appendText=".png"
      >
      </mustache-template>

      <div v-else-if="event.command === 'downloadFromLink'">
        <mustache-template
          label="Download Link"
          v-model="event.link"
          :suggestions="availableInputFields"
          :singleLine="true"
        ></mustache-template>
        <v-checkbox label="Single Download?" v-model="event.singleFile" />
      </div>

      <div v-else-if="event.command === 'awaitDownload'">
        <mustache-template
          label="Wait Time(Milliseconds)"
          placeholder="30000"
          v-model="event.waitTime"
          hint="Only one (1), awaitDownload event is needed per automation."
          :suggestions="availableInputFields"
          :singleLine="true"
          class="mb-3"
        ></mustache-template>
        <mustache-template
          label="Desired filename"
          placeholder="(OPTIONAL) If not set, default name will be used"
          v-model="event.filename"
          :suggestions="availableInputFields"
          :singleLine="true"
          class="mb-3"
        ></mustache-template>
        <v-checkbox label="Single Download?" v-model="event.singleFile" />
      </div>

      <div v-else-if="event.command === 'upload'">
        <mustache-template
          label="Target"
          v-model="event.target"
          :suggestions="availableInputFields"
          :singleLine="true"
          class="mb-3"
        ></mustache-template>
        <mustache-template
          label="File Content(base64)"
          v-model="event.content"
          :suggestions="availableInputFields"
          class="mb-3"
        ></mustache-template>
        <mustache-template
          label="File Extension"
          v-model="event.extension"
          :suggestions="availableInputFields"
          :singleLine="true"
        ></mustache-template>
      </div>

      <div v-else-if="event.command === 'getOTPToken'">
        <mustache-template
          label="Enter Secret"
          v-model="event.target"
          :suggestions="availableInputFields"
          :singleLine="true"
          class="mb-3"
        ></mustache-template>
        <v-text-field
          label="Enter Variable Name (defaults to token)"
          v-model="event.value"
          outlined
          dense
          :hide-details="true"
        />
      </div>

      <div v-else>
        <v-combobox
          v-model="event.target"
          :items="availableTargets"
          :search-input.sync="search"
          hint="Add or select default target"
          outlined
          dense
          label="Target"
          persistent-hint
          @change="addTarget"
          class="mb-5"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Press <kbd>enter</kbd> to create target
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>

        <mustache-template
          label="Value"
          v-model="event.value"
          :suggestions="availableInputFields"
          :singleLine="true"
        ></mustache-template>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import MustacheTemplate from '@/components/mustache/mustache-template.vue';
import { seleniumEvents, customEvents } from '@/util/browserAutomationEvents';

export default {
  name: 'event',
  data() {
    return {
      search: null,
      event: this.value,
    };
  },
  props: {
    availableInputFields: { required: true },
    showEventsDialog: { required: true },
    value: {
      required: false,
      default: function () {
        return {};
      },
    },
  },
  components: {
    MustacheTemplate,
  },
  computed: {
    seleniumEvents() {
      return seleniumEvents;
    },
    availableTargets() {
      return this.event.targets.map((target) => target[0]);
    },
  },
  watch: {
    showEventsDialog() {
      this.event = this.value;
    },
    'event.command'(newValue, OldValue) {
      if (!this.event) return;

      const { id, command } = this.event;
      const event = { id, command };
      if (customEvents.includes(newValue)) {
        if (newValue === 'screenshot') {
          event.fileName = '';
        } else if (newValue === 'downloadFromLink') {
          event.link = '';
          event.singleFile = false;
        } else if (newValue === 'awaitDownload') {
          event.waitTime = '';
          event.filename = '';
          event.singleFile = false;
        } else if (newValue === 'upload') {
          event.target = '';
          event.content = '';
          event.extension = '';
        } else {
          event.value = '';
          event.target = '';
          event.targets = [];
        }
      } else if (!OldValue || customEvents.includes(OldValue)) {
        event.value = '';
        event.target = '';
        event.targets = [];
      }
    },
  },
  methods: {
    deleteEvent() {
      this.events.splice(this.index, 1);
    },
    addTarget(data) {
      let input = data;
      if (!input) return;
      if (!this.availableTargets.includes(input)) {
        const type = input.split('=')[0];
        this.event.targets.push(input);
        const target = [input, type];
        const targets = this.event.targets ? [...this.event.targets] : [];
        targets.push(target);
        this.event.targets = targets.filter((item) => Array.isArray(item));
      }
    },
    updateEvent() {
      this.$emit('input', this.event);
    },
  },
};
</script>

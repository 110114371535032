<template>
  <div class="AutomationCanvas">
    <div class="AutomationCanvas__zoom-container d-flex flex-column">
      <v-btn
        @click="zoomIn"
        class="AutomationCanvas__zoom-button"
        depressed
        small
        :disabled="zoomValue >= maxZoom"
      >
        <v-icon color="#384e53">mdi-magnify-plus-outline</v-icon>
      </v-btn>
      <v-btn
        @click="resetZoom"
        class="AutomationCanvas__zoom-button"
        depressed
        small
        :disabled="zoomValue >= maxZoom"
      >
        <v-icon color="#384e53">mdi-rotate-left</v-icon>
      </v-btn>
      <v-btn
        @click="zoomOut"
        class="AutomationCanvas__zoom-button"
        depressed
        small
        :disabled="zoomValue <= minZoom"
      >
        <v-icon color="#384e53">mdi-magnify-minus-outline</v-icon>
      </v-btn>

      <v-btn
        @click="togglePan"
        class="AutomationCanvas__pan-button"
        depressed
        small
        :color="isPanning ? 'primary' : 'default'"
      >
        <v-icon color="#384e53">mdi-hand-right</v-icon>
      </v-btn>
    </div>
    <div class="AutomationCanvas__container">
      <div
        class="AutomationCanvas__node-container d-flex flex-column align-center"
        ref="zoomBaseElement"
        :style="{
          transform: `scale(${this.zoomValue})`,
          cursor: isPanning ? 'pointer' : 'default',
        }"
        :draggable="!isPanning"
      >
        <!-- start node -->
        <div class="start-node">start</div>

        <div class="arrow-down">
          <div class="arrow-down__line"></div>
        </div>
        <div class="plus-block">
          <action-list-menu :insertIndex="0" :isDisabled="triggerIsEvent" />
        </div>
        <div class="new-flow d-flex flex-column align-center">
          <div
            class="block__container d-flex flex-column align-center"
            v-for="(node, index) in nodes"
            :key="node.id"
          >
            <node-resolver :node="node" :index="index" />
          </div>
        </div>
        <div class="arrow-down">
          <div class="arrow-down__line"></div>
        </div>
        <div class="end-node">End</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ActionListMenu from "@/components/action-list/action-list.vue";
import NodeResolver from "@/components/node-resolver/node-resolver.vue";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");
const canvasCoords = { x_elem: null, y_elem: null };

export default {
  name: "AutomationCanvas",
  components: {
    NodeResolver,
    ActionListMenu,
  },
  data() {
    return {
      zoomValue: 1,
      minZoom: 0.5,
      maxZoom: 1,
      isPanning: false,
      isMouseDown: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      nodes: "NODES",
      startSettings: "START_SETTINGS",
    }),
    triggerIsEvent() {
      return this.startSettings.trigger_type === "event";
    },
  },
  beforeDestroy() {
    if (this.isPanning) {
      this.removeCanvasListeners();
    }
  },
  methods: {
    zoomIn() {
      // TODO: center the canvas during zoom
      if (this.zoomValue < this.maxZoom) {
        this.zoomValue += 0.1;
      }
    },
    zoomOut() {
      if (this.zoomValue > this.minZoom) {
        this.zoomValue -= 0.1;
      }
    },
    resetZoom() {
      this.zoomValue = 1;
    },
    pageDragStarted(evt) {
      if (this.isPanning && evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      this.isMouseDown = true;
      const detailsCanvas = this.$refs.zoomBaseElement;
      canvasCoords.x_elem = window.event.clientX - detailsCanvas.offsetLeft;
      canvasCoords.y_elem = window.event.clientY - detailsCanvas.offsetTop;
    },
    pageDragStopped() {
      this.isMouseDown = false;
    },
    pageDragging() {
      if (!this.isPanning || !this.isMouseDown) return false;
      const detailsCanvas = this.$refs.zoomBaseElement;
      const { x_elem, y_elem } = canvasCoords;

      detailsCanvas.style.left = `${window.event.clientX - x_elem}px`;
      detailsCanvas.style.top = `${window.event.clientY - y_elem}px`;
    },
    addCanvasListeners() {
      this.isPanning = true;
      const detailsCanvas = this.$refs.zoomBaseElement;
      detailsCanvas.draggable = false;

      detailsCanvas.addEventListener("mousedown", (e) =>
        this.pageDragStarted(e)
      );
      detailsCanvas.addEventListener("mousemove", () => this.pageDragging());
      detailsCanvas.addEventListener("mouseup", () => this.pageDragStopped());
    },
    removeCanvasListeners() {
      this.isPanning = false;
      const detailsCanvas = this.$refs.zoomBaseElement;
      detailsCanvas.draggable = true;

      detailsCanvas.removeEventListener("mousedown", () =>
        this.pageDragStarted()
      );
      detailsCanvas.removeEventListener("mousemove", () => this.pageDragging());
      detailsCanvas.removeEventListener("mouseup", () =>
        this.pageDragStopped()
      );
    },
    togglePan() {
      if (this.isPanning) {
        this.removeCanvasListeners();
      } else {
        this.addCanvasListeners();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./automation-canvas";
</style>

<template>
  <div class="ConditionalNode">
    <div :ref="`parentNode_${node.id}`" :id="`parentNode_${node.id}`">
      <general-node
        :node="node"
        :index="index"
        :parent-node-list="parentNodeList"
        :parent-node="parentNode"
      />
    </div>

    <div class="ConditionalNode__branches">
      <div
        class="ConditionalNode__branch ConditionalNode__branch--yes"
        :ref="`yesBranch_${node.id}`"
        :id="`yesBranch_${node.id}`"
      >
        <div class="ConditionalNode__options">Yes</div>

        <div class="ConditionalNode__flow d-flex flex-column align-center">
          <div class="ConditionalNode__arrows">
            <div class="arrow-down">
              <div class="arrow-down__line"></div>
            </div>
          </div>

          <div
            class="plus-block"
            @dragover.stop.prevent="dragOver($event, 0, null, null, true)"
            :id="trueBranchId"
          >
            <action-list-menu
              :insertIndex="0"
              :parent-node="node"
              :branch="'true'"
              :parent-node-list="computedParentNodeList('true')"
            />
          </div>

          <div
            class="block__container d-flex flex-column align-center"
            v-for="(trueSubNode, trueSubNodeindex) in node.settings.true"
            :key="trueSubNode.id"
          >
            <node-resolver
              :node="trueSubNode"
              :index="trueSubNodeindex"
              :parent-node-list="computedParentNodeList('true')"
              :parent-node="node"
              :branch="'true'"
            />
          </div>

          <div class="arrow-conditional-connector">
            <div class="arrow-down__conditional-line"></div>
          </div>

          <div class="arrow-down">
            <div class="arrow-down__line"></div>
          </div>
        </div>
      </div>

      <div
        class="ConditionalNode__branch ConditionalNode__branch--no"
        :ref="`noBranch_${node.id}`"
        :id="`noBranch_${node.id}`"
      >
        <div class="ConditionalNode__options">No</div>

        <div class="ConditionalNode__flow d-flex flex-column align-center">
          <div class="ConditionalNode__arrows">
            <div class="arrow-down">
              <div class="arrow-down__line"></div>
            </div>
          </div>

          <div
            class="plus-block"
            @dragover.stop.prevent="dragOver($event, 0, null, null, true)"
            :id="falseBranchId"
          >
            <action-list-menu
              :insertIndex="0"
              :parent-node="node"
              :branch="'false'"
              :parent-node-list="computedParentNodeList('false')"
            />
          </div>

          <div
            class="block__container d-flex flex-column align-center"
            v-for="(falseSubNode, falseSubNodeindex) in node.settings.false"
            :key="falseSubNode.id"
          >
            <node-resolver
              :node="falseSubNode"
              :index="falseSubNodeindex"
              :parent-node-list="computedParentNodeList('false')"
              :parent-node="node"
              :branch="'false'"
            />
          </div>

          <div class="arrow-conditional-connector">
            <div class="arrow-down__conditional-line"></div>
          </div>

          <div class="arrow-down">
            <div class="arrow-down__line"></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="ConditionalNode__end"
      :ref="`endNode_${node.id}`"
      :id="`endNode_${node.id}`"
    >
      <general-node
        :node="{ ...node, action_type: 'conditional_output' }"
        :index="index"
        :parent-node-list="parentNodeList"
        :parent-node="parentNode"
      />
    </div>
  </div>
</template>

<script>
import { jsPlumb } from 'jsplumb';

export default {
  name: 'ConditionalNode',
  components: {
    GeneralNode: () =>
      import('@/components/node-types/general-node/general-node.vue'),
    NodeResolver: () => import('@/components/node-resolver/node-resolver.vue'),
    ActionListMenu: () => import('@/components/action-list/action-list.vue'),
  },
  props: {
    node: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentNodeList: {
      type: Array,
      default: () => [],
    },
    parentNode: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      instance: null,
      jsPlumbInit: false,
    };
  },
  computed: {
    trueBranchId() {
      return this.node.id + '|true|DEST';
    },
    falseBranchId() {
      return this.node.id + '|false|DEST';
    },
  },
  methods: {
    dragOver() {},
    initJsPlumb() {
      this.instance.ready(() => {
        this.jsPlumbInit = true;
        this.instance.importDefaults({
          ConnectionsDetachable: false,
          LogEnabled: true,
        });
      });
    },
    drawConnectors() {
      const parentNode = `parentNode_${this.node.id}`;
      const yesBranch = `yesBranch_${this.node.id}`;
      const noBranch = `noBranch_${this.node.id}`;
      const endNode = `endNode_${this.node.id}`;

      const connections = [
        [parentNode, yesBranch],
        [parentNode, noBranch],
        [yesBranch, endNode],
        [noBranch, endNode],
      ];

      this.$nextTick(() => {
        connections.forEach(([source, target]) => {
          this.instance.connect({
            source: this.$refs[source],
            target: this.$refs[target],
            endpoint: 'Blank',
            paintStyle: { stroke: '#374151', strokeWidth: 2 },
            connectorStyle: {
              strokeStyle: '#374151',
              joinStyle: 'round',
              outlineColor: '#374151',
            },
            connector: ['Flowchart', { cornerRadius: 15 }],
            anchor: ['BottomCenter', 'TopCenter'],
          });
        });
      });
    },
    computedParentNodeList(branch) {
      return [
        ...this.parentNodeList,
        { nodeId: this.node.id, index: this.index, branch },
      ];
    },
  },
  mounted() {
    this.instance = jsPlumb.getInstance();
    this.initJsPlumb();
    this.drawConnectors();
    setTimeout(() => {
      this.$nextTick(() => {
        this.instance.reset();
        this.drawConnectors();
      });
    },5)
  },
  updated() {
    this.$nextTick(() => {
      this.instance.reset();
      this.drawConnectors();
    });
  },
  beforeDestroy() {
    this.instance.reset();
    this.instance.clear();
    this.instance.destroy();
  },
};
</script>

<style scoped lang="scss">
@import './conditional-node.scss';
</style>

<template>
  <div class="EmailSettings">
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.label"
      @input="(val) => setLabel(val)"
      @click:append-outer="listLabels"
      item-text="displayName"
      item-value="id"
      :items="labels"
      label="Label"
      placeholder="Inbox"
      :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
      clearable
    ></v-select>

    <v-text-field
      outlined
      dense
      placeholder="Maximun of 10. Defaults to 10"
      label="Max Results"
      hide-details="auto"
      type="number"
      :value="settings.maxResults"
      @input="(val) => onUpdate(val, 'settings.maxResults')"
      class="mb-4"
    ></v-text-field>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: worklfowActions } = createNamespacedHelpers(
  'workflows/list'
);
const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'outlook-read-email',
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      labels: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (this.settings.label && this.settings.labelName) {
      this.labels = [
        { id: this.settings.label, displayName: this.settings.labelName },
      ];
    }
  },
  methods: {
    ...worklfowActions(['outlookAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    setLabel(labelId) {
      const label = this.labels.find((item) => item.id === labelId);
      this.onUpdate(labelId, 'settings.label');
      this.onUpdate(label.displayName, 'settings.labelName');
    },
    async listLabels() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_email_labels',
        };
        const resp = await this.outlookAction(data);
        if (resp && resp.result) {
          this.labels = resp.result;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

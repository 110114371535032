<template>
  <div class="settings-col">
    <v-select
      class="param-row__field mr-2"
      outlined
      dense
      item-text="name"
      item-value="id"
      :value="settings.workerId"
      @input="(val) => onUpdate(val, 'settings.workerId')"
      :items="workers"
      @change="updateWorker"
      :loading="fetchWorkerLoading"
      clearable
    ></v-select>

    <h4 class="pt-4 pb-2">Worker Inputs</h4>
    <div v-if="settings.workerId && settings.inputs">
      <div v-for="(input, index) in settings.inputs" :key="index">
        <mustache-template
          :label="input.name"
          :value="input.value"
          @input="(val) => onUpdate(val, `settings.inputs[${index}].value`)"
          :suggestions="availableInputFields"
          :singleLine="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapGetters: workersGetters, mapActions: workersActions } =
  createNamespacedHelpers('workers');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'worker-settings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workersGetters({
      workers: 'WORKERS',
      worker: 'WORKER',
      fetchWorkerLoading: 'FETCH_WORKER_LOADING',
    }),
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (this.selectedNode.settings.workerId) {
      this.updateWorker(false);
    }
  },
  methods: {
    ...workersActions(['fetchWorker', 'clearWorker']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async updateWorker(overwrite = true) {
      const settings = cloneDeep(this.settings);

      if (settings.workerId) {
        await this.fetchWorker(settings.workerId);
      }

      if (overwrite) {
        const output_type = cloneDeep(this.worker.model);
        settings.authenticationId = this.worker.settings.authenticationId;
        settings.inputs = cloneDeep(this.worker.inputs);
        this.onUpdate(output_type, 'output_type');
        this.onUpdate(settings, 'settings');
      }
    },
  },
  beforeDestroy() {
    this.clearWorker();
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="TestBlock">
    <div class="TestBlock__btn-container pb-4">
      <v-btn
        @click="run()"
        color="success"
        :disabled="isWorkflowRunning"
        :loading="!running.runningInProductionMode && isWorkflowRunning"
      >
        <v-icon class="mr-3" right>mdi-bug-check</v-icon>
        Test Run
      </v-btn>
    </div>

    <h3
      class="py-3 px-4"
      v-if="
        running.validation?.validation_status === workflowStatus.SUCCESS &&
        (running.vm_status === workflowStatus.SUCCESS ||
          running.vm_status === workflowStatus.SKIPPED)
      "
    >
      <workflow-status-icon :status="running.workflow_status" class="mr-1" />
      Running workflow in
      {{ running.runningInProductionMode ? 'production mode' : 'test mode' }}
    </h3>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__run-status"
      v-if="headerText"
      rounded="lg"
    >
      <v-card-title class="TestBlock__run-status-title">
        <workflow-status-icon :status="running.workflow_status" class="mr-1" />
        {{ headerText }}
      </v-card-title>

      <v-list flat>
        <v-list-item>
          <v-list-item-icon>
            <workflow-status-icon
              :status="running.validation?.validation_status"
            />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Validation</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <workflow-status-icon :status="running.validation?.actions" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Actions</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="
            running.validation?.schedule !== workflowStatus.SKIPPED &&
            running.validation?.schedule !== workflowStatus.NOT_STARTED
          "
        >
          <v-list-item-icon>
            <workflow-status-icon :status="running.validation?.schedule" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Schedule</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__response"
      rounded="lg"
      v-if="
        running.vm_status &&
        running.vm_status !== workflowStatus.NOT_STARTED &&
        running.vm_status !== workflowStatus.SKIPPED
      "
    >
      <v-card-text>
        <workflow-status-icon :status="running.vm_status" />
        Testing connection with the remote bot
      </v-card-text>
    </v-card>

    <v-card
      outlined
      class="mx-4 mb-4 TestBlock__response"
      rounded="lg"
      v-if="
        running.validation?.validation_status === workflowStatus.SUCCESS &&
        (running.vm_status === workflowStatus.SUCCESS ||
          running.vm_status === workflowStatus.SKIPPED)
      "
    >
      <v-expansion-panels accordion flat multiple>
        <v-expansion-panel
          v-for="(action, index) in actionsStatuses"
          :key="index"
        >
          <v-expansion-panel-header class="px-4">
            <div>
              <workflow-status-icon :status="action.status" class="mr-1" />
              Step {{ index + 1 }}.{{ action.action_name }}
            </div>
            <template v-slot:actions>
              <template v-if="action.finish">
                <span class="text-caption grey--text">{{
                  formatDuration(action.start, action.finish)
                }}</span>
              </template>
              <template v-else-if="action.progress">
                <span class="text-caption grey--text">{{
                  action.progress
                }}</span>
              </template>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <input-output-viewer :action="action" :isServerless="workflow.isServerless" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import * as moment from 'moment';
import { workflowStatus } from '@/util/workflow-statuses';
import WorkflowStatusIcon from '@/components/workflow-status-icon/workflow-status-icon.vue';
import InputOutputViewer from '@/components/io-viewer/io-viewer.vue';

const {
  mapActions: workflowDetailsActions,
  mapGetters: workflowDetailsGetters,
} = createNamespacedHelpers('workflows/details');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const { mapActions: sharedActions } = createNamespacedHelpers('shared');

export default {
  name: 'TestBlock',
  components: {
    WorkflowStatusIcon,
    InputOutputViewer,
  },
  data() {
    return {
      workflowStatus: workflowStatus,
      collapsedDataIds: [],
      productionWarnDialog: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      workflow: 'WORKFLOW',
      startSettings: 'START_SETTINGS',
      nodes: 'NODES',
      runStatus: 'WORKFLOW_RUNNING_OBJ',
      workflowRunning: 'WORKFLOW_RUNNING',
      workflowStarted: 'WORKFLOW_STARTED',
      checkingWorkflowStatus: 'CHECK_AUTOMATION_WORKFLOW_STATUS_LOADING',
    }),
    ...customerGetters({
      customer: 'CUSTOMER',
      selected_customer: 'SELECTED_CUSTOMER',
    }),
    actionsStatuses() {
      if (this.running.actions_statuses) {
        return this.running.actions_statuses;
      } else {
        return [];
      }
    },
    headerText() {
      const prod = this.running.runningInProductionMode;
      switch (this.running.workflow_status) {
        case workflowStatus.IDLE:
        case workflowStatus.PENDING:
          return prod ? 'Running workflow in production...' : 'Testing...';
        case workflowStatus.SUCCESS:
          return prod ? 'Production run successful!' : 'Testing Successful!';
        case workflowStatus.FAILURE:
          return prod ? 'Production run failed!' : 'Testing Failed!';
        default:
          return null;
      }
    },
    automationId() {
      return this.$route.params.automationId;
    },
    customerId() {
      if (this.customer.customer_id) {
        return this.selected_customer.customer_id;
      }
      return this.customer.customer_id;
    },
    isNew() {
      return this.automationId === 'new' || !this.automationId;
    },
    isWorkflowRunning() {
      return (
        this.workflowRunning ||
        this.workflowStarted ||
        this.checkingWorkflowStatus ||
        this.running.workflow_status === 'PENDING'
      );
    },
    running() {
      if (this.automationId) {
        return this.runStatus(this.automationId);
      }
      return {};
    },
  },
  beforeDestroy() {
    clearInterval(this.checkWorkflowStatusTimer);
    // TODO: stop workflow status here on page navigation
  },
  methods: {
    ...sharedActions(['postError']),
    ...workflowDetailsActions(['runWorkflow']),
    async run(production = false) {
      this.runWorkflow({ isProduction: production, isNew: this.isNew });
    },
    formatDuration(start, finish) {
      const difference = moment(finish).diff(moment(start), 'milliseconds');
      return moment.utc(difference).format('m [min] s [sec]');
    },
    getFormattedData(id, data) {
      if (!data) {
        return '-';
      } else if (!this.collapsedDataIds.includes(id)) {
        if (Array.isArray(data)) {
          if (data.length <= 1) return data[0] ? `[ ${data[0]} ]` : '[]';
          else return '[ ... ]';
        } else {
          return '{ ... }';
        }
      } else {
        return data;
      }
    },
    collapseData(prefix, id) {
      const item = `${prefix}_${id}`;
      if (this.collapsedDataIds.includes(item)) {
        this.collapsedDataIds.splice(this.collapsedDataIds.indexOf(item), 1);
      } else {
        this.collapsedDataIds.push(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './test-block';
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ChainOutput"},[_vm._l((_vm.outputs),function(output,outputIndex){return _c('div',{key:outputIndex,staticClass:"5"},[_c('p',{staticClass:"text-h6 mb-3"},[_vm._v("Connector #"+_vm._s(outputIndex + 1))]),_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-select',{attrs:{"outlined":"","dense":"","value":output.inputNodeId,"item-text":"name","item-value":"id","items":_vm.internalInputs.filter((i) => i.action_type),"label":"Connector"},on:{"input":(val) => _vm.onUpdateOutput(val, `[${outputIndex}].inputNodeId`),"change":function($event){return _vm.changeInput(outputIndex)}}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteInput(outputIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1),_c('div',{staticClass:"mb-3"},[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{staticClass:"mr-auto",attrs:{"cols":"auto"}},[_c('p',{staticClass:"text-subtitle-2 mb-0"},[_vm._v("Output Fields")])]),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.showAddParamBtn(outputIndex))?_c('v-btn',{attrs:{"dense":"","text":"","color":"primary"},on:{"click":function($event){return _vm.addParam(outputIndex)}}},[_vm._v(" + Add ")]):_vm._e()],1)],1),_vm._l((output.params),function(param,paramIndex){return _c('v-row',{key:paramIndex,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Name","value":param.name},on:{"input":(val) =>
                _vm.onUpdateOutput(
                  val,
                  `[${outputIndex}].params[${paramIndex}].name`
                )}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","value":param.value,"menu-props":"auto","item-text":"name","item-value":"name","items":_vm.availableInputFields(outputIndex),"label":"Value","clearable":"","append-icon":""},on:{"input":(val) =>
                _vm.onUpdateOutput(
                  val,
                  `[${outputIndex}].params[${paramIndex}].value`
                )}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteParam(outputIndex, paramIndex)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)})],2),(outputIndex !== _vm.outputs.length - 1)?_c('v-divider',{staticClass:"my-2",attrs:{"inset":""}}):_vm._e()],1)}),_c('v-btn',{staticClass:"mb-3 mt-5",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.addInput()}}},[_vm._v(" + Add Output ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <validation-observer v-slot="{ handleSubmit, valid }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="credential_group"
          >
            <v-select
              outlined
              dense
              :value="selectedCredGroupId"
              @input="(val) => updateGroup(val)"
              :items="credentialGroups"
              :error="errors.length > 0"
              :error-count="errors.length"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Credential Group"
              clearable
              v-model="selectedCredGroupId"
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn plain class="mr-2" @click="onCancel"> Cancel </v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="!valid || !selectedCredGroupId"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </validation-observer>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: credentialGroupGetter } =
  createNamespacedHelpers("credentials");

export default {
  name: "AddExternalWorkflow",
  data() {
    return {
      selectedCredGroupId: null,
    };
  },
  computed: {
    ...credentialGroupGetter({
      credentialGroupsAll: "AUTHENTICATIONS",
    }),
    credentialGroups() {
      return this.credentialGroupsAll.filter(
        (group) => group.credential_type === "externalWorkflowActivator"
      );
    },
  },
  methods: {
    async onSubmit() {
      this.$emit("submit-form", this.selectedCredGroupId);
    },
    onCancel() {
      this.$emit("close");
    },
    updateGroup(value) {
      this.selectedCredGroupId = value;
    },
  },
};
</script>

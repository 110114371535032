<template>
  <div>
    <mustache-template
      class="mb-3"
      label="Date Sent"
      :value="settings.dateSent"
      @input="(val) => onUpdate(val, 'settings.dateSent')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      class="mb-3"
      label="Date Sent After"
      :value="settings.dateSentAfter"
      @input="(val) => onUpdate(val, 'settings.dateSentAfter')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      class="mb-3"
      label="Date Sent Before"
      :value="settings.dateSentBefore"
      @input="(val) => onUpdate(val, 'settings.dateSentBefore')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>

    <v-combobox
      :value="settings.from"
      @input="(val) => onUpdate(val, 'settings.from')"
      :items="phoneNumbers"
      item-text="phoneNumber"
      item-value="phoneNumber"
      :search-input.sync="search"
      hint="Select or Type Number"
      outlined
      dense
      label="From Number"
      persistent-hint
      @change="addTarget"
      class="mb-2"
      @click:append-outer="availableNumbers"
      :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Press <kbd>enter</kbd> to create target
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>

    <mustache-template
      class="mb-3"
      label="To Number"
      :value="settings.to"
      @input="(val) => onUpdate(val, 'settings.to')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
    <mustache-template
      class="mb-3"
      label="Limit"
      :value="settings.limit"
      @input="(val) => onUpdate(val, 'settings.limit')"
      :suggestions="availableInputFields"
      :singleLine="true"
    ></mustache-template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: workflowsActions } = createNamespacedHelpers(
  'workflows/list'
);
const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'ListMessagesSettings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      phoneNumbers: [],
      search: null,
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (this.settings.authenticationId && !this.settings.from) {
      this.availableNumbers();
    }
  },
  methods: {
    ...workflowsActions(['twilioAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async availableNumbers() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'available_phone_numbers',
        };
        const resp = await this.twilioAction(data);
        this.phoneNumbers = resp.result;
      } finally {
        this.loading = false;
      }
    },
    addTarget(data) {
      if (typeof data === 'string') {
        this.phoneNumbers = [...this.phoneNumbers, { phoneNumber: data }];
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <div v-if="nestedInputType !== null">
    <output-model
      title="Nested input"
      :model="nestedInputType" />
    <v-dialog
      v-model="showDialog"
      width="700">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          class="my-4"
          v-on="on">
          Change nested input
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="NestedInputSettings__modal-title">
          JavaScript code editor
        </v-card-title>

        <v-card-text class="NestedInputSettings__modal-content">
          <CodeEditor
            v-if="showDialog"
            :value="nestedSettings.nestedActionJs"
            @input="(val) => onUpdate(val, 'nestedSettings.nestedActionJs')"
            language="javascript"
            class="mb-3"
            :runScript="testScript"
            :placeholder="placeholder"
            :suggestions="availableInputFields" />
          <p
            v-if="error"
            class="error--text">
            {{ error }}
          </p>
          <div v-if="output">
            <div v-if="typeof output === 'object' || Array.isArray(output)">
              <div class="output-title">Output:</div>
              <div class="output-tree">
                <v-treeview
                  dense
                  open-on-click
                  :items="output">
                  <template v-slot:label="{ item }">
                    <div v-if="item.image">
                      <button @click="openImageModal(item.image)">
                        {{ item.name }}
                      </button>
                    </div>
                    <div v-else>
                      <div>{{ item.name }}</div>
                    </div>
                  </template>
                </v-treeview>
              </div>
            </div>
            <div v-else>Output: {{ output }}</div>
          </div>
        </v-card-text>

        <v-divider />

        <v-card-actions class="justify-end py-3 px-4">
          <v-btn
            color="primary"
            text
            @click="showDialog = false"
            >Done</v-btn
          >
          <v-btn
            color="primary"
            :loading="testingScript"
            @click="testScript()">
            Test Script
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { findNode, allowedNestedActionTypes } from '@/util/action-types';
import { getModel, getOutputTree } from '@/util/actionsModels';
import OutputModel from '@/components/output-model/output-model.vue';
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import lodashGet from 'lodash/get';
import CodeEditor from '@/components/code-editor/code-editor.vue';

const { mapGetters: workflowsGetters, mapActions: workflowsActions } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'NestedInput',
  components: {
    OutputModel,
    CodeEditor,
  },
  data() {
    return {
      showDialog: false,
      testingScript: false,
      availableInputFields: [],
      error: '',
      output: '',
      placeholder: `
        (
          () => {
            return {
              field1: 'X1',
              field2: 'X2',
              field3: 'x3'
            }
          }
        )()
      `,
    };
  },
  methods: {
    ...workflowsActions(['updateNodes', 'removeNode', 'executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    checkIfInputIsValid() {
      const selectedNode = cloneDeep(this.selectedNode);

      if (!selectedNode.input.inputNodeId) return;
      // check if input was not deleted
      const inputNode = this.findNode(selectedNode.input.inputNodeId);
      const selectedAction =
        inputNode &&
        inputNode.actions &&
        inputNode.actions.find((a) => a.action_type === inputNode.action_type);
      if (!inputNode) {
        const snn = cloneDeep(selectedNode);
        snn.input.inputNodeId = null;
        snn.input.inputActionType = null;
        snn.output_type = null;
      } else if (!inputNode.output_type && !selectedAction) {
        selectedNode.output_type = null;
      }
      this.updateNodes(selectedNode);
    },
    findNode(id) {
      return findNode(this.nodes, id);
    },

    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        //js compatible options to get output
        const options = {
          creds: null,
          data: {
            script: this.nestedSettings.nestedActionJs,
          },
          action: 'execute_javascript',
          params: [],
        };
        const data = await this.executeSingleAction(options);
        if (!data || !data.success) {
          throw Error(data && data.message);
        }
        const output = data.data || 'no output';
        const output_type = getModel(data.model);
        this.onUpdate(output_type, 'nestedSettings.output_type');

        if (typeof output === 'object' || Array.isArray(output)) {
          this.nestedSettings.output = getOutputTree(output);
        } else {
          this.nestedSettings.output = output;
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
  },
  computed: {
    ...workflowsGetters({
      selectedNode: 'SELECTED_NODE',
      actionsOutputs: 'ACTION_OUTPUTS',
      nodes: 'NODES',
      copyOfSavedNodes: 'COPY_OF_SAVED_NODES',
      appointmentOptions: 'APPOINTMENT_OPTIONS',
      isServerless: 'IS_SERVERLESS',
    }),
    nodeInput() {
      return lodashGet(this.selectedNode, 'input');
    },
    nestedInputType() {
      if (!allowedNestedActionTypes.includes(this.selectedNode.action_type)) {
        return null;
      }
      if (this.selectedNode && this.selectedNode?.nestedSettings?.output_type) {
        return this.selectedNode?.nestedSettings?.output_type;
      }
      return {};
    },
    action() {
      return this.selectedNode.action_type;
    },
    nestedSettings() {
      return this.selectedNode.nestedSettings || {};
    },
  },
  created() {
    this.debouncedSelectedNodeUpdate = debounce(this.updateNodes, 500);
  },
  watch: {
    selectedNode: {
      handler(newNode, oldNode) {
        const oldInput = oldNode.input;
        const newInput = newNode.input;

        if (this.nodeInput && !isEqual(newInput, oldInput)) {
          this.checkIfInputIsValid();
        }
        const updatedNode = cloneDeep(newNode);
        if (this.action === 'loop_output') {
          updatedNode.action_type = 'loop';
        }
        this.debouncedSelectedNodeUpdate(updatedNode);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.NestedInputSettings {
  display: flex;
  flex-direction: column;
  align-items: start;

  &__modal-title {
    background: #f8f9fa;
    border-bottom: thin solid #dde2e5;
  }

  &__modal-content {
    padding-top: 20px !important;
  }
}
</style>

<template>
  <section>
    <div>
      <v-text-field
        class="mb-3"
        outlined
        dense
        label="Period"
        hide-details="auto"
        type="number"
        :value="selectedNode.settings.period"
        @input="(val) => onUpdate(val, 'settings.period')"
      ></v-text-field>
      <v-select
        outlined
        dense
        :value="selectedNode.settings.link_type"
        @input="(val) => onUpdate(val, 'settings.link_type')"
        item-text="text"
        item-value="value"
        :items="availableLinks"
        label="Link Type"
        clearable
      />
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "patient-messages-settings",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: "SELECTED_NODE",
    }),
    availableLinks() {
      return [
        { value: null, text: "No link" },
        { value: "prePay", text: "prePay" },
        { value: "checkIn", text: "checkIn" },
        { value: "billing", text: "billing" },
      ];
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TryCatchSettings"},[_c('h3',{staticClass:"mt-3"},[_vm._v("Settings")]),_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Retry attempts","hide-details":"auto","type":"number","value":_vm.selectedNode.settings.retryAttempts || 3},on:{"input":(val) => _vm.onUpdate(val, 'settings.retryAttempts')}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Retry Delay (in seconds)","hide-details":"auto","type":"number","value":_vm.selectedNode.settings.retryDelaySeconds || 3},on:{"input":(val) => _vm.onUpdate(val, 'settings.retryDelaySeconds')}}),_c('v-checkbox',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Fail workflow at maximum number of attempts","hide-details":"auto"},model:{value:(_vm.selectedNode.settings.failWorkflow),callback:function ($$v) {_vm.$set(_vm.selectedNode.settings, "failWorkflow", $$v)},expression:"selectedNode.settings.failWorkflow"}}),_vm._l((_vm.filterMessages),function(filter,index){return _c('div',{key:index},[_c('v-row',{attrs:{"justify":"space-between","align":"center","no-gutters":""}},[_c('div',{staticClass:"filter-container"},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Regex Filter message","hide-details":"auto","type":"string","value":filter.message},on:{"input":(val) => _vm.onUpdateFilterMessage(val, index)}}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Regex Flags","hide-details":"auto","items":[
            { text: 'Global search', value: 'g' },
            { text: 'Case insensitive', value: 'i' },
            { text: 'Multiline', value: 'm' },
            { text: 'Unicode', value: 'u' },
            { text: 'Sticky', value: 'y' },
            { text: 'Dotall', value: 's' },
          ],"multiple":""},on:{"change":function($event){return _vm.onUpdateFilterFlags(filter.flags, index)}},model:{value:(filter.flags),callback:function ($$v) {_vm.$set(filter, "flags", $$v)},expression:"filter.flags"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","color":"red"},on:{"click":function($event){return _vm.onDeleteFilter(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])],1)}),(_vm.showNewFilterControl)?_c('div',{staticClass:"filter-container"},[_c('v-text-field',{staticClass:"mb-3",attrs:{"outlined":"","dense":"","label":"Regex Filter message","hide-details":"auto","type":"string"},model:{value:(_vm.newFilterMessage),callback:function ($$v) {_vm.newFilterMessage=$$v},expression:"newFilterMessage"}}),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Regex Flags","hide-details":"auto","items":[
        { text: 'Global search', value: 'g' },
        { text: 'Case insensitive', value: 'i' },
        { text: 'Multiline', value: 'm' },
        { text: 'Unicode', value: 'u' },
        { text: 'Sticky', value: 'y' },
        { text: 'Dotall', value: 's' },
      ],"multiple":""},model:{value:(_vm.newFilterFlags),callback:function ($$v) {_vm.newFilterFlags=$$v},expression:"newFilterFlags"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","dense":""},on:{"click":_vm.onAddNewFilter}},[_vm._v("Create ")])],1):_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.onAddFilter}},[_vm._v("Add filter ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div>
      <v-select
        class="mb-4"
        outlined
        dense
        :value="selectedNode.settings.patient_id"
        @input="(val) => onUpdate(val, 'settings.patient_id')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Patient ID"
        clearable
      ></v-select>

      <p class="text-h6 mt-5 mb-3">Updated fields</p>

      <div
        class="param-row mb-6"
        v-for="(param, i) in availableInsuranceFields"
        :key="i"
      >
        <v-select
          class="param-row__field mr-2"
          outlined
          dense
          :value="selectedNode.settings[param]"
          @input="(val) => onUpdate(val, `settings[${param}]`)"
          item-text="name"
          item-value="name"
          :items="availableInputFields"
          :label="param | snakeCaseToNormal | capitalize"
          clearable
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "benefits-settings",
  props: {
    availableInputFields: { required: true },
  },
  computed: {
    ...workflowDetailsGetters({
      actionsOutputs: "ACTION_OUTPUTS",
      selectedNode: "SELECTED_NODE",
    }),
    availableInsuranceFields() {
      return [
        "deductible",
        "deductible_met",
        "co_insurance_percent",
        "effective_date",
        "family_deductible",
        "family_deductible_met",
        "coverage_id",
        "policy_number",
        "company_name",
        "allowable",
        "plan",
        "groupID",
        "policyHolder",
        "ribbonResponse",
        "ribbon_update_date",
        "insured_firstname",
        "insured_lastname",
        "insured_date_of_birth",
      ];
    },
  },
  beforeMount() {
    if (!this.selectedNode.settings.params) {
      this.onUpdate({}, "settings.params");
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
  },
};
</script>

<style scoped lang="scss">
.param-row {
  display: flex;
  width: 100%;

  &__field {
    width: 33%;
  }
  &__icon-btn {
    width: 10%;
  }
}
</style>

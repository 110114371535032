<template>
  <div>
    <div>
      <v-switch
        label="Use Share URL"
        class="mt-2"
        :input-value="settings.useShareUrl"
        @change="toggleUseShareUrl"
      ></v-switch>
      <mustache-template
        v-if="settings.useShareUrl"
        label="Share URL"
        class="mb-3"
        :value="settings.shareUrl"
        @input="(val) => onUpdate(val, `settings.shareUrl`)"
        :suggestions="availableInputFields"
        :singleLine="true"
      />
      <v-autocomplete
        v-else
        class="mb-3"
        outlined
        dense
        :value="settings.location"
        @input="(val) => setLocation(val)"
        @click:append-outer="listFolders"
        item-text="name"
        item-value="id"
        :items="driveFolders"
        label="Folder location"
        :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
        clearable
      ></v-autocomplete>

      <v-autocomplete
        v-if="showFolderNameInput"
        class="mb-3"
        outlined
        dense
        :value="selectedNode.settings.folderName"
        @input="(val) => onUpdate(val, 'settings.folderName')"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Folder Name"
        clearable
      ></v-autocomplete>

      <v-checkbox
        class="mb-3"
        outlined
        dense
        :input-value="selectedNode.settings.includeFiles || false"
        @change="(val) => onUpdate(val, 'settings.includeFiles')"
        label="Include files"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: workflowActions } =
  createNamespacedHelpers('workflows/list');
const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'folder-create',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      driveFolders: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    action() {
      return this.selectedNode.action_type;
    },
    suite() {
      if (
        this.action === 'msgraph_onedrive_create_folder' ||
        this.action === 'msgraph_onedrive_get_folder'
      ) {
        return 'outlook';
      }
      return 'google';
    },
    showFolderNameInput() {
      if (!this.settings.useShareUrl) return true;
      if (this.action === 'msgraph_onedrive_get_folder') return false;
      return true;
    },
  },
  created() {
    const dynamicFolders = this.availableInputFields.map((inputField) => {
      return {
        name: inputField.name,
      };
    });
    this.driveFolders = [...dynamicFolders];
    if (!this.settings.location || !this.settings.locationName) {
      return;
    }
    this.driveFolders.push({
      id: this.settings.location,
      name: this.settings.locationName,
    });
  },
  methods: {
    ...workflowActions(['outlookAction']),
    setLocation(location) {
      this.onUpdate(location, 'settings.location');
      const folder = this.driveFolders.find((item) => item.id === location);
      if (folder?.name) {
        this.onUpdate(folder.name, 'settings.locationName');
      } else {
        this.onUpdate(null, 'settings.locationName');
      }
      if (folder?.driveId) {
        this.onUpdate(folder.driveId, 'settings.driveId');
      } else {
        this.onUpdate(null, 'settings.driveId');
      }
    },
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    suiteAction(data) {
      if (this.suite === 'outlook') return this.outlookAction(data);
    },
    setInputOutput(uid) {
      const input = cloneDeep(this.selectedNode.input);
      const selectedOption = this.availableInputFields.find(
        (f) => f.uid === uid
      );
      const field = selectedOption.field;
      input.inputFieldPath = selectedOption.name;
      input.inputFieldName = field.name;
      input.inputFieldId = field.uid;

      this.onUpdate(input, 'input');
    },
    async listFolders() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'list_folders',
        };
        const resp = await this.suiteAction(data);
        if (resp && resp.result) {
          this.driveFolders = resp.result;
        }
        if (this.isServerless) {
          const dynamicFolders = this.availableInputFields.map((inputField) => {
            return {
              name: inputField.name,
            };
          });
          this.driveFolders.push(...dynamicFolders);
        }
      } finally {
        this.loading = false;
      }
    },
    toggleUseShareUrl() {
      const settings = cloneDeep(this.settings);
      settings.useShareUrl = !settings.useShareUrl;
      if (!settings.useShareUrl) {
        settings.location = null;
      }
      this.onUpdate(settings, 'settings');
    },
  },
};
</script>

<style lang="scss" scoped></style>

import { v4 as uuidv4 } from 'uuid';

export { getAutomationInitialSettings } from "./browserAutomationEvents";

export const getHumanInTheLoopSettings = () => ({
  title: "",
  fields: [{ label: "", name: "", id: uuidv4() }],
});

export const getCurrentTimeSettings = () => ({
  name: "",
  value: "",
});

export const getDaysBetweenSettings = () => ({
  begin_date: "",
  end_date: "",
});

export const getNotifierSettings = () => ({
  value: "",
  is_custom: null,
});

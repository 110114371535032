<template>
  <div>
    <div>
      <v-select
        class="mb-3"
        filter
        outlined
        dense
        :value="selectedNode.input.inputFieldId"
        @input="(val) => onUpdate(val, 'input.inputFieldId')"
        @change="setInputOutput"
        item-text="name"
        item-value="uid"
        :items="availableInputFields"
        :label="placeholderForDefaultFieldSelect"
        clearable
      ></v-select>

      <v-checkbox
        v-if="actionType === 'get_patient_insurance'"
        :input-value="selectedNode.settings.full_response"
        @change="(val) => onUpdate(val, 'settings.full_response')"
        label="Full Ribbon Response"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers("workflows/details");

export default {
  name: "default-settings",
  props: {
    availableInputFields: {
      type: Array,
      required: true,
    },
    setInputOutput: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    if (this.actionType === "get_patient_insurance") {
      this.updatePatientInsuranceOutput();
    }
  },
  watch: {
    "settings.full_response"() {
      this.updatePatientInsuranceOutput();
    },
  },
  computed: {
    ...workflowDetailsGetters({
      actionsOutputs: "ACTION_OUTPUTS",
      selectedNode: "SELECTED_NODE",
    }),
    settings() {
      if (!this.selectedNode) return {};
      return this.selectedNode.settings;
    },
    actionType() {
      return this.selectedNode.action_type;
    },
    placeholderForDefaultFieldSelect() {
      const type = this.selectedNode.action_type;
      if (
        type === "get_patient_insurance" ||
        type === "update_balances_notification_date"
      ) {
        return "Target patient id";
      } else if (
        type === "google_drive_upload_file" ||
        type === "msgraph_onedrive_upload_file" ||
        type === "sftp_upload" ||
        type === "sftp_download"
      ) {
        return "File";
      } else if (type === "google_sheets_write_data") {
        return "Data Array";
      } else if (type === "csv_to_json") {
        return "CSV File or Raw CSV String";
      } else if (type === "get_patient_messages") {
        return "Patient ID";
      } else if (type === "eligibiltiy_pdf_report_pverify") {
        return "Request Id";
      } else {
        return "Data";
      }
    },
  },
  methods: {
    onUpdate(value, path) {
      this.$emit("update", value, path);
    },
    updatePatientInsuranceOutput() {
      const models = this.actionsOutputs[this.actionType];
      const outputIndex = Number(!!this.settings.full_response);

      if (models && Array.isArray(models)) {
        const output_type = { ...models[outputIndex] };
        this.onUpdate(output_type, "output_type");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

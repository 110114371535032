<template>
  <div>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.from"
      @input="(val) => onUpdate(val, 'settings.from')"
      @click:append-outer="availableNumbers"
      item-text="phoneNumber"
      item-value="phoneNumber"
      :items="phoneNumbers"
      label="Available Numbers"
      :append-outer-icon="loading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
      clearable
    ></v-select>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.toPhoneNumbers"
      @input="(val) => onUpdate(val, 'settings.toPhoneNumbers')"
      item-text="name"
      item-value="name"
      :items="availableInputFields"
      label="Phone No(s)"
    ></v-select>
    <mustache-template
      class="mb-3"
      label="Message"
      :value="settings.body"
      @input="(val) => onUpdate(val, 'settings.body')"
      :suggestions="availableInputFields"
    ></mustache-template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import MustacheTemplate from '@/components/mustache/mustache-template.vue';

const { mapActions: workflowsActions } = createNamespacedHelpers(
  'workflows/list'
);
const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'TwilioSMSSettings',
  components: {
    MustacheTemplate,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      phoneNumbers: [],
      loading: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (this.settings.authenticationId && !this.settings.from) {
      this.availableNumbers();
    }
  },
  methods: {
    ...workflowsActions(['twilioAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async availableNumbers() {
      this.loading = true;
      try {
        const data = {
          authenticationId: this.settings.authenticationId,
          action: 'available_phone_numbers',
        };
        const resp = await this.twilioAction(data);
        this.phoneNumbers = resp.result;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>

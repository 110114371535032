<template>
  <div class="JsSettings mt-1">
    <v-row
      dense
      align="center">
      <v-col
        cols="auto"
        class="mr-auto">
        <p class="text-subtitle-1 mb-0">Function Parameters</p>
      </v-col>
      <v-col cols="auto">
        <v-btn
          v-if="showAddParamBtn"
          @click="addParam()"
          text
          color="primary"
          dense>
          + Add
        </v-btn>
      </v-col>
    </v-row>
    <div
      class="JsSettings__param-row mb-6"
      v-for="(param, i) in settings.params"
      :key="i">
      <v-text-field
        class="JsSettings__param-row--field mr-2"
        outlined
        dense
        label="Name"
        :value="param.name"
        @input="(val) => onUpdate(val, `settings.params[${i}].name`)" />
      <v-select
        class="JsSettings__param-row--field mr-2"
        outlined
        dense
        :value="param.value"
        @input="(val) => onUpdate(val, `settings.params[${i}].value`)"
        item-text="name"
        item-value="name"
        :items="availableInputFields"
        label="Value"
        clearable></v-select>
      <v-btn
        class="JsSettings__param-row--icon-btn"
        icon
        @click="deleteParam(i)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </div>

    <v-dialog
      v-model="showDialog"
      width="700">
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          class="my-4"
          v-on="on">
          Open JavaScript code editor
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          primary-title
          class="JsSettings__modal-title">
          JavaScript code editor
        </v-card-title>

        <v-card-text class="JsSettings__modal-content">
          <CodeEditor
            v-if="showDialog"
            :value="settings.script"
            @input="(val) => onUpdate(val, 'settings.script')"
            language="javascript"
            :placeholder="placeholder"
            class="mb-3"
            :runScript="testScript"
            :suggestions="availableInputFields" />

          <div v-if="output">
            <p class="text-subtitle-2 mb-0">Output</p>
            <json-viewer
              v-if="output"
              :value="output"
              expanded
              boxed
              sort
              copyable></json-viewer>
          </div>
          <v-alert
            dense
            border="left"
            type="error"
            v-if="error">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-divider />

        <v-card-actions class="JsSettings__modal-actions justify-end">
          <v-btn
            color="primary"
            text
            @click="showDialog = false"
            >Done</v-btn
          >
          <v-btn
            color="primary"
            :loading="testingScript"
            @click="testScript()">
            Test Script
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import JsonViewer from 'vue-json-viewer';
import { getModel } from '@/util/actionsModels';
import CodeEditor from '@/components/code-editor/code-editor.vue';
import 'vue-json-viewer/style.css';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'js-settings',
  components: {
    CodeEditor,
    JsonViewer,
  },
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      showDialog: false,
      testingScript: false,
      error: '',
      output: '',
      placeholder: `
      Example:
      function myFunction(data) {
        return 'Hello ' + data.patientName
      }
      myFunction(data)`,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
    showAddParamBtn() {
      const params = this.settings.params;
      return (
        !params ||
        !params[0] ||
        (params[params.length - 1].name && params[params.length - 1].value)
      );
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),

    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    addParam() {
      let settings = cloneDeep(this.settings);
      if (!settings.params) {
        settings.params = [];
      }

      settings.params.push({
        name: '',
        value: '',
      });
      this.onUpdate(settings, 'settings');
    },
    deleteParam(index) {
      const settings = cloneDeep(this.settings);
      settings.params.splice(index, 1);
      if (!settings.params.length) delete settings.params;

      this.onUpdate(settings, 'settings');
    },
    async testScript() {
      try {
        this.error = '';
        this.output = '';
        this.testingScript = true;
        const params = {};
        if (this.settings.params) {
          this.settings.params.forEach(
            (param) => (params[param.name] = param.value)
          );
        }

        const options = {
          data: {
            script: this.settings.script,
            params: JSON.stringify(params),
            test_mode: true,
          },
          action: this.selectedNode.action_type || 'execute_javascript',
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          this.output = data.result || 'no output';
          const output_type = getModel(data.model);
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testingScript = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './js-settings';
</style>

import { render, staticRenderFns } from "./llm-code-generation-icd-settings.vue?vue&type=template&id=37b54df8&scoped=true"
import script from "./llm-code-generation-icd-settings.vue?vue&type=script&lang=js"
export * from "./llm-code-generation-icd-settings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b54df8",
  null
  
)

export default component.exports
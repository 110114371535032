<template>
  <div>
    <v-select
      class="mb-3"
      outlined
      dense
      :value="settings.delimiter"
      @input="(val) => onUpdate(val, 'settings.delimiter')"
      item-text="name"
      item-value="value"
      :items="delimiters"
      label="Delimeter"
      clearable
    />

    <v-checkbox
      label="Are there header fields in the source?"
      :input-value="settings.hasHeader"
      @change="(val) => onUpdate(val, 'settings.hasHeader')"
      class="mb-3"
    />

    <v-file-input
      class="mr-1"
      accept=".csv"
      outlined
      dense
      v-model="sampleCSV"
      label="Import sample CSV file"
      :loading="testing"
    />

    <div class="mt-5">
      <h3 class="mb-2">Header Settings</h3>
      <v-checkbox
        class="mb-3"
        outlined
        dense
        :input-value="settings.header.spaces"
        @change="(val) => onUpdate(val, 'settings.header.spaces')"
        label="Replace Spaces with Underscores?"
      ></v-checkbox>
      <v-checkbox
        class="mb-3"
        outlined
        dense
        :input-value="settings.header.dots"
        @change="(val) => onUpdate(val, 'settings.header.dots')"
        label="Remove dots?"
      ></v-checkbox>
      <v-checkbox
        class="mb-3"
        outlined
        dense
        :input-value="settings.header.lowercase"
        @change="(val) => onUpdate(val, 'settings.header.lowercase')"
        label="Convert to lowercase?"
      ></v-checkbox>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getModel } from '@/util/actionsModels';
import cloneDeep from 'lodash/cloneDeep';

const {
  mapGetters: workflowDetailsGetters,
  mapActions: workflowDetailsActions,
} = createNamespacedHelpers('workflows/details');

export default {
  name: 'csv-to-json',
  props: {
    availableInputFields: { required: true },
  },
  data() {
    return {
      delimiters: [
        { name: 'Comma', value: ',' },
        { name: 'Tab', value: '\t' },
        { name: 'SemiColon', value: ';' },
      ],
      types: ['String', 'Number', 'Boolean'],
      sampleCSV: null,
      testing: false,
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  created() {
    if (!this.settings.header) {
      const settings = cloneDeep(this.settings);
      settings.header = {
        spaces: false,
        dots: false,
        lowercase: false,
      };
      this.onUpdate(settings, 'settings');
    }
  },
  watch: {
    sampleCSV() {
      if (!this.sampleCSV) return;
      const reader = new FileReader();
      reader.readAsText(this.sampleCSV, 'UTF-8');
      reader.onload = this.parseFile;
      reader.onerror = (evt) => {
        console.log('error', evt);
      };
    },
  },
  methods: {
    ...workflowDetailsActions(['executeSingleAction']),
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    async parseFile(evt) {
      try {
        const csvString = evt.target.result.toString();
        this.testing = true;

        const options = {
          data: this.settings,
          action: 'csv_to_json',
          input: csvString,
        };
        const data = await this.executeSingleAction(options);
        if (data.success) {
          const output_type = getModel(data.model, 'CsvToJson');
          this.onUpdate(output_type, 'output_type');
        } else {
          throw Error(data.message);
        }
      } catch (e) {
        this.error = e.data ? e.data.message : e;
      } finally {
        this.testing = false;
        this.sampleCSV = null;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

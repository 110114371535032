<template>
  <div class="ObjectHelperSettings">
    <p class="text-h5 mt-5 mb-2">Select Connectors and Fields</p>

    <div>
      <p class="text-subtitle-1 mt-2 mb-2">Yes Output</p>
      <ChainOutput
        :availableInputs="availableInputs"
        :outputs="settings.outputs[true]"
        :internalInputs="internalInputs(true)"
        :availableInputFields="(index) => availableInputFields(index, true)"
        :changeInput="(index) => changeInput(index, true)"
        :addInput="() => addInput(true)"
        @updateOutput="(outputs) => updateOutput(outputs, true)"
      />
    </div>

    <v-divider class="my-5"></v-divider>

    <div>
      <p class="text-subtitle-1 mt-2 mb-2">No Output</p>
      <ChainOutput
        :availableInputs="availableInputs"
        :outputs="settings.outputs[false]"
        :internalInputs="internalInputs(false)"
        :availableInputFields="(index) => availableInputFields(index, false)"
        :changeInput="(index) => changeInput(index, false)"
        :addInput="() => addInput(false)"
        @updateOutput="(outputs) => updateOutput(outputs, false)"
      />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { isObject } from 'lodash';
import { createNamespacedHelpers } from 'vuex';

import { shTypes, cloneModel, genUid } from '@/util/actionsModels';
import { findNode, getInputModel } from '@/util/action-types';
import ChainOutput from '@/components/connector-settings/chain-outputs/chain-output.vue';

const { mapGetters: workflowDetailsGetters } =
  createNamespacedHelpers('workflows/details');

export default {
  name: 'conditional-output-settings',
  props: {
    availableInputs: { required: true },
  },
  components: {
    ChainOutput,
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
      nodes: 'NODES',
    }),
    settings() {
      return this.selectedNode.settings;
    },
  },
  mounted() {
    if (!this.settings.outputs) {
      this.addInput();
    }
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    updateOutput(outputs, branch) {
      const settings = cloneDeep(this.settings);
      settings.outputs[branch] = outputs;
      this.onUpdate(settings, 'settings');
      this.updateModel(branch);
    },
    internalInputs(branch) {
      return this.selectedNode.settings[branch];
    },
    findNode(id, branch) {
      return findNode(this.internalInputs(branch), id);
    },
    addInput(branch) {
      const settings = cloneDeep(this.settings);
      const input = {
        inputNodeId: null,
        inputFieldId: null,
        inputFieldName: null,
        inputFieldPath: null,
        params: [],
      };
      if (!settings.outputs) {
        settings.outputs = { true: [input], false: [input] };
      }
      if (branch === null || branch !== undefined) {
        settings.outputs[branch].push(input);
      }
      this.onUpdate(settings, 'settings');
    },
    initOutputModel() {
      // set default output model
      const payload = {
        name: 'ConditionalOutput',
        type: 'object',
        uid: genUid(),
        value: [
          {
            name: 'true',
            type: 'object',
            uid: genUid(),
            value: [],
          },
          {
            name: 'false',
            type: 'object',
            uid: genUid(),
            value: [],
          },
        ],
      };
      if (!this.selectedNode.output_external?.uid) {
        this.onUpdate(payload, 'output_external');
      }
    },
    getOutputModelValue(branch) {
      const values = cloneDeep(this.selectedNode.output_external.value);
      return values.find((v) => v.name === String(branch)).value;
    },
    setOutputModelValue(branch, values) {
      const value = cloneDeep(this.selectedNode.output_external);
      const index = value.value.findIndex((v) => v.name === String(branch));
      value.value[index].value = values;
      this.onUpdate(value, 'output_external');
    },
    updateModel(branch) {
      this.initOutputModel();
      let keysList = [];

      this.settings.outputs[branch].forEach((output, index) => {
        //  clone output models from source models with new names and uids
        for (let param in output.params) {
          const value = cloneDeep(this.getOutputModelValue(branch));
          const newName = output.params[param]['name'];
          const sourcePath = output.params[param]['value'];
          if (!sourcePath) continue;

          const nodeOutputs = this.availableInputFields(index, branch);
          const sourceModel = nodeOutputs.find(
            (field) => field.name === sourcePath
          );

          const existedParamIdx = value.findIndex((v) => v.name == newName);
          if (existedParamIdx !== -1) {
            value[existedParamIdx] = cloneModel(newName, sourceModel.field);
          } else {
            value.push(cloneModel(newName, sourceModel.field));
          }
          this.setOutputModelValue(branch, value);
        }

        keysList = [...keysList, ...output.params.map((p) => p.name)];
      });

      // remove deleted fields from output model
      const filteredModelValues = this.getOutputModelValue(branch).filter(
        (outputParam) => keysList.find((key) => key == outputParam.name)
      );
      this.setOutputModelValue(branch, filteredModelValues);
    },
    inputAction(inputNode) {
      if (
        !inputNode ||
        !inputNode.action_type ||
        !inputNode.actions ||
        !inputNode.actions.length
      )
        return null;
      return inputNode.actions.find(
        (a) => inputNode.action_type === a.action_type
      );
    },
    getInputModel(index, branch) {
      const inputNodeId = this.settings.outputs[branch][index].inputNodeId;
      const inputNode = this.findNode(inputNodeId, branch);
      const inputAction = this.inputAction(inputNode);

      const options = {
        inputNodeId,
        inputNode,
        inputAction,
        nodes: this.nodes,
        nodeId: this.selectedNode.id,
      };
      return getInputModel(options);
    },
    availableInputFields(index, branch) {
      let result = [];

      const inputModel = this.getInputModel(index, branch);
      if (inputModel) {
        if (Array.isArray(inputModel)) {
          const items = inputModel
            .filter((item) => item.type === shTypes.ARRAY)
            .map((i) => ({ name: i.name, uid: i.uid, field: i }));
          result.push(...items);
        } else if (
          typeof inputModel === 'object' &&
          inputModel.type === shTypes.ARRAY
        ) {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
        const flattenObjFields = (items, prefix) => {
          if (isObject(items)) {
            items.forEach((prop) => {
              const propWithPrefix = {
                name: !prefix ? prop.name : `${prefix}.${prop.name}`,
                uid: prop.uid,
                field: prop,
              };

              if (prop.type === shTypes.OBJECT) {
                result.push(propWithPrefix);
                flattenObjFields(prop.value, propWithPrefix.name);
              } else {
                result.push(propWithPrefix);
              }
            });
          }
        };

        if (inputModel.type === shTypes.OBJECT) {
          flattenObjFields(inputModel.value, inputModel.name);
        } else {
          const items = [
            {
              name: inputModel.name,
              uid: inputModel.uid,
              field: inputModel,
            },
          ];
          result.push(...items);
        }
      }

      return result;
    },
    changeInput(index, branch) {
      const settings = cloneDeep(this.settings);
      const inputModel = this.getInputModel(index, branch);
      const inputFields = this.availableInputFields(index, branch);

      if (inputFields && inputFields.length) {
        settings.outputs[branch][index].inputFieldPath = inputModel.name;
        settings.outputs[branch][index].inputFieldName = inputModel.name;
        settings.outputs[branch][index].inputFieldId = inputModel.uid;
      }
      this.onUpdate(settings, 'settings');
      this.updateModel(branch);
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <div>
      <v-row justify="space-between" align="center" no-gutters class="my-4">
        <h4>Rules</h4>
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-source-branch</v-icon>
          </template>
          <span>Executed condition: {{ strCondition }}</span>
        </v-tooltip>
      </v-row>
      <div class="mb-6" v-for="(cond, i) in conditions" :key="i">
        <v-select
          class="mb-4"
          outlined
          dense
          v-model="cond.leftValue"
          @change="onConditionChange"
          :items="leftValues"
          item-text="name"
          item-value="name"
          label="1st value"
          clearable
        ></v-select>
        <v-select
          class="mb-4"
          outlined
          dense
          v-model="cond.comparator"
          @change="onConditionChange"
          item-text="name"
          item-value="value"
          :items="availableComparators"
          label="compare"
          clearable
        >
        </v-select>
        <v-text-field
          :disabled="
            cond.comparator == 'is_null' || cond.comparator == 'is_not_null'
          "
          outlined
          dense
          label="2nd value"
          hide-details="auto"
          v-model="cond.rightValue"
          @input="onConditionChange"
        ></v-text-field>
        <v-row justify="space-between" no-gutters>
          <div v-if="isConditionValid(cond)">
            <v-btn
              v-if="!conditions[i + 1] || !conditions[i + 1].condOperator"
              depressed
              @click="addCondition('&&', i)"
              >+ Add condition
            </v-btn>
            <v-btn-toggle
              v-else
              v-model="conditions[i + 1].condOperator"
              group
              mandatory
              color="success"
            >
              <v-btn value="&&" class="mr-2" depressed>AND</v-btn>
              <v-btn value="||" depressed>OR</v-btn>
            </v-btn-toggle>
          </div>
          <div v-else></div>
          <v-btn v-if="conditions.length > 1" text @click="deleteCondition(i)">
            <v-icon>mdi-arrow-up</v-icon>
            Delete
          </v-btn>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';

import { shTypes } from '@/util/actionsModels';

const { mapGetters: workflowDetailsGetters } = createNamespacedHelpers(
  'workflows/details'
);

export default {
  name: 'ConditionalRules',
  props: {
    inputModel: { required: true },
  },
  data() {
    return {
      conditions: [
        {
          condOperator: null,
          leftValue: null,
          comparator: null,
          rightValue: null,
        },
      ],
    };
  },
  computed: {
    ...workflowDetailsGetters({
      selectedNode: 'SELECTED_NODE',
    }),
    leftValues() {
      if (!this.inputModel) return [];
      const result = [];
      const flattenObjFields = (items, prefix) => {
        items.forEach((prop) => {
          const propWithPrefix = {
            name: !prefix ? prop.name : `${prefix}.${prop.name}`,
            value: prop.name,
          };

          if (prop.type === shTypes.OBJECT && Array.isArray(prop.value)) {
            result.push(propWithPrefix);
            flattenObjFields(prop.value, propWithPrefix.name);
          } else {
            result.push(propWithPrefix);
          }
        });
      };

      if (
        this.inputModel.type === shTypes.OBJECT &&
        Array.isArray(this.inputModel.value)
      ) {
        flattenObjFields(this.inputModel.value, this.inputModel.name);
      } else if (this.inputModel.type === shTypes.ARRAY) {
        console.error("conditionals don't expect arrays", this.inputModel);
        return;
      } else {
        return [
          {
            name: this.inputModel.name,
            value: this.inputModel.name,
          },
        ];
      }
      return result;
    },
    availableComparators() {
      let sn = this.selectedNode;
      if (sn && sn.action_type === 'conditional') {
        return [
          { name: 'Equal to =', value: '==' },
          { name: 'Not equal to !=', value: '!=' },
          { name: 'Greater than >', value: '>' },
          { name: 'Less than <', value: '<' },
          { name: 'Greater than or equal to >=', value: '>=' },
          { name: 'Less than or equal to  <=', value: '<=' },
          { name: 'Is null/undefined', value: 'is_null' },
          { name: 'Is not null/undefined', value: 'is_not_null' },
        ];
      }
      return [];
    },
    strCondition() {
      let brackets = 0;
      let condition = this.conditions
        .reduce((acc, cond) => {
          let str = acc;
          if (cond.condOperator) str += `${cond.condOperator} `;
          if (cond.leftValue) str += `${cond.leftValue} `;
          if (cond.comparator) str += `${cond.comparator} `;
          if (cond.rightValue) str += `${cond.rightValue}`;

          if (cond.condOperator) {
            str += ') ';
            brackets++;
          } else {
            str += ' ';
          }
          return str;
        }, '')
        .trim();
      for (let i = brackets; i > 0; i--) {
        condition = '(' + condition;
      }
      return condition;
    },
  },
  watch: {
    'selectedNode.id'() {
      this.initConditions();
    },
  },
  mounted() {
    this.initConditions();
  },
  methods: {
    onUpdate(value, path) {
      this.$emit('update', value, path);
    },
    initConditions() {
      const cond = this.selectedNode.settings.condition;
      if (cond && cond.length) this.conditions = cond;
      else
        this.conditions = [
          {
            condOperator: null,
            leftValue: null,
            comparator: null,
            rightValue: null,
          },
        ];
    },
    isConditionValid(cond) {
      return !!((cond.leftValue && cond.comparator) /*&& cond.rightValue*/);
    },
    onConditionChange() {
      const settings = cloneDeep(this.selectedNode.settings);
      settings.condition = [];
      this.conditions.forEach((cond) => {
        if (this.isConditionValid(cond)) {
          settings.condition.push(cond);
        }
      });
      this.onUpdate(settings, 'settings');
    },
    addCondition(condOperator) {
      this.conditions.push({
        condOperator: condOperator,
        leftValue: null,
        comparator: null,
        rightValue: null,
      });
      this.onConditionChange();
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1);
      this.onConditionChange();
    },
  },
};
</script>

<style scoped lang="scss"></style>

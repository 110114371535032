<template>
  <div class="Builder">
    <div class="Builder__main-content">
      <AutomationCanvas />
      <AutomationSidebar />
    </div>
  </div>
</template>

<script>
import AutomationCanvas from "../canvas/automation-canvas.vue";
import AutomationSidebar from "../sidebar/automation-sidebar.vue";

export default {
  name: "Builder",
  components: {
    AutomationCanvas,
    AutomationSidebar,
  },
};
</script>

<style scoped lang="scss">
@import "./Builder.scss";
</style>
